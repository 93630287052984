<template>
    <div id="content" class="tasks" :class="[{ detail: detail, portrait: ['portrait'].includes($mq) }, $mq]">
        <div class="column left hide-scrollbar" v-if="!detail">
            <div class="calendar-navigation-container" v-if="!detail">
                <calendar-navigation @select="selectDate" @calendarOpen="calendarState" :selected_date="date.format('YYYY-MM-DD')" :future_days="1" :past_days="pastDays"></calendar-navigation>
            </div>
            <!-- Category selector for landscape -->
            <div class="category-list-container" v-if="['landscape', 'desktop'].includes($mq) && showCategories">
                <checklistCategory :selected="categorySelected.id" @selectCategory="selectCategory" v-for="(category, index) in categories" :itemClasses="itemClasses(category)" :category="category" :key="index"></checklistCategory>
            </div>
            <!-- Category name for portrait -->
            <div class="category-selected-container" v-if="categorySelected && !detail && ['portrait'].includes($mq) && showCategories">
                <SimpleNavigationBar @back-button-click="() => (categorySelected = false)" :color="categorySelected.color" :title="categorySelected.name" />
                <div class="timeframe-button" @click="timeframeHandler()" :class="{ inactive: !showTimeFrames }"></div>
            </div>

            <!-- Timeframes portrait -->
            <CollapseTransition :duration="transitionDuration">
                <div class="timeframes hide-scrollbar slideIn" ref="tframeContainer" v-if="(categories && categorySelected && showTimeFrames && ['portrait'].includes($mq)) || (detail && ['portrait'].includes($mq)) || Object.values(categories).length < 1 || !showCategories">
                    <div class="tframe" :class="[tframeClasses(tframe, index), $mq]" v-for="(tframe, index) in timeframes" :key="index" @click="toggleFrame(tframe.id)" :id="index">
                        <div class="graph chart">
                            <div class="c100" :class="['p' + tframe.score, itemClasses(tframe), { condensed: detail }]">
                                <span v-if="!detail">{{ tframe.score }}%</span>
                                <div class="slice">
                                    <div class="bar"></div>
                                    <div class="fill"></div>
                                </div>
                            </div>
                        </div>
                        <div class="meta">
                            <h6 class="tf-title" :class="{ condensed: detail }">
                                {{ tframe.name ? tframe.name : $t('tasks.timeframe.default.title') }}
                            </h6>

                            <div class="available" v-if="!detail">
                                <span class="hour">{{ $tc('assets.timing.from', 1) }} {{ timeFormat(tframe.start_hour) | hour }}h </span>
                                <span class="hour" v-if="tframe.end_hour">{{ $tc('assets.timing.to', 1) }} {{ tframe.end_hour | hour }}h</span>
                            </div>
                        </div>
                    </div>
                </div>
            </CollapseTransition>
        </div>

        <!-- CATEGORY LIST FOR PORTRAIT -->
        <div class="category-list-container hide-scrollbar" v-if="categories && !categorySelected && Object.values(categories).length > 0 && ['portrait'].includes($mq) && !detail && showCategories" :class="[$mq]">
            <checklistCategory @selectCategory="selectCategory" v-for="(category, index) in categories" :itemClasses="itemClasses(category)" :category="category" :key="index"></checklistCategory>
        </div>

        <!-- MAIN -->
        <div v-if="(!detail && categorySelected) || (categories && !detail && Object.values(categories).length <= 1)" class="right hide-scrollbar" :class="[{ 'calendar-opened': calendarIsVisible }, { 'timeframe-opened': showTimeFrames }, { category: categories.length > 0 }]">
            <div class="contain">
                <!-- TIMEFRAMES ON LANDSCAPE/DESKTOP -->
                <template v-if="['landscape', 'desktop'].includes($mq) && Object.values(categories).length > 0 && showCategories">
                    <div class="timeframes-container scrollbar">
                        <div class="tframe" :class="tframeClasses(tframe, index)" v-for="(tframe, index) in timeframes" :key="index" @click="toggleFrame(tframe.id)" :id="index">
                            <div class="graph chart">
                                <div class="c100" :class="['p' + tframe.score, itemClasses(tframe), { condensed: detail }]">
                                    <span v-if="!detail">{{ tframe.score }}%</span>
                                    <div class="slice">
                                        <div class="bar"></div>
                                        <div class="fill"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="meta">
                                <h6 class="tf-title" :class="{ condensed: detail }">
                                    {{ tframe.name ? tframe.name : $t('tasks.timeframe.default.title') }}
                                </h6>

                                <div class="available" v-if="!detail">
                                    <span class="hour">{{ $tc('assets.timing.from', 1) }} {{ timeFormat(tframe.start_hour) | hour }}h </span>
                                    <span class="hour" v-if="tframe.end_hour">{{ $tc('assets.timing.to', 1) }} {{ tframe.end_hour | hour }}h</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
                <template v-if="isLoadedChecklist">
                    <template v-if="checklists && checklists.length > 0">
                        <div
                            class="checklist-container"
                            :class="{
                                categories: categories.length > 0 && showCategories,
                                'hide-scrollbar': ['portrait'].includes(this.$mq)
                            }"
                        >
                            <item v-for="check in checklists" :item="check" :allCategories="categorySelected.id == 'all'" @sendId="openDetail" :key="check.id"></item>
                        </div>
                    </template>
                    <template v-else-if="checklists && checklists.length <= 0">
                        <div class="empty-page hide-scrollbar" v-if="!connectionError">
                            <EmptyPage customClass="checklist" :title="$t('empty_pages.generic_title')" :description="$t('empty_pages.checklist_description')"></EmptyPage>
                        </div>
                        <ConnectionBox gType="checklistConnection" @reload="reloadChecklist" v-else></ConnectionBox>
                    </template>
                </template>
            </div>
        </div>

        <!-- DETAIL -->
        <detail v-if="detail" :gId="detail" :cType="type" @setDateTimeframe="setDateTimeframe"></detail>
    </div>
</template>

<script>
import ChecklistCategory from '@/components/domain/tasks/checklistCategory.vue'
import EmptyPage from '@/components/layout/EmptyPage'
import item from '@/components/domain/tasks/item'
import detail from '@/components/domain/tasks/detail'
import calendar from '@/components/layout/Calendar'
import CalendarNavigation from '@/components/layout/CalendarNavigation.vue'
import { CollapseTransition } from '@ivanv/vue-collapse-transition'
import ConnectionBox from '@/components/domain/ui/Box.vue'
import SimpleNavigationBar from '@/components/domain/ui/SimpleNavigationBar.vue'

export default {
    name: 'Checklist',
    components: {
        item,
        detail,
        calendar,
        EmptyPage,
        CalendarNavigation,
        ChecklistCategory,
        CollapseTransition,
        ConnectionBox,
        SimpleNavigationBar
    },

    data() {
        return {
            type: 1,
            route_name: 'ChecklistDetail',
            route: 'Checklist',
            detail: this.$route.params.id,
            // date: this.$route.params.propDate ? this.$route.params.propDate : moment().set({ minutes: 0, seconds: 0 }),
            // date: moment().set({ minutes: 0, seconds: 0 }),
            name: this.$route.params.name,
            yesterdaytf: false,
            pastDays: 1,
            calendarIsVisible: false,
            categorySelected: false,
            showTimeFrames: false,
            transitionDuration: 300,
            showCategories: true,
            isLoadedChecklist: false,
            connectionError: false
        }
    },
    computed: {
        date: {
            get() {
                return this.$route.params.propDate ? this.$route.params.propDate : moment(this.$store.getters['tasks/currentDate']) //moment().set({ minutes: 0, seconds: 0 })
            },
            set(value) {
                log('setting date --> ', value.format('YYYY-MM-DD'))
                this.$store.commit('tasks/setCurrentDate', value.format('YYYY-MM-DD'))
            }
        },
        def() {
            return this.$store.getters['tasks/getDefaults']
        },

        timeframes() {
            return this.$store.getters['tasks/getTimeframes']
        },
        currentTimeframe: {
            get() {
                log('get - currentTimeFrame: ', this.$store.getters['tasks/getCurrentTimeframe'])
                return this.$store.getters['tasks/getCurrentTimeframe']
            },
            set(val) {
                log('set - currentTimeFrame: ', val)
                this.$store.commit('tasks/setCurrentTimeframe', val)
            }
        },
        user_id() {
            return this.$store.getters['loginUser/getLocalEmployee']
        },

        user() {
            var employee = this.$store.getters['employee/getEmployee'](this.user_id)
            if (employee) {
                return employee
            }
        },

        connection() {
            return this.$store.getters['getConnState']
        },

        checklists() {
            var checklists = this.$store.getters['tasks/getAll']
            var aReturn = []
            var rolEmployee = this.$store.getters['employee/getRol'](this.user.rol)
            this.isLoadedChecklist = true //To show the empty page only when try to load the checklist

            const serverTimeDifference = this.$store.getters['getServerTimeDifference']
            let currentDate = this.date //.valueOf()
            // // currentDate = moment(currentDate).add(serverTimeDifference, 'minutes')
            for (var k in checklists) {
                let businessDate = checklists[k].business_date
                businessDate = moment(businessDate).add(serverTimeDifference, 'minutes')
                log(serverTimeDifference, businessDate.format('YYYY-MM-DD'), currentDate.format('YYYY-MM-DD'))
                if (businessDate.format('YYYY-MM-DD') == currentDate.format('YYYY-MM-DD')) {
                    // si tiene los timeframes desplegados
                    if ((this.showTimeFrames && this.categorySelected.id != 'all') || Object.values(this.categories).length <= 1) {
                        // Filtrar por la categoría seleccionada
                        if (checklists[k].category_id == this.categorySelected.id || Object.values(this.categories).length <= 1) {
                            if (checklists[k].timeframe_id == this.currentTimeframe) {
                                if (checklists[k] && checklists[k].config != false && checklists[k].config.nRoles && checklists[k].config.nRoles.length > 0) {
                                    const templateRoles = checklists[k].config.nRoles
                                    let bChecklistAdded = false
                                    templateRoles.forEach((role) => {
                                        if (role.roleId == this.user.rol) {
                                            aReturn.push(checklists[k])
                                            bChecklistAdded = true
                                        }
                                    })

                                    if (rolEmployee.level >= 8 && !bChecklistAdded) {
                                        aReturn.push(checklists[k])
                                    }
                                } else if (checklists[k] && checklists[k].config != false && checklists[k].config.roles && checklists[k].config.roles.length > 0) {
                                    var templateroles = checklists[k].config.roles

                                    // Si el rol del usuario está en el listado del template o es nivel 8 se muestra
                                    if (templateroles.includes(this.user.rol) || rolEmployee.level >= 8) {
                                        aReturn.push(checklists[k])
                                    }
                                    // SI NO TIENE NINGUN ROL SE MUESTRA
                                } else aReturn.push(checklists[k])
                            }
                        }
                    } else {
                        if (checklists[k].category_id == this.categorySelected.id || this.categorySelected.id == 'all') {
                            // if is portrait and there is no selected timeframe dont filter by timeframe
                            if ((['landscape', 'desktop'].includes(this.$mq) && checklists[k].timeframe_id == this.currentTimeframe) || (['portrait'].includes(this.$mq) && !this.showTimeFrames) || (['portrait'].includes(this.$mq) && this.showTimeFrames && checklists[k].timeframe_id == this.currentTimeframe)) {
                                if (checklists[k] && checklists[k].config != false && checklists[k].config.nRoles && checklists[k].config.nRoles.length > 0) {
                                    const templateRoles = checklists[k].config.nRoles
                                    let bChecklistAdded = false
                                    templateRoles.forEach((role) => {
                                        if (role.roleId == this.user.rol) {
                                            aReturn.push(checklists[k])
                                            bChecklistAdded = true
                                        }
                                    })

                                    if (rolEmployee.level >= 8 && !bChecklistAdded) {
                                        aReturn.push(checklists[k])
                                    }
                                } else if (checklists[k] && checklists[k].config != false && checklists[k].config.roles && checklists[k].config.roles.length > 0) {
                                    var templateroles = checklists[k].config.roles

                                    // Si el rol del usuario está en el listado del template o es nivel 8 se muestra
                                    if (templateroles.includes(this.user.rol) || rolEmployee.level >= 8) {
                                        aReturn.push(checklists[k])
                                    }
                                    // SI NO TIENE NINGUN ROL SE MUESTRA
                                } else aReturn.push(checklists[k])
                            }
                        }
                    }
                } else {
                    // return false
                }
            }

            return aReturn
        },
        categories() {
            var checklists = this.$store.getters['tasks/getAll']
            var categories = this.$store.getters['tasks/getCategories']()
            var hashCategories = ['all']
            var rolEmployee = this.$store.getters['employee/getRol'](this.user.rol)

            for (var k in checklists) {
                var checklist = checklists[k]
                if (checklist.category_id != false && !hashCategories.includes(checklist.category_id)) {
                    let nRoles = []
                    if(checklist.config && checklist.config.nRoles){
                        nRoles = checklist.config.nRoles.map(rol => rol.roleId)
                    }
                    
                    const hasConfig = checklist.config;
                    const hasRoles = hasConfig && checklist.config.roles && checklist.config.roles.length > 0;
                    const hasNRoles = hasConfig && checklist.config.nRoles && checklist.config.nRoles.length > 0;
                    const userRoleInRoles = hasRoles && checklist.config.roles.includes(this.user.rol);
                    const userRoleInNRoles = hasNRoles && nRoles.includes(this.user.rol);
                    const isHighLevelEmployee = rolEmployee.level >= 8;
                    console.log(userRoleInRoles)
                    if ((!hasRoles && !hasNRoles) || userRoleInRoles || userRoleInNRoles || isHighLevelEmployee) {
                        hashCategories.push(checklist.category_id)
                    }

                }
            }

            categories = Object.values(categories).filter((category) => {
                return hashCategories.includes(category.id)
            })

            if (!this.hasToShowCategories(categories, checklists)) {
                // remove the category that is not all
                categories = categories.filter((category) => {
                    return category.id == 'all'
                })
            }

            // sort by id = 'all first'
            return Object.values(categories).sort((a, b) => {
                return a.id == 'all' ? -1 : b.id == 'all' ? 1 : a.id - b.id
            })
        }
    },
    methods: {
        hasToShowCategories(categories, checklists) {
            // if all items doesnt have a category dont show categories
            let haveCategoryAssigned = false
            Object.values(checklists).forEach((element) => {
                if (element.category_id) haveCategoryAssigned = true
            })
            if (!haveCategoryAssigned) {
                this.showCategories = false
                return false
            }
            // if there is only one category a part of all and all checklist have the same category_id dont show categories
            if (Object.values(categories).length == 2) {
                let result = false
                Object.values(checklists).forEach((element) => {
                    if (!element.category_id) result = true
                    if (result) return

                    // get id of category that is not all
                    if (element.category_id && element.category_id != 'all') {
                        result = element.category_id == categories[1].id
                    }
                })
                this.showCategories = result
                return result
            }
            this.showCategories = true
            return true
        },
        timeFormat(time) {
            return timeFormat(time)
        },
        selectCategory(category) {
            this.categorySelected = category
        },
        calendarState(show) {
            this.calendarIsVisible = show
        },
        selectDate(date, refresh = false) {
            log('selectDate ->', date, refresh)
            this.date = moment(date).set({ minutes: 0, seconds: 0 })
            this.load(refresh)
            this.connectionError = !fnCheckConnection()
        },
        setDateTimeframe(date, timeframe) {
            // this.date = moment(date).set({ minutes: 0, seconds: 0 })
            const serverTimeDifference = this.$store.getters['getServerTimeDifference']
            this.date = moment(date)
            this.currentTimeframe = timeframe
        },
        isToday() {
            return this.date.format('DD-MM-YYYY') == moment().format('DD-MM-YYYY')
        },

        openDetail(id) {
            this.$router.push({ name: this.route_name, params: { id: id } })
        },

        timeframeHandler() {
            if (this.showTimeFrames) {
                this.showTimeFrames = false
                // save variable in localstorage
                localStorage.setItem('showTimeFrames_' + this.user_id, false)
                // this.currentTimeframe = false
            } else {
                this.showTimeFrames = true
                // save variable in localstorage
                localStorage.setItem('showTimeFrames_' + this.user_id, true)
            }
        },

        tframeClasses(tframe, index) {
            return {
                'alert pending': tframe.alert_pending_show,
                'alert important': tframe.alert_important_show,
                selected: this.currentTimeframe == index,
                expired: this.expiredTFrame(tframe) && this.isToday()
            }
        },

        itemClasses(tframe) {
            if (tframe) {
                if (tframe.score == 100 && !tframe.alert_pending_count) {
                    return 'done'
                } else if (tframe.alert_pending_count && !this.expiredTFrame(tframe)) {
                    if (!this.expiredTFrame(tframe)) {
                        return 'justified'
                    } else {
                        return 'uncompleted'
                    }
                } else {
                    if (tframe.start_hour && this.expiredTFrame(tframe)) {
                        return 'justified'
                    } else {
                        return 'progress'
                    }
                }
            }
        },

        toggleFrame(id) {
            log(id)
            if (id || id == 0) {
                this.currentTimeframe = id
            } else {
                id = this.currentTimeframe
            }

            if (this.detail) {
                this.detail = false
                this.$router.push({
                    name: this.route,
                    params: { timeframe_id: id }
                })
            }
        },

        expiredTFrame(tframe) {
            if ((!tframe.name && this.isToday()) || (!this.isToday() && !this.yesterdaytf)) {
                return false
            }

            var now = moment()
            if (tframe.start_hour) {
                var start = moment(this.date).set('hour', tframe.start_hour).set('minute', 0).set('second', 0).set('millisecond', 0)
                var endHour = tframe.end_hour < 24 ? tframe.end_hour : tframe.end_hour - 24
                var end = moment(this.date).set('hour', endHour).set('minute', 0).set('second', 0).set('millisecond', 0)
                if (tframe.end_hour >= 24) end.add(1, 'days')

                // console.log("Ha expirado? "+ tframe.name);
                // console.log(now.format('HH:mm DD-MM-YYYY'));
                // console.log(start.format('HH:mm DD-MM-YYYY'));
                // console.log(end.format('HH:mm DD-MM-YYYY'));
                // console.log("......");
                // console.log(!now.isBetween(start, end, "hour")+" !now.isBetween(start, end)");
                // console.log(now.isAfter(end)+" now.isAfter(end)");
                // console.log("=");
                // console.log(!now.isBetween(start, end, "hour") && now.isAfter(end));
                // console.log("-------------------------------------------------");

                return !now.isBetween(start, end, 'hour') && now.isAfter(end)
            }
        },

        scrollTo(id) {
            if (['portrait'].includes(this.$mq)) {
                this.$refs.tframeContainer.scrollLeft = document.getElementById(id).offsetLeft - window.innerWidth / 2 + document.getElementById(id).offsetWidth / 2 + 10 // plus 10px of margin
            } else {
                var scroll = {
                    container: this.$refs.tframeContainer,
                    target: document.getElementById(id),
                    offset: -100
                }
                this.$root.scrollTo(scroll)
            }
        },

        load(refresh = true) {
            var self = this

            // var categories = this.categories

            if (!this.$store.getters['tasks/userDateChanged']) {
                if (this.date && moment(this.date).format('DD/MM/YYYY') == moment().format('DD/MM/YYYY') && this.timeframes && Object.keys(this.timeframes).length > 0 && Object.values(this.timeframes)[Object.keys(this.timeframes).length - 1].end_hour > 24 && Object.values(this.timeframes)[Object.keys(this.timeframes).length - 1].end_hour - 24 > parseInt(moment().format('HH'))) {
                    this.yesterdaytf = true
                    this.selectDate(moment().add(-1, 'days').format('YYYY-MM-DD'), true)
                }
            } else {
                if (moment(this.date).format('DD/MM/YYYY') == moment().add(-1, 'days').format('DD/MM/YYYY') && this.timeframes && Object.keys(this.timeframes).length > 0 && Object.values(this.timeframes)[Object.keys(this.timeframes).length - 1].end_hour > 24 && Object.values(this.timeframes)[Object.keys(this.timeframes).length - 1].end_hour - 24 > parseInt(moment().format('HH'))) {
                    this.yesterdaytf = true
                }
            }

            self.$overlay.loading()
            if (self.$route.params.id == undefined) {
                self.$store.dispatch('tasks/loadCategories').then(function () {
                    self.$store
                        .dispatch('tasks/loadAll', {
                            date: self.date.valueOf() / 1000,
                            forceUpdate: true
                        })
                        .then(function () {
                            if (['landscape', 'desktop'].includes(self.$mq)) {
                                if (Object.values(self.categories).length > 0 && !self.categorySelected) {
                                    for (var category in self.categories) {
                                        var idx = self.categories[category]
                                        if (idx.id == 'all' && self.showCategories) {
                                            self.categorySelected = idx
                                        }
                                    }
                                }
                            }
                            if (refresh) {
                                self.$bar.show()
                                self.$bar.reset()

                                // Set Timeframe
                                var desired = self.$route.params.timeframe_id ? self.$route.params.timeframe_id : false
                                if (self.$route.params.timeframe_id == 0) {
                                    desired = '0'
                                }

                                if (!desired) {
                                    var withAlert = self.$route.params.withAlert ? self.$route.params.withAlert : false
                                    for (var i in self.timeframes) {
                                        let tf = self.timeframes[i]
                                        if (tf.name) {
                                            let aux = moment(self.date)
                                                .set({
                                                    hours: 0,
                                                    minutes: 0,
                                                    seconds: 0
                                                })
                                                .add(tf.end_hour, 'hours')

                                            if (withAlert && tf[withAlert]) {
                                                desired = tf.id
                                                break
                                            } else if (moment(moment(self.date).format('YYYY-MM-DD') + ' ' + moment().format('HH:mm:ss')).isBefore(aux) && !self.expiredTFrame(tf)) {
                                                desired = tf.id
                                                break
                                            }
                                        }
                                    }
                                }

                                if (desired && !self.detail) {
                                    self.currentTimeframe = desired
                                } else if (Object.keys(self.timeframes).length >= 1 && !self.detail) {
                                    self.currentTimeframe = self.timeframes[0] ? self.timeframes[0].id : self.timeframes[Object.keys(self.timeframes)[0]].id
                                }

                                if (self.currentTimeframe && !self.detail) {
                                    // TODO Uncomment this line when the app is ready to be used
                                    // self.scrollTo(self.currentTimeframe)
                                }

                                self.detail = false
                            }
                            self.$overlay.hide()
                            self.isLoadedChecklist = true
                        })
                })
            } else if (self.$route.params.id != undefined) {
                self.$store
                    .dispatch('tasks/loadById', {
                        id: self.$route.params.id
                    })
                    .then(function () {
                        self.$store
                            .dispatch('tasks/loadCategories')
                            .then(function () {
                                self.detail = self.$route.params.id

                                for (let key in self.checklists) {
                                    const checklist = self.checklists[key]
                                    if (self.$route.params.id == checklist.id) {
                                        self.currentTimeframe = checklist.timeframe_id ? checklist.timeframe_id : 0
                                    }
                                }

                                self.$overlay.hide()
                                self.isLoadedChecklist = true
                            })
                            .catch(function (error) {
                                logError(error)
                                self.$overlay.hide()
                                self.$popup.alert({
                                    closable: false,
                                    blocking: false,
                                    message: self.$t('popup.checklist.offline_open'),
                                    textSave: self.$t('popup.audits.exit'),
                                    callSave: function (response) {
                                        self.$popup.close()
                                    }
                                })
                            })
                    })
            }

            // this.isLoadedChecklist = true
        },
        reloadChecklist() {
            this.load()
        }
    },
    filters: {
        hour: function (value) {
            if (value == parseInt(value, 10)) {
                return moment('00:00', 'HH:mm').add(value, 'hours').format('HH')
            } else {
                return moment('00:00', 'HH:mm').add(value, 'hours').format('HH:mm')
            }
        }
    },
    mounted() {
        // get timeframe variable from localstorage
        if (localStorage.getItem('showTimeFrames_' + this.user_id) !== null) {
            localStorage.getItem('showTimeFrames_' + this.user_id) == 'false' ? (this.showTimeFrames = false) : (this.showTimeFrames = true)
        }
        // select all categories if is in landscape
    },
    created() {
        this.$bar.reset()
        this.load()
    },
    updated() {
        this.pastDays = ['portrait'].includes(this.$mq) ? 2 : 1
        // Delete calendar open dom
        if (this.$route.name == 'ChecklistDetail') {
            this.calendarIsVisible = false
        }
    },
    watch: {
        $mq(newValue, oldValue) {
            // if is in portrait and didn't select a category, automatically select all categories for landscape/dektop
            if (((oldValue == 'portrait' && newValue == 'landscape') || oldValue == 'desktop') && !this.categorySelected) {
                for (var category in this.categories) {
                    var idx = this.categories[category]
                    if (idx.id == 'all') {
                        this.categorySelected = idx
                    }
                }
            }
        },
        $route: 'load',
        categorySelected(newValue, oldValue) {
            if (!newValue) {
                this.transitionDuration = 0
            } else {
                this.transitionDuration = 300
            }
        }
    }
}
</script>

<style lang="scss">
#content.tasks {
    $column-width: 20%;
    $column-max-width: 180px;
    // $content-width: 100%;

    .slide-leave-active,
    .slide-enter-active {
        transition: 0.3s;
    }
    .slide-enter {
        transform: translate(90%, 0);
        position: absolute;
    }
    .slide-leave-to {
        position: absolute;
        transform: translate(-90%, 0);
    }

    > .right {
        $content-margin: 12px;

        padding-top: 16px;
        // margin: $content-margin 0 0 0;
        height: calc(100% - 25px) !important;
        border: none;
        width: calc(70% - 320px);
        min-width: calc(100% - 320px);
        float: right;

        // @include border-radius($default-border-radius);
        // @include default-box-shadow();
        // background-color: $default-bg-color !important;
        // width: calc(100% - 340px);
        // min-width: calc(100% - #{$column-max-width}) !important;

        &.empty {
            @include display-flex();
            @include align-items();
            @include justify-content(flex-end);
            width: 100% !important;

            .message {
                @include display-inline-flex();
                @include align-items();
                @include background($image: img('alexpose_04.svg'), $size: contain, $position: right center);
                width: 100%;
                padding-right: 150px;
                height: 250px;
                max-height: 100%;
            }
        }

        .contain {
            width: 98%;
            height: 100%;
            margin: 0 16px;

            .empty {
                @include empty();
                @include background($size: 70px, $position: top center);
                padding-top: 80px;
                margin-top: calc(40% - #{$menu-top-height} - 70px);
                background-image: img('confeti_light.svg');
            }

            .timeframes-container {
                @include display-flex();
                width: 100%;
                height: auto;
                margin-bottom: 20px;
                overflow-x: auto;

                .tframe {
                    @include interaction();
                    @include display-flex();
                    @include flex-direction(column);
                    @include align-items(center);
                    @include bgHover($default-bg-color, true, 15%);
                    border-radius: $default-border-radius, 0px, 0px, $default-border-radius;
                    width: 100%;
                    height: auto;
                    padding: 9px 9px;
                    width: auto;
                    min-width: 175px;

                    .graph.chart {
                        $chart-size: rem(65px);
                        float: none;
                        margin: 0;
                        padding: 0;
                        width: $chart-size;
                        height: $chart-size;
                        display: inline-block;

                        .c100 {
                            margin: 0;
                        }
                        span {
                            @include font-size(ml);
                            font-family: $conden;
                            height: $chart-size;
                            line-height: $chart-size;
                        }
                    }
                    .meta {
                        width: 100%;
                        text-align-last: center;
                    }

                    .tf-title {
                        @include font-size(m);
                        @include text-ellipsis();
                        font-family: $conden-bold !important;
                        margin: 0;
                        padding: 0;
                        margin-top: 3px;
                    }
                    .available {
                        margin-top: 3px;
                        .hour {
                            @include font-size(sm);
                            font-family: $conden;
                        }
                    }

                    &.alert {
                        position: relative;

                        &:before {
                            @include background($color: $main, $size: 14px);
                            @include border-radius(3px);
                            content: '';
                            position: absolute;
                            display: block;
                            min-width: 20px;
                            width: auto;
                            height: 20px;
                            z-index: 2;
                            top: 5px;
                            left: 5px;
                        }

                        &.pending:before {
                            background-color: $warning;
                            background-image: img('exclamation_light.svg');
                        }
                        &.important:before {
                            background-color: $error;
                            background-image: img('exclamation_light.svg');
                        }
                    }

                    &.expired {
                        @include opacity(0.6);
                    }

                    &.selected {
                        background-color: #fff;
                        @include bgHover(#fff, false, 1.5%);
                    }
                }
            }
            .checklist-container {
                width: 100%;
                // height: calc(100% - 139px);
                overflow: auto;
                height: 100%;

                &.categories {
                    height: calc(100% - 139px);
                    padding-right: 8px;
                }
            }
        }

        .empty-page {
            width: 100%;
            height: calc(100% - 170px);
        }
    }

    .category-list-container {
        @include display-flex();
        @include flex-direction(column);
        gap: 4px;
        width: 100%;
        height: auto;
        // padding-top: 6px;

        &.portrait {
            height: calc(100% - 73px);
            overflow-y: auto;
        }
    }

    > .column.left {
        $content-space: 6px;
        width: 100%;
        box-shadow: 0px 0px 10px rgba(159, 162, 166, 0.25);
        border-radius: 8px;
        background-color: #fff;
        padding-bottom: 0 !important;
        height: calc(100%);
        min-width: 320px;

        .calendar-navigation-container {
            display: block;
            width: 100%;
            height: auto;
            padding: 8px 8px 16px 8px;
        }

        .category-selected-container {
            position: relative;
            width: 100%;
            max-width: 100%;
            height: auto;
            @include display-flex();
            @include justify-content(space-between);
            @include align-items(center);

            .category-name {
                @include display-flex();
                @include align-items(center);
                width: calc(100% - 85px);
                padding-left: 6px;
                .category-color {
                    width: 13px;
                    height: 13px;
                    min-height: 13px;
                    min-width: 13px;
                    border-radius: 50%;
                }
                .text {
                    @include font-size(m);
                    @include text-ellipsis();
                    font-family: $text-bold;
                    padding-left: 6px;
                    color: $neutro-s80;
                }
            }

            .timeframe-button {
                position: relative;
                @include background($image: img('timeframe_icon.svg'), $size: 20px);
                @include border-radius(4px);
                background-color: $main-t80;
                width: 40px;
                height: 40px;
                min-height: 40px;
                min-width: 40px;
                cursor: pointer;

                &:hover {
                    @include bgHover($main-t80);
                }

                &.inactive {
                    @include background($image: img('timeframe_icon_ffffff.svg'), $size: 20px);
                    background-color: #fff;
                    &:hover {
                        @include bgHover(#fff);
                    }
                }
            }
        }

        .timeframes {
            padding: $content-space 0 $content-space $content-space;
            height: auto;
            overflow: auto;

            .tframe {
                @include interaction();
                border-radius: $default-border-radius, 0px, 0px, $default-border-radius;
                display: block;
                width: 100%;
                height: auto;
                padding: 9px 9px;
                width: calc(100%);
                text-align: center;

                @include bgHover($default-bg-color, true, 15%);

                .graph.chart {
                    $chart-size: rem(65px);
                    float: none;
                    margin: 0;
                    padding: 0;
                    width: $chart-size;
                    height: $chart-size;
                    display: inline-block;

                    .c100 {
                        margin: 0;
                    }
                    span {
                        @include font-size(ml);
                        font-family: $conden;
                        height: $chart-size;
                        line-height: $chart-size;
                    }
                }

                .tf-title {
                    @include font-size(m);
                    @include text-ellipsis();
                    font-family: $conden-bold !important;
                    margin: 0;
                    padding: 0;
                    margin-top: 3px;
                }
                .available {
                    margin-top: 3px;
                    .hour {
                        @include font-size(sm);
                        font-family: $conden;
                    }
                }

                &.alert {
                    position: relative;

                    &:before {
                        @include background($color: $main, $size: 14px);
                        @include border-radius(3px);
                        content: '';
                        position: absolute;
                        display: block;
                        min-width: 20px;
                        width: auto;
                        height: 20px;
                        z-index: 2;
                        top: 5px;
                        left: 5px;
                    }

                    &.pending:before {
                        background-color: $warning;
                        background-image: img('exclamation_light.svg');
                    }
                    &.important:before {
                        background-color: $error;
                        background-image: img('exclamation_light.svg');
                    }
                }

                &.expired {
                    @include opacity(0.6);
                }

                &.selected {
                    @include default-box-shadow();
                    background-color: $main;
                    border-radius: 4px;

                    &.landscape,
                    &.desktop {
                        .meta {
                            .tf-title {
                                color: #fff;
                            }
                            .available {
                                .hour {
                                    color: #fff;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    &.landscape:not(.detail),
    &.desktop:not(.detail) {
        .left {
            .timeframes {
                .tframe {
                    @include display-flex();
                    @include align-content(center);
                    @include justify-content(flex-start);
                    @include align-items(center);

                    .meta {
                        padding-left: 12px;
                    }
                }
            }
        }
    }
}
</style>

<!-- // PORTRAIT STYLES -->
<style lang="scss" scoped>
#content.portrait {
    padding: 8px;

    .column.left {
        width: 100% !important;
        max-width: 100% !important;
        margin-top: 0;
        height: auto !important;
        background-color: #f4f2ed !important;
        border-radius: unset;
        box-shadow: unset;

        .calendar-navigation-container {
            padding: 0px 0px 16px 0px;

            .calendar-navigation {
                > .calendar-picker {
                    width: 100px !important;
                    max-width: 500px !important;
                }
            }
        }

        .timeframes {
            padding: 8px 0 0 0;
            border-radius: 5px;
            overflow-x: auto;
            overflow-y: hidden;
            white-space: nowrap;
            height: auto;

            .tframe {
                box-shadow: none;
                background-color: transparent;
                max-width: 150px;
                display: inline-block;
                padding: 5px;
                margin: 0;
                margin-right: 5px;
                height: 130px;

                .graph.chart {
                    transform: scale(0.8);
                    margin-top: -5px;
                }

                .meta {
                    height: 80px;
                    display: block;
                    width: 100%;
                }

                .tf-title {
                    @include font-size(s);
                    margin-top: -5px;
                    text-align: center;
                }
                .available {
                    margin: 0;
                    margin-top: -5px;
                    text-align: center;
                    .hour {
                        @include font-size(xs);
                    }
                }
                &.selected {
                    box-shadow: none;
                    background-color: #fff;
                    // @include bgHover($default-bg-color, false, 1.5%);
                }
            }

            @include portrait-tablet {
                .tframe {
                    height: 140px;

                    .available {
                        .hour {
                            @include font-size(s);
                            line-height: 35px;
                        }
                    }
                }
            }
        }
    }
    .right {
        width: 100% !important;
        max-width: 100% !important;
        height: calc(100% - 230px) !important;
        box-shadow: none;
        background: transparent !important;
        margin: 0;
        padding: 0 !important;
        margin-top: 16px !important;

        .contain {
            width: 100%;
            height: 100%;
            margin: 0;

            .checklist-container {
                width: 100%;
                height: calc(100% - 10px) !important;
                overflow: auto;
                &.categories {
                    padding-right: 0px;
                }
            }
        }
        &.calendar-opened {
            height: calc(100% - 505px) !important;
        }
        &.timeframe-opened {
            height: calc(100% - 271px) !important;
        }
        .empty-page {
            width: 100%;
            height: calc(100% - 130.5px);
        }
        &.category {
            height: calc(100% - 140px) !important;
        }
    }
}

#content.portrait.detail {
    .timeframes {
        display: none !important;
    }
}
</style>
