<template>
    <div id="info-container" :class="[$mq, { task: taskType }]">
        <div class="detail-info-container" :class="[{ checklist: checklist }, { full: !hasMoreOptions }, { titleFullWidth: $route.name == 'AuditDetail' }]">
            <div v-if="showBackButton" class="btn-content">
                <div class="back-button" @click="back()"></div>
            </div>
            <div class="detail-info" :class="[{ 'with-values': showExtraDetail }, { full: !hasMoreOptions }]" @click="infoOpened()">
                <div class="detail-title" v-html="title"></div>

                <div class="detail-complete" v-if="checklist">
                    <div class="hour" v-html="checkAvailability()"></div>
                </div>
                <!-- se muestra cuando se clica -->
                <div class="detail-created-at" v-if="(completed && !checklist) || (showExtraDetail && checklist && completed)">
                    <span class="detail-text">
                        {{ $t('assets.timing.completed') }}
                    </span>
                    <span class="detail-text-date">
                        {{ completed | moment('DD/MM/YYYY') }}
                        {{ completed | moment('HH:mm') }}
                    </span>
                </div>
                <!-- mostrar creado y cerrado en issues -->
                <div class="issue-info" v-if="issue">
                    <div class="detail-text">
                        <div class="created" v-if="issue.employee && showExtraDetail">
                            <div class="created-text">
                                {{ $t('issue.created_at', { date: formatDate(issue.created_date) }) }}
                            </div>
                            <div class="user-container">
                                <div class="employee-img" v-if="issue.employee">
                                    <img v-if="issue.employee.avatar && issue.employee.avatar.includes('http')" :src="issue.employee.avatar" alt="" />
                                    <img v-else-if="issue.employee.avatar && issue.employee.avatar.includes('file')" :src="issue.employee.avatar" alt="" />
                                    <img v-else :src="css_image_url + 'avatar/' + issue.employee.avatar" alt="avatar" />
                                </div>
                                <div class="employee-name" v-if="issue.employee && Object.keys(issue.employee).length > 0">
                                    {{ issue.employee.name + ' ' + issue.employee.surname }}
                                </div>
                            </div>
                        </div>
                        <!-- <div class="created" v-else-if="!issue.employee_ref && issue.created_date && showExtraDetail">
                            <div class="created-text">
                                {{ $t('issue.created_at', { date: formatDate(issue.created_date) }) }}
                            </div>
                        </div> -->
                        <!-- <div class="created" v-else-if="!issue.employee_ref && issue.complete_date && showExtraDetail">
                            <div class="created-text">
                                {{ $t('issue.created_at', { date: formatDate(issue.complete_date) }) }}
                            </div>
                        </div> -->
                        <div class="created" v-if="issue.employee && issue.complete_date && showExtraDetail">
                            <div class="created-text">
                                {{ $t('issue.closed_at', { date: formatDate(issue.complete_date) }) }}
                            </div>
                            <div class="user-container">
                                <div class="employee-img" v-if="issue.employee">
                                    <img v-if="issue.employee.avatar && issue.employee.avatar.includes('http')" :src="issue.employee.avatar" alt="" />
                                    <img v-else-if="issue.employee.avatar && issue.employee.avatar.includes('file')" :src="issue.employee.avatar" alt="" />
                                    <img v-else :src="css_image_url + 'avatar/' + issue.employee.avatar" alt="avatar" />
                                </div>
                                <div class="employee-name" v-if="issue.employee && Object.keys(issue.employee).length > 0">
                                    {{ issue.employee.name + ' ' + issue.employee.surname }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="detail-created-at" v-if="showExtraDetail && !checklist && createdAt != '' && !issue">
                    <span class="detail-text">
                        {{ $t('assets.timing.created_at') }}
                    </span>
                    <span class="detail-text-date">
                        {{ createdAt | moment('DD/MM/YYYY') }}
                        {{ createdAt | moment('HH:mm') }}
                    </span>
                </div>
                <div class="detail-user-info" v-if="showExtraDetail && userInfo && !issue">
                    <div class="employee-img" v-if="userInfo">
                        <img v-if="userInfo.avatar && userInfo.avatar.includes('http')" :src="userInfo.avatar" alt="" />
                        <img v-else-if="userInfo.avatar && userInfo.avatar.includes('file://')" :src="userInfo.avatar" alt="" />
                        <img v-else :src="css_image_url + 'avatar/' + userInfo.avatar" alt="avatar" />
                    </div>
                    <div class="employee-name" v-if="userInfo && Object.keys(employee).length > 0">
                        {{ employee.name + ' ' + employee.surname }}
                    </div>
                </div>
                <!-- audits -->
                <div class="audit-points" v-if="audit">
                    <div class="status" :class="audit.status">{{ $t('audit.status.' + audit.status) }}</div>

                    <div v-if="(audit.total != 0 && auditScore && auditScore.show_score == undefined) || auditScore.show_score == 1 || audit.complete_date">
                        <div v-if="audit.finalScore && audit.finalScore.name && audit.score > 0" class="final-score">
                            <!-- <div class="color-and-name">
                                <div v-if="audit.finalScore.color" class="final-score-color" :style="{ backgroundColor: audit.finalScore.color }"></div>
                                <span class="text">{{ audit.finalScore.name }}</span>
                            </div>
                            <div class="text">({{ audit.score }}%)</div> -->

                            <div v-if="audit.finalScore.color" class="final-score-color" :style="{ backgroundColor: audit.finalScore.color }"></div>
                            <span class="text">{{ audit.finalScore.name }} ({{ audit.score }}%)</span>
                        </div>
                        <div v-else-if="audit.score != 0 || !audit.status == 'progress'" class="score-box">
                            <div class="score" :class="scoreClasses(audit.score)">
                                <span class="percent">{{ audit.score }}%</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- checklist -->
        <div class="resume" v-if="checklist">
            <span class="count" :class="{ done: checklist.completed == checklist.total }">
                <span class="completed">{{ checklist.completed }}</span>
                <span class="total">{{ '/' + checklist.total }}</span>
            </span>
        </div>
        <div :class="hasOrderNew ? 'more-actions-container-more' : 'more-actions-container'" v-if="hasMoreOptions">
            <div class="more-button" :class="{ active: showExtraDetail }" @click="infoOpened()"></div>
        </div>
        <div :class="hasOrderNew ? 'more-actions-container-more' : 'more-actions-container'" v-else-if="hasOrder && userAppAccess">
            <div class="more-button" :class="{ active: showExtraDetail }" @click="showOrderPopup()"></div>
        </div>
        <div class="details">
            <div class="more-buttons-content" v-if="hasMoreOptions && showExtraDetail">
                <div class="button-action reopen" v-if="hasReopen && this.completed" @click="reopenReport()">
                    <div class="reopen-btn" v-if="showExtraDetail"></div>
                    <span>{{ $t('menu_aux.action.reopen') }}</span>
                </div>
                <div class="button-action settings" @click="openSettings()">
                    <div class="settings-btn" v-if="showExtraDetail"></div>
                    <span>{{ $t('menu_aux.action.settings') }}</span>
                </div>
                <div class="button-action delete" @click="removeBtn()" v-if="!taskType && userAppAccess && (this.hasDelete || this.toolType !== 3)">
                    <div class="delete-btn" v-if="showExtraDetail"></div>
                    <span>{{ $t('menu_aux.action.delete') }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import mixin from '@/core/mixin'

export default {
    name: 'AppccTopDetail',
    mixins: [mixin],
    props: {
        title: {
            type: String,
            default: ''
        },
        issue: {
            type: undefined,
            default: false
        },
        completed: {
            type: undefined,
            default: false
        },
        createdAt: {
            type: undefined,
            default: ''
        },
        userInfo: {
            type: undefined,
            default: false
        },
        hasMoreOptions: {
            type: Boolean,
            default: false
        },
        audit: {
            type: undefined,
            default: false
        },
        checklist: {
            type: undefined,
            default: false
        },
        auditScore: {
            type: undefined,
            default: false
        },
        notAvailable: {
            type: [Boolean, Object],
            default: false
        },
        taskType: {
            type: Boolean,
            default: false
        },
        showBackButton: {
            type: Boolean,
            default: true
        },
        hasOrder: {
            type: Boolean,
            default: false
        },
        hasReopen: {
            type: Boolean,
            required: true
        },
        hasDelete: {
            type: Boolean,
            required: true
        },
        hasOrderNew: {
            type: Boolean,
            default: false
        },
        toolType: Number
    },
    data() {
        return {
            // Mostrar info adicional
            showExtraDetail: false
        }
    },
    computed: {
        userAppAccess() {
            return this.$store.getters['loginUser/getHasAccessApp'] || (this.$store.getters['login/getKitchenModeFeatureFlag'] === 1 && localStorage.getItem('kitchenMode') && localStorage.getItem('kitchenMode') === 'true')
        },
        user_id() {
            return this.$store.getters['loginUser/getLocalEmployee']
        },
        user() {
            var employee = this.$store.getters['employee/getEmployee'](this.user_id)
            if (employee) {
                return employee
            }
        },
        employee() {
            return this.$store.getters['employee/getEmployee'](this.userInfo.id)
        }
    },
    methods: {
        scoreClasses(value) {
            return {
                success: value == 100,
                progress: value > 75 && value < 100,
                warning: value > 0 && value <= 75,
                error: value == 0
            }
        },
        showReopen() {
            this.$emit('hasReopen')
        },
        showDelete() {
            this.$emit('hasDelete')
        },
        reopenReport() {
            this.showExtraDetail = false
            this.$emit('reopenButton')
        },
        openSettings() {
            this.$emit('settingsButton')
        },
        checkAvailability() {
            if (this.checklist.limit.start && this.checklist.limit.end) {
                // NO disponible
                if (this.notAvailable) {
                    var ball = '<div class="ball-red"></div>'
                    var text = '<span class="text">'
                    var endText = '</span>'
                    return ball + text + this.$t('assets.timing.no_availbale_future') + ' ' + this.checklist.limit.start.format('HH:mm') + 'h' + ' ' + this.$tc('assets.timing.to') + ' ' + this.checklist.limit.end.format('DD/MM/YYYY HH:mm') + 'h' + endText
                }

                const serverTimeDifference = this.$store.getters['getServerTimeDifference']
                const limitStart = moment(this.checklist.limit.start).add(serverTimeDifference, 'minutes')
                const limitEnd = moment(this.checklist.limit.end).add(serverTimeDifference, 'minutes')

                // disponible
                if (moment() > limitStart && moment() < limitEnd) {
                    var ball = '<div class="ball-green"></div>'
                    var text = '<span class="text">'
                    var endText = '</span>'
                    var finalPhrase = ball + text + this.$t('assets.timing.disponible') + ' '
                    if (limitStart.format('DD/MM/YYYY') != this.checklist.business_date.format('DD/MM/YYYY')) {
                        finalPhrase += limitStart.format('DD/MM/YYYY HH:mm') + ' ' + this.$tc('assets.timing.to') + ' '
                    } else {
                        finalPhrase += limitStart.format('HH:mm') + ' ' + this.$tc('assets.timing.to') + ' '
                    }
                    if (limitEnd.format('DD/MM/YYYY') != this.checklist.business_date.format('DD/MM/YYYY')) {
                        finalPhrase += limitEnd.format('DD/MM/YYYY HH:mm') + 'h' + endText
                    } else {
                        finalPhrase += limitEnd.format('HH:mm') + 'h' + endText
                    }
                    return finalPhrase
                }
                // no disponible, dias antiguos
                if (moment() > limitEnd) {
                    var ball = '<div class="ball-red"></div>'
                    var text = '<span class="text">'
                    var endText = '</span>'
                    return ball + text + this.$t('assets.timing.no_availbale') + ' ' + this.checklist.business_date.format('DD/MM/YYYY') + endText
                }
                // no disponible, dias futuros
                if (moment() < limitStart) {
                    var ball = '<div class="ball-red"></div>'
                    var text = '<span class="text">'
                    var endText = '</span>'
                    // the future day is today because only time is future
                    if (limitStart.format('DD/MM/YYYY') == moment().format('DD/MM/YYYY')) {
                        if (limitStart.format('DD/MM/YYYY') != limitEnd.format('DD/MM/YYYY')) {
                            return ball + text + this.$t('assets.timing.no_availbale_future') + ' ' + limitStart.format('HH:mm') + 'h' + ' ' + this.$tc('assets.timing.to') + ' ' + limitEnd.format('DD/MM/YYYY HH:mm') + 'h' + endText
                        }
                        return ball + text + this.$t('assets.timing.no_availbale_future') + ' ' + limitStart.format('HH:mm') + 'h' + ' ' + this.$tc('assets.timing.to') + ' ' + limitEnd.format('HH:mm') + 'h' + endText
                    }
                    return ball + text + this.$t('assets.timing.no_availbale_future') + ' ' + limitStart.format('DD/MM/YYYY') + endText
                }
            }
        },
        getMaxHour(hourA, hourB) {
            return moment(hourA).valueOf() > moment(hourB).valueOf() ? hourA : hourB
        },
        getMinHour(hourA, hourB) {
            return moment(hourA).valueOf() < moment(hourB).valueOf() ? hourA : hourB
        },
        formatDate(date) {
            return moment(date).format('DD/MM/YYYY HH:mm')
        },
        back() {
            this.$emit('backButton')
        },
        removeBtn() {
            this.$emit('deleteButton')
        },
        infoOpened() {
            if (!this.checklist) {
                this.showExtraDetail = !this.showExtraDetail
                this.$emit('infoOpened')
            } else if (this.taskType) {
                this.showExtraDetail = !this.showExtraDetail
                this.$emit('infoOpened')
            } else {
                if (this.completed) {
                    this.showExtraDetail = !this.showExtraDetail
                    this.$emit('infoOpened')
                }
            }
        },
        showOrderPopup() {
            this.$emit('orderPopup')
        }
    },
    filters: {
        moment: function (date, format) {
            return date ? moment(date).format(format) : ''
        }
    }
}
</script>

<style lang="scss">
#info-container {
    @include display-flex();
    @include justify-content(space-between);
    width: 100%;
    height: 100%;
    position: relative;

    .detail-info-container {
        width: calc(100% - 45px);
        @include display-flex();
        gap: 8px;
        // @include align-items(center);
        @extend .interaction;
        min-height: 40px;

        .detail-info {
            @include align-self(center);
            font-family: $text-bold;
            width: calc(100% - 45px);

            .detail-title {
                @extend .interaction;
                // @include font-size(ml);
                font-size: 18px;
                // @include text-ellipsis();
                font-family: $text-sbold !important;
                line-height: 30px;
            }
            .detail-complete {
                @include display-flex();
                .hour {
                    @include display-flex();
                    @include align-items(center);

                    .ball-green {
                        @include border-radius(50%);
                        width: 12px;
                        height: 12px;
                        min-width: 12px;
                        min-height: 12px;
                        background-color: $done;
                        margin-right: 6px;
                    }
                    .ball-red {
                        @include border-radius(50%);
                        width: 12px;
                        height: 12px;
                        min-width: 12px;
                        min-height: 12px;
                        background-color: $error;
                        margin-right: 6px;
                    }
                    .text {
                        @include font-size(s);
                        font-family: $text;
                        color: $neutro-s80;
                    }
                }
                .date {
                    padding-left: 6px;
                    @include font-size(sm);
                    width: fit-content;
                    font-family: $text-light;
                    color: $main-dark;
                }
            }
            .detail-created-at {
                width: 100%;
                @include display-flex();

                .detail-text {
                    padding-right: 6px;
                    @include font-size(s);
                }

                .detail-text-date {
                    @include text-ellipsis();
                    @include font-size(s);
                    width: max-content;
                }
            }
            .issue-info {
                @include display-flex();
                @include align-items(center);
                width: 100%;
                .detail-text {
                    // @include text-ellipsis();
                    width: 100%;

                    .created {
                        @include display-flex();
                        @include align-items(flex-start);
                        @include flex-direction(column);
                        @include font-size(s);
                        font-family: $text;
                        color: $neutro-s60;
                        height: auto;

                        .created-text {
                            @include text-ellipsis();
                            width: 100%;
                        }

                        .user-container {
                            @include text-ellipsis();
                            @include display-flex();
                            width: 100%;

                            .employee-img {
                                @include display-flex();
                                @include align-items(center);
                                width: 20px;
                                height: 20px;
                                min-width: 20px;
                                min-height: 20px;
                            }
                            .employee-name {
                                @include text-ellipsis();
                                @include font-size(s);
                                padding-left: 6px;
                                font-family: $text;
                                color: $neutro-s60;
                            }
                        }
                    }
                }
            }
            .detail-text {
                @include font-size(xs);
                font-family: $text;
                color: $neutro-s60;
            }
            .detail-text-date {
                @include font-size(xs);
                font-family: $text-bold;
                color: $neutro-s60;
            }

            .detail-user-info {
                @include display-flex();
                @include align-items(center);
                width: 100%;
                height: 22px;

                .employee-img {
                    @include display-flex();
                    @include align-items(center);
                    width: 20px;
                    height: 30px;
                }
                .employee-name {
                    @include text-ellipsis();
                    @include font-size(s);
                    padding-left: 6px;
                    font-family: $text;
                    color: $neutro-s60;
                }
            }
            .audit-points {
                @include display-flex();
                @include align-items(center);
                width: 100%;
                max-height: 50px;
                padding-top: 6px;

                .final-score {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 6px;
                    padding-left: 3px;
                    justify-content: center;

                    // @media screen and (max-width: 400px) {
                    //     flex-wrap: wrap;
                    //     gap: 0px;
                    // }

                    .color-and-name {
                        display: flex;
                        gap: 8px;
                        align-items: center;
                        width: 100%;
                        max-width: 100%;

                        .text {
                            width: 100%;
                            white-space: nowrap;
                            overflow-x: hidden !important;
                            text-overflow: ellipsis !important;
                        }
                    }

                    .final-score-color {
                        border-radius: 50%;
                        min-width: 16px;
                        width: 16px;
                        height: 16px;
                    }

                    .text {
                        width: 100%;
                        max-width: 100%;
                        font-family: $text-medium;
                        font-size: 18px;
                        padding-top: 3px;
                        line-height: 1.1em;

                        max-height: 80px; /* Ajusta este valor según el número de líneas que desees permitir */
                        overflow: hidden;
                        text-overflow: ellipsis; /* Agrega puntos suspensivos (...) al final del texto si se corta */
                        display: -webkit-box;
                        -webkit-line-clamp: 2; /* Número de líneas que deseas mostrar */
                        -webkit-box-orient: vertical;

                        @media screen and (max-width: 400px) {
                            font-size: 16px;
                        }
                    }
                }

                .top-title {
                    @include font-size(xs);
                    font-family: $text !important;
                    color: $neutro-s60;
                    text-align: left;
                    padding-right: 6px;
                }
                .score-box {
                    @include display-flex();
                    @include flex-wrap(wrap);
                    @include align-items();
                    @include justify-content();
                    @include border-radius(4px);
                    width: fit-content;
                    background-color: #fff;
                    max-width: calc(100%);
                    height: 30px;
                    padding: 0px 6px;

                    .score {
                        @include display-flex();
                        @include align-items();
                        height: 30px;
                        @include styScore();
                        width: auto;
                        text-align: right;

                        .points,
                        .total,
                        .percent {
                            display: inline-block;
                        }

                        .points {
                            @include font-size(s);
                            font-family: $conden-bold;
                            color: $neutro-s90;
                        }
                        .total {
                            @include font-size(s);
                            font-family: $conden-bold;
                            color: $neutro-s90;
                        }

                        .percent {
                            @include font-size(sm);
                            @include display-flex();
                            @include align-items();
                            font-family: $text-bold;
                            color: $main-dark;
                            height: 30px;
                            padding-top: 3px;
                        }
                    }
                }
                .status {
                    @include font-size(sm);
                    display: inline-block;
                    padding: 6px;
                    border-radius: 4px;
                    text-align: center;
                    line-height: 1.2;
                    margin-right: 6px;
                    height: 30px;
                    max-height: 30px;

                    &.completed {
                        // background-color: $main;
                        background-color: #1d7672;
                        color: white;
                    }
                    &.progress {
                        // background-color: $warning;
                        background-color: #244390;
                        color: white;
                    }
                    &.scheduled {
                        background-color: $info;
                        color: $main;
                    }
                }
            }
            &.with-values {
                align-self: unset;
            }
        }
        &.checklist {
            width: calc(100% - 80px);
            // margin-top: 10px;
            // .back-button {
            //     margin-top: 6px;
            // }
        }
        &.full {
            width: fit-content;
            overflow: hidden;

            .detail-info {
                width: calc(100% - 25px);

                &.full {
                    align-self: center;

                    .detail-title {
                        cursor: default;
                    }
                }
            }
            &.titleFullWidth {
                width: 100% !important;
            }
        }
    }

    .resume {
        @include display-flex();
        @include align-items(center);
        align-items: flex-start;
        //@include justify-content(center);
        width: auto;
        // height: 45px;
        // padding-right: 20px;

        .count {
            @include border-radius(4px);
            @include font-size(ml);
            font-family: $text-bold;
            background-color: #fff;
            padding: 6px 9px;
            color: $main-dark;

            .completed {
                color: inherit;
                font-family: inherit;
            }
            .total {
                color: inherit;
                font-family: inherit;
            }
        }
    }

    .more-actions-container {
        height: auto;
        /* position: absolute;
        z-index: $z-menu-top;
        right: 8px; */
        margin-left: 4px;
        .more-button {
            @extend .interaction;
            @include background($image: img('more_vert_aaaaaa.svg'), $size: 20px);
            @include border-radius(4px);
            @include align-self(center);
            background-color: $default-sec-color;
            width: 32px;
            height: 32px;
            &.active {
                background-image: img('more_horizontal_aaaaaa.svg') !important;
            }
        }
    }

    .more-actions-container-more {
        height: auto;
        margin-left: 4px;
        margin-top: 6px;
        .more-button {
            @extend .interaction;
            @include background($image: img('kebab_neutral_600.svg'), $size: 24px);
            @include border-radius(4px);
            @include align-self(center);
            background-color: $default-sec-color;
            width: 43px;
            height: 43px;
            &.active {
                background-image: img('more_horizontal_aaaaaa.svg') !important;
            }
        }
    }

    .details {
        height: auto;
        position: absolute;
        z-index: $z-menu-top;
        right: 8px;
        top: 55px;

        .more-buttons-content {
            @include display-flex();
            @include align-items(center);
            @include justify-content(center);
            @include flex-direction(column);
            gap: 4px;
            background-color: $color-white;
            padding: 4px;
            @include border-radius(4px);
            @include default-box-shadow();
            margin-bottom: 2px;

            .button-action {
                @include display-flex();
                @include align-items(center);
                @include justify-content(center);
                @include border-radius(4px);

                cursor: pointer;

                width: 100%;
                &.delete {
                    background-color: $error-dark;
                }
                &.settings,
                &.reopen {
                    background-color: $main-dark;
                }

                span {
                    @include font-size(s);
                    font-family: $text-bold;
                    color: $color-white;
                    padding: 6px;
                }

                .delete-btn,
                .settings-btn,
                .reopen-btn {
                    @extend .interaction;
                    @include border-radius(4px);
                    @include align-self(center);
                    width: 40px;
                    height: 40px;
                }

                .delete-btn {
                    @include background($image: img('delete_ffffff.svg'), $size: 20px);
                }
                .settings-btn {
                    @include background($image: img('settings_ffffff.svg'), $size: 20px);
                }

                .reopen-btn {
                    @include background($image: img('lockOpened.svg'), $size: 20px);
                }
            }
        }
    }
    &.portrait {
        display: flex;
        gap: 8px;

        &.task {
            width: 100%;
        }

        .resume {
            width: min-content;
        }
        .detail-info {
            .issue-info {
                .created {
                    @include font-size(xs, $important: true);
                }
            }
            .detail-title {
                // @include font-size(sm, $important: true);
                font-size: 18px;
                line-height: 1.1rem;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .detail-created-at {
                .detail-text {
                    overflow: hidden;
                    white-space: nowrap;
                    @include font-size(xs, $important: true);
                    width: max-content;
                }

                .detail-text-date {
                    @include font-size(xs, $important: true);
                    width: auto;
                }
            }
            .employee-name {
                @include font-size(xs, $important: true);
            }
            @include portrait-tablet {
                .employee-name {
                    @include font-size(s, $important: true);
                }
                // .detail-title {
                //     @include font-size(m, $important: true);
                // }
                .detail-created-at {
                    .detail-text {
                        @include font-size(s, $important: true);
                    }

                    .detail-text-date {
                        @include font-size(s, $important: true);
                    }
                }
            }

            &.with-values {
                .detail-created-at {
                    .detail-text {
                        overflow: hidden;
                        white-space: nowrap;
                        @include font-size(xs, $important: true);
                    }

                    .detail-text-date {
                        @include font-size(xs, $important: true);
                    }
                }
                .employee-name {
                    @include font-size(xs, $important: true);
                }
                @include portrait-tablet {
                    .employee-name {
                        @include font-size(s, $important: true);
                    }
                    .detail-title {
                        @include font-size(m, $important: true);
                    }
                    .detail-created-at {
                        .detail-text {
                            @include font-size(s, $important: true);
                        }

                        .detail-text-date {
                            @include font-size(s, $important: true);
                        }
                    }
                }
            }
            .audit-points {
                .status {
                    @include font-size(xs);
                }
            }
        }
        .detail-info-container {
            &.full {
                width: calc(100% - 60px);

                .detail-info {
                    width: calc(100% - 40px);
                }

                .detail-complete {
                    padding-top: 4px;

                    .hour {
                        @include align-items(flex-start);
                        .ball-green,
                        .ball-red {
                            position: relative;
                            top: 4px;
                        }
                        .text {
                            @include font-size(xs, $important: true);
                            line-height: 20px;
                        }
                    }
                }
                @include portrait-tablet {
                    .detail-title {
                        @include font-size(m, $important: true);
                    }
                    .detail-complete {
                        .hour {
                            .text {
                                @include font-size(xs, $important: true);
                                width: calc(100% - 2px);
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
