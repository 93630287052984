<template>
    <div class="questionCopy" :class="[questionClasses, $mq]" :data-type="item.type" :id="item.item_id" :noApply="item.apply == 0 ? 1 : 0" v-show="item.apply != 0 || (item.apply == 0 && showNoApply)">
        <div
            class="itemCopy"
            :class="[
                itemClasses,
                $mq,
                {
                    done: item.apply == 0,
                    sync: item.requireSync == true,
                    showErrors: showErrors && !item.complete_date && item.mandatory,
                    disabled: isActionPlanPage && item.isAuditItem
                }
            ]"
        >
            <div class="answerCopy" :class="answerClasses" @click.self="toggleMeta(def.item.itemsInfo.includes(item.type))">
                <div
                    class="line"
                    :class="{
                        'flex-row': !canShowValue && ![FORMAT.TITLE, FORMAT.SUBTITLE].includes(item.type) && ((list.status != 0 && list.status != 'progress' && item.value == null) || list.type == 1)
                    }"
                >
                    <div class="statement" :class="{ 'has-image': [FORMAT.PHOTO].includes(item.type) && value != null && Object.values(value) != 0 }" @click="toggleMeta(def.item.itemsInfo.includes(item.type))">
                        <div class="answer-title keep-format" :class="[{ optional: !item.mandatory && def.has_mandatory && def.item.itemsTitle.indexOf(item.type) == -1 }, { 'title-no-description': !item.description }]" v-html="itemTitle + ((item.mandatory && ![FORMAT.TITLE, FORMAT.SUBTITLE].includes(item.type)) || (![FORMAT.TITLE, FORMAT.SUBTITLE].includes(item.type) && item.mandatory) ? ' *' : '')" :data-after="$t('answer.optional')"></div>

                        <!-- DESCRIPTION -->
                        <div v-if="item.description" class="description keep-format" :class="{ 'description-short': showSeeMore }">
                            <p ref="description" v-html="descriptionParse(item.description)"></p>
                            <div v-if="descriptionIsExpandable">
                                <button v-if="showSeeMore" class="see-more">{{ $t('label.more') }}</button>
                                <button v-else class="see-less">{{ $t('label.less') }}</button>
                            </div>
                        </div>
                    </div>
                    <div class="meta" v-if="expanded && item.type == FORMAT.TITLE && canExpandSection(false)">
                        <div class="actions" v-if="showActions()">
                            <button class="btn ico action noApply" @click="apply(0)" v-if="item.apply == 1">
                                {{ $t('answer.actions.no_apply') }}
                            </button>
                            <button class="btn ico action apply" v-else-if="item.apply == 0" @click="apply(1)">
                                {{ $t('answer.actions.make_apply') }}
                            </button>
                        </div>
                    </div>
                    <template v-if="(item.mandatory || (!item.mandatory && item.apply != 0)) && item.type != 8">
                        <!-- RESPONSE / RESPUESTA -->
                        <div class="responseCopy" :class="{ 'no-description': !item.description, 'empty-gallery': (!value || value.length < 1) && item.type == 5 }">
                            <template v-if="canShowValue">
                                <!-- CHECK -->
                                <template v-if="item.type == FORMAT.CHECK">
                                    <!-- Tipo check -->
                                    <span class="responseCopy valueCopy" :class="[value ? 'checked' : '']" @click="checkAccess(!value)"></span>
                                </template>
                                <!-- YES/NO -->
                                <template v-if="item.type == FORMAT.YESNO">
                                    <div class="yes-no">
                                        <span class="valueCopy" :class="[value == 0 ? 'selected' : '']" data-value="0" @click="checkAccess(0)"></span>
                                        <span class="valueCopy" :class="[value == 1 ? 'selected' : '']" data-value="1" @click="checkAccess(1)"></span>
                                    </div>
                                </template>
                                <!-- YES/NO/NSNC -->
                                <template v-if="item.type == FORMAT.YESNONC">
                                    <span class="valueCopy" :class="[value == 0 ? 'selected' : '']" data-value="0" @click="checkAccess(0)">{{ $t('answer.no') }}</span>
                                    <span class="valueCopy" :class="[value == 1 ? 'selected' : '']" data-value="1" @click="checkAccess(1)">{{ $t('answer.yes') }}</span>
                                    <span class="valueCopy" :class="[value == 2 ? 'selected' : '']" data-value="2" @click="checkAccess(2)">{{ $t('answer.nsnc') }}</span>
                                </template>
                                <!-- TEXT -->
                                <template v-if="item.type == FORMAT.TEXT" @click="checkAccess()">
                                    <input type="text" class="valueCopy" v-model.lazy="value" @focus="handleFocus($event, item.item_id)" @blur="handleBlur" :class="{ disabled: !hasValidRole() }" maxlength="140" />
                                </template>
                                <!-- PHOTO / FOTO -->
                                <template v-if="item.type == FORMAT.PHOTO && (!aConfig || aConfig.limit <= 1)" @click="checkAccess()">
                                    <div class="actions-image" v-if="value == null || typeof value[0] == 'undefined'" :class="[value !== null && typeof value[0] !== 'undefined' && !isDisabled ? 'editing' : '']">
                                        <div class="add-file" v-if="value == null || value.length == 0" @click="capture($event, 'answer')">
                                            <input type="file" name="upload-image" class="input-file" :class="{ disabled: !hasValidRole() }" accept="image/*" capture="camera" @change="qImageReload" />
                                        </div>
                                        <!-- <template v-if="value != null && Object.values(value) != 0 && isOnline">
                                            <a class="btn view ico" @click="qImagePreview(0, true)"></a>
                                            <a class="btn delete ico" @click="qImageDelete(0)"></a>
                                        </template> -->
                                    </div>
                                    <div class="preview-image" :class="{ 'has-image': value != null && Object.values(value).length != 0 }" @click="qImagePreview(0)" :style="[isDisabled ? { position: 'relative', zIndex: 5 } : {}]">
                                        <template v-if="value != null && value[0] != undefined">
                                            <img v-if="isOnline || isBase64(value[0])" :src="value[0]" />
                                            <div class="image-offline" v-else></div>
                                        </template>
                                        <template v-else>
                                            <img src="" />
                                        </template>
                                    </div>
                                </template>
                                <!-- PHOTO GALLERY / GALERIA -->
                                <template v-if="item.type == FORMAT.PHOTO && aConfig.limit > 1">
                                    <div class="add-image">
                                        <div class="actions-image">
                                            <div class="add-file" @click="capture($event, 'answer')">
                                                <input type="file" name="upload-image" class="input-file" :class="{ disabled: !hasValidRole(), empty: !value || value.length == 0 }" accept="image/*" capture="camera" @change="qImageReload" />
                                            </div>
                                        </div>
                                        <div class="preview-image has-image" :style="[isDisabled ? { position: 'relative', zIndex: 5 } : {}]"></div>
                                    </div>
                                    <div class="galery-list" v-if="value && value.length > 0">
                                        <div class="galery-item" v-for="(item, index) in value">
                                            <template v-if="isOnline || isBase64(value[index])">
                                                <!-- <div class="actions-image editing">
                                                    <a class="btn delete ico" @click="qImageDelete(index)"></a>
                                                </div> -->
                                                <div class="preview-image has-image" @click="qImagePreview(index, true)" :style="{ position: 'relative', zIndex: 5 }">
                                                    <!-- offline -->
                                                    <template v-if="isBase64(value[index])">
                                                        <img :src="value[index]" />
                                                    </template>
                                                    <!-- connection -->
                                                    <template v-else>
                                                        <!-- <cld-image class="galery-img" v-if="value[index]" :publicId="value[index]" type="fetch" loading="lazy">
                                                            <cld-transformation height="100" crop="fill" fetchFormat="webp" />
                                                        </cld-image> -->
                                                        <img :src="value[index]" />
                                                    </template>
                                                </div>
                                            </template>
                                            <div v-else class="preview-image" :class="{ 'has-image': value != null && Object.values(value) != 0 }" @click="qImagePreview(0)" :style="[isDisabled ? { position: 'relative', zIndex: 5 } : {}]">
                                                <!-- <img :srcset="getOfflineImg()"> -->
                                                <div class="image-offline"></div>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                                <!-- NUMBER / NUMÉRICO -->
                                <template v-if="item.type == FORMAT.NUMBER">
                                    <div class="valueCopy" @click="checkAccess()" :class="[{ showTap: !value && value != '-' }, { 'no-value': value == '-' }]">
                                        {{ value ? (value != '-' ? value : $t('answer.no_value')) : '' }}
                                    </div>
                                </template>
                                <!-- DATE / FECHA -->
                                <template v-if="item.type == FORMAT.DATE" @click="checkAccess()">
                                    <div class="checkaccess" @click="checkAccess()">
                                        <!-- Tipo fecha -->
                                        <input @click="$event.target.showPicker()" type="date" class="valueCopy" :class="{ disabled: !hasValidRole() }" v-model.lazy="value" />
                                    </div>
                                </template>
                                <!-- TEXTAREA -->
                                <template v-if="item.type == FORMAT.TEXTAREA" @click="checkAccess()">
                                    <div class="checkaccess" @click="checkAccess()">
                                        <textarea class="valueCopy" v-model.lazy="value" @focus="handleFocus($event, item.item_id)" @blur="handleBlur" :class="[{ disabled: !hasValidRole() }]" maxlength="512"></textarea>
                                    </div>
                                </template>
                                <!-- MULTIPLE / SELECTOR -->
                                <template v-if="item.type == FORMAT.MULTIPLE">
                                    <div @click="checkAccess()">
                                        <Selector :items="item.config.values" :value="value" @input="qMultiple" @reset="qMultiple" :canClicked="canClicked()"></Selector>
                                    </div>
                                </template>
                                <!-- HOUR / HORA -->
                                <template v-if="item.type == FORMAT.TIME" @click="checkAccess()">
                                    <div class="checkaccess" @click="checkAccess()">
                                        <input type="time" class="valueCopy" v-model.lazy="value" />
                                    </div>
                                </template>
                                <!-- FECHA Y HORA -->
                                <template v-if="item.type == FORMAT.DATETIME" @click="checkAccess()">
                                    <div class="checkaccess" @click="checkAccess()">
                                        <!-- Tipo fecha y hora -->
                                        <input @click="$event.target.showPicker()" :class="{ disabled: !hasValidRole() }" type="datetime-local" class="valueCopy" v-model.lazy="value" />
                                    </div>
                                </template>
                                <!-- SIGNATURE / FIRMA -->
                                <template v-if="item.type == FORMAT.SIGNATURE">
                                    <div class="sign-block">
                                        <div class="signed" v-show="value" @click="qSignaturePreview()">
                                            <div class="ico" :style="{ backgroundImage: 'url(' + value + ')' }"></div>
                                            <div class="text" v-if="item.config.require_emp">{{ completeEmployee().name }}</div>
                                        </div>
                                        <div class="valueCopy unsigned" @click="checkAccess()"></div>
                                    </div>
                                </template>
                                <!-- TEMPERATURE / TEMPERATURA -->
                                <template v-if="item.type == FORMAT.TEMPERATURE">
                                    <!-- Tipo temperature -->
                                    <div class="valueCopy" @click="checkAccess()" :class="[{ showTap: !value && value != '-' }, { 'no-value': value == '-' }]">
                                        {{ value ? (value != '-' ? value + ' ' + item.config.units : $t('answer.no_value')) : '' }}
                                    </div>
                                </template>
                                <!-- EMAIL -->
                                <template v-if="item.type == FORMAT.EMAIL">
                                    <button v-if="value == (1 || true)" class="valueCopy sended">
                                        {{ $t('answer.sended_mail') }}
                                    </button>
                                    <button v-if="value == (0 || false || null)" class="valueCopy" @click="checkAccess()">
                                        {{ $t('answer.send_mail') }}
                                    </button>
                                </template>
                                <!-- DOCUMENT / DOCUMENTO -->
                                <template v-if="item.type == FORMAT.DOCUMENT">
                                    <div class="value-content keep-format" v-html="item.config.message"></div>
                                    <div class="conditions" v-if="item.config.statement || item.config.check">
                                        <span v-if="item.config.statement" class="text-check" v-html="item.config.statement"></span>
                                        <span v-if="item.config.check" class="valueCopy" :class="[value ? 'checked' : '']" @click="qDocument(!value)"></span>
                                    </div>
                                </template>
                                <!-- MESSAGE / MENSAJE -->
                                <template v-if="item.type == FORMAT.MESSAGE">
                                    <div class="value-content keep-format" v-html="item.config.message"></div>
                                </template>
                                <!-- LINK -->
                                <template v-if="item.type == FORMAT.LINK">
                                    <span class="valueCopy" @click="checkAccess()"></span>
                                </template>
                                <!-- RESOURCE / RECURSO -->
                                <template v-if="item.type == FORMAT.RESOURCE">
                                    <div class="checkaccess" @click="checkAccess()">
                                        <!-- Tipo recurso de libreria -->
                                        <span class="valueCopy" @click="qResource()" :class="[{ disabled: !hasValidRole() }]"></span>
                                    </div>
                                </template>
                            </template>
                            <!-- REQUIRE REVIEW -->
                            <div v-if="!item.planned_to_date && def.item.can_review" class="review-check-container">
                                <button v-if="!completed && item.require_review && canBeEdited" class="review-check-disabled" @click="cantValidateAnswer" />
                                <button v-if="completed && !isReview && item.require_review" class="review-check-pending" @click="validateAnswer" />
                                <button v-if="completed && isReview && item.require_review" class="review-check-done" :class="[$mq]" @click="unvalidateAnswer" />
                                <div v-if="!canShowValue && ![FORMAT.TITLE, FORMAT.SUBTITLE].includes(item.type) && ((list.status != 0 && list.status != 'progress' && item.value == null) || list.type == 1)" class="unknown">
                                    {{ $t('answer.unknown') }}
                                </div>
                            </div>
                            <!-- POSTPONED / POSPUESTO-->
                            <template v-else-if="item.planned_to_date">
                                <div class="postposed" @click="postpone">{{ $t('answer.postpone.postposed') }}</div>
                            </template>
                            <!-- DOCUMENT / DOCUMENTO -  UNANSWERED / SIN RESPUESTA -->
                            <template v-else-if="![FORMAT.TITLE, FORMAT.SUBTITLE].includes(item.type) && cType !== 5 && ((list.status != 0 && list.status != 'progress' && item.value == null) || list.type == 1)">
                                <!-- DOCUMENT -->
                                <template v-if="item.type == FORMAT.DOCUMENT">
                                    <div class="value-content keep-format" v-html="item.config.message"></div>
                                    <div class="conditions" v-if="item.config.statement || item.config.check">
                                        <div class="unknown">{{ $t('answer.unknown') }}</div>
                                    </div>
                                </template>
                                <!-- SIN RESPUESTA -->
                                <template v-else>
                                    <div class="unknown">{{ $t('answer.unknown') }}</div>
                                </template>
                            </template>
                        </div>
                    </template>
                    <!-- NO APLICA -->
                    <template v-else-if="!item.mandatory && item.apply == 0">
                        <div class="responseCopy">
                            <div class="responseNoApply">
                                <div class="text" @click="toggleMeta(false)"></div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
            <button v-if="item.type != FORMAT.TITLE || (canExpandSection(false) && showActions())" class="btn ico action toggleMeta" :class="expanded ? 'open' : ''" @click="toggleMeta(false)" :style="[canPostpone ? { top: '26px' } : { top: '8px' }]"></button>

            <!-- ITEM DETAILS (EMPLEADO Y FECHA) -->
            <div class="meta" v-if="expanded && def.item.itemsTitle.indexOf(item.type) == -1">
                <div v-if="enabledMeta" class="info">
                    <div v-if="item.requireSync" class="offline-message-box">
                        <span class="offline-message"><span class="task-icon-sync"></span>{{ $t('answer.pending_to_sync') }}</span>
                    </div>
                    <div class="sign" v-if="!itemsExcluded && !item.planned_to_date">
                        <!-- <span v-if="completeEmployee() && item.value != null" class="name"
                            ><span class="task-icon-done"></span>{{ $t('answer.performed_by') }}<span class="bold"> {{ completeEmployee().name + ' ' + completeEmployee().surname }}</span></span
                        > -->
                        <span v-if="completeEmployee()" class="name">
                            <span class="task-icon-done"></span>
                            {{ $t('answer.performed_by') }}
                            <span class="bold">
                                {{ completeEmployee().name + ' ' + completeEmployee().surname }}
                            </span>
                        </span>
                        <span v-else-if="!completeEmployee() && item.apply != 0 && item.value == null" class="name">
                            <span class="task-icon-notdone"></span>
                            {{ $t('answer.not_done') }}
                        </span>
                        <!-- <span v-if="!completeEmployee() && item.apply == 0" class="name">{{ $t('answer.meta_na') }}</span> -->
                    </div>
                    <!-- <div class="sign" v-if="!itemsExcluded && !item.planned_to_date && completeEmployee() && completeDate && item.apply != 0">
                        <span class="datetime" v-if="completeEmployee() && completeDate"><span class="task-icon-date"></span>{{ $t('answer.hour_indicator') }} {{ completeDate }}</span>
                    </div> -->
                    <div class="sign" v-if="!itemsExcluded && !item.planned_to_date && completeEmployee() && completeDate">
                        <span class="datetime" v-if="completeEmployee() && completeDate">
                            <span class="task-icon-date"></span>
                            <span class="datetime">{{ $t('answer.hour_indicator') }} {{ completeDate }}</span>
                        </span>
                    </div>
                    <div class="review" v-if="item.require_review && !itemsExcluded && !item.planned_to_date && item.apply != 0">
                        <span v-if="isReview" class="name"><span class="task-icon-reviewed"></span> {{ $t('answer.reviewed_by') }}</span>
                        <span v-else class="name"><span class="task-icon-toreview"></span>{{ $t('answer.not_reviewed') }}</span>
                        <span class="bold" v-if="isReview">{{ isReview.employee.name + ' ' + isReview.employee.surname }}</span>
                    </div>
                    <div class="review" v-if="item.require_review && !itemsExcluded && !item.planned_to_date && isReview">
                        <span class="datetime" v-if="isReview"><span class="task-icon-date"></span>{{ $t('answer.hour_indicator') }} {{ isReview.complete_date.format('HH:mm DD/MM/YYYY') }}</span>
                    </div>

                    <div class="timing" v-if="list.def.item.has_periodicity && !itemsExcluded && (item.planned_to_date || item.planned_from_date)">
                        <!-- If it can be posposed or not -->
                        <div class="line">
                            <!-- Yes -->
                            <template v-if="canPostpone">
                                <span class="name"> <span class="task-icon-postpone"></span>{{ $tc('answer.postpone.before_label', item.available > 1 ? 2 : 1) }} </span>
                                <span class="bold">
                                    {{ $tc('answer.postpone.before_date', item.available == 1 ? 1 : 2, { s: item.available }) }}
                                </span>
                            </template>

                            <!-- No -->
                            <!-- <template v-else-if="!item.planned_to_date">
                                <span class="name">{{ $t('answer.postpone.cannot') }}</span>
                            </template> -->
                        </div>

                        <!-- If planned -->
                        <div class="line">
                            <!-- Posposed task to another day (this appears like "posposed" and cannot be done) -->
                            <template v-if="item.planned_to_date">
                                <span class="date-info">
                                    <span class="task-icon-postpone_done"></span>
                                    {{ this.completeEmployee() ? $t('answer.postpone.planned_to', getFormattedPostponedDate(item.planned_to_date, true)) : $t('answer.postpone.planned_to_noname', getFormattedPostponedDate(item.planned_to_date, false)) }}
                                </span>
                            </template>

                            <!-- Posposed task from another day (This one has to be done) -->
                            <template v-else-if="item.planned_from_date">
                                <span v-if="item.planned_from_date" class="date-info">
                                    {{ this.completeEmployee() ? $t('answer.postpone.planned_from', getFormattedPostponedDate(item.planned_from_date, true)) : $t('answer.postpone.planned_from_noname', getFormattedPostponedDate(item.planned_from_date, false)) }}
                                </span>
                            </template>
                        </div>
                        <!-- Reasons -->
                        <div class="line" v-if="item.planned_to_date || item.planned_from_date">
                            <span class="bold"> <span class="task-icon-reason"></span>{{ $t('answer.postpone.reason') }}:</span>
                            <span class="reason">
                                <template v-if="item.planned_reason_msg">{{ item.planned_reason_msg }}</template>
                                <template v-else-if="item.planned_reason_id">{{ getReasonName }}</template>
                            </span>
                        </div>
                    </div>
                    <div class="role" v-if="item.config.roles && typeof item.config.roles !== 'undefined' && item.config.roles.length > 0 && item.config.roles.some((x) => Object.keys(roles).includes(x))">
                        <div class="line">
                            <span class="name">
                                {{ $t('answer.limited_for') }}
                            </span>
                            <template v-for="(role, index) in item.config.roles">
                                <span class="roles" v-if="role && roles && roles[role]" :key="index">
                                    {{ role && roles[role] ? roles[role].name : '' }}
                                </span>
                            </template>
                        </div>
                    </div>
                    <!-- <div v-if="item.requireSync" class="sync">{{ $t('answer.sync') }}</div> -->
                    <div
                        v-if="showPeriodicityTag && !item.planned_to_date && !item.planned_from_date"
                        class="task-label"
                        :class="{
                            daily: item.periodicity == 1,
                            weekly: item.periodicity == 2,
                            biweekly: item.periodicity == 3,
                            monthly: item.periodicity == 4,
                            quarterly: item.periodicity == 5,
                            biannually: item.periodicity == 6,
                            annually: item.periodicity == 7
                        }"
                    >
                        <span>{{ periodicityTranslation }}</span>
                    </div>
                </div>

                <div class="actions" v-if="showActions()" :class="{ hidden: isActionPlanPage && item.isAuditItem }">
                    <!-- <button v-if="cType != 3 && !isFuture" class="btn ico action share" @click="shareAnswer" >{{ $t('checklist.share.action') }}</button> -->
                    <button v-if="item.resource && ![FORMAT.RESOURCE].includes(item.type)" class="btn ico action hasFile" @click="qResource(true)">
                        {{ $t('library.resource') }}
                    </button>

                    <!-- NOTE BUTTON / BOTÓN NOTA -->
                    <button class="btn ico action comment" :class="[commenting ? 'editing' : '', item.message || item.image ? 'has' : '']" @click="inputComment">
                        {{ $t('answer.actions.note') }}
                    </button>
                    <button class="btn ico action postpone" v-if="list.def.item.has_periodicity && !itemsExcluded && canPostpone" :class="{ cannot: !canPostpone }" @click="postpone">
                        {{ $t('answer.actions.postpone') }}
                    </button>

                    <!-- No aplica -->
                    <button class="btn ico action noApply" v-if="!item.mandatory && item.apply != 0 && !item.value" @click="apply(0)">
                        {{ $t('answer.actions.no_apply') }}
                    </button>

                    <!-- REALIZAR -->
                    <button class="btn ico action apply" v-if="!item.mandatory && item.apply == 0 && !item.value" @click="apply(1)">
                        {{ $t('answer.actions.make_apply') }}
                    </button>
                </div>
            </div>
        </div>

        <div v-if="!(!item.message && !item.image && !commenting)" class="has-comment" :class="[{ hide: !item.message && !item.image && !commenting }, { editing: commenting }]">
            <!-- NOTE -->
            <div class="has-note">
                <span v-if="!commenting" class="editIcon"></span>
                <textarea v-model.lazy="comment" class="note" :maxlength="460" @input="calculedLength($event)" :disabled="!commenting" ref="refInputComment" @focus="handleFocus($event, item.item_id, true)" @blur="handleBlur"></textarea>
            </div>

            <!-- IMAGE OF NOTE -->
            <div class="has-image" :class="[{ editing: image }]">
                <div class="preview" v-if="commenting || image" @click="imagePreview">
                    <img class="image" :src="isOnline || isBase64(image) ? image : getOfflineImg()" />
                </div>

                <div class="add-file" ref="changeimage" @click="capture($event, 'comment')">
                    <input type="file" name="change-image" class="input-file" :class="{ disabled: !hasValidRole() }" accept="image/*" capture="camera" :disabled="!commenting" @change="imageReload" />
                </div>
            </div>
        </div>

        <!-- ACTION PLAN SECTION (ONLY FOR NEW AUDITS with ACTION PLANS - itemHasActionPlan) -->
        <section v-if="cType === 2 && isActionPlanPage" class="action-plan-section">
            <!-- ACTION PLAN PAGE -->
            <template v-if="isActionPlanPage">
                <div class="title-and-button">
                    <div class="action-plan-title">{{ $t('sensor.action_plan').toUpperCase() }}:</div>
                    <template>
                        <button v-if="actionPlansItemsAreVisible" @click="actionPlansItemsAreVisible = !actionPlansItemsAreVisible" class="show-hide" :aria-label="$t('button.hide')">
                            <span class="label">{{ $t('button.hide') }}</span>
                            <img src="/img/icons/arrow_up_blue.svg" />
                        </button>
                        <button v-else @click="actionPlansItemsAreVisible = !actionPlansItemsAreVisible" class="show-hide" :aria-label="$t('button.show')">
                            <span class="label">{{ $t('button.show') }}</span>
                            <img src="/img/icons/arrow_down_blue.svg" />
                        </button>
                    </template>
                </div>
                <!-- ACTION PLAN ITEMS -->
                <ActionPlanItems v-if="item && actionPlansItemsAreVisible" :auditItem="item" :list="list" />
            </template>
            <template v-else> </template>
        </section>

        <!-- ALERTS and INCIDENT REFERENCE -->
        <template v-if="!isActionPlanPage">
            <div class="item-alert" v-if="item.alerts && item.alerts_triggers[0].type != 'email'" :class="[{ 'has-issue': hasIssue }, item.alerts.class]">
                <div class="alex"></div>
                <div class="message keep-format">
                    <div class="preview" v-if="item.alerts.message" v-html="item.alerts.preview"></div>
                    <div class="read-more" @click="viewAlert">{{ getString('answer.actions.read_more') }}</div>
                </div>
            </div>
            <div class="item-alert" v-if="item.alerts && item.alerts_triggers[0].type == 'email'" :class="[{ 'has-issue': hasIssue }, item.alerts.class]">
                <div class="alex"></div>
                <div class="message keep-format email">
                    <div class="preview" v-if="item.alerts_triggers[0]" v-html="getAlertEmail(item.alerts_triggers[0])"></div>
                </div>
            </div>

            <!-- NO mostrar link en items con Plan de Acción -->
            <div class="reference-link" v-if="item.reference && item.alerts && !isAuditItemVinculatedToAnActionPlan">
                <div class="message keep-format" @click="goToReference">
                    <div class="preview" v-html="getStringVariable('answer.reference.issue', getStringIssueState(item.reference.status))"></div>
                    <div class="read-more">{{ getString('answer.reference.issue_action') }}</div>
                </div>
            </div>
        </template>

        <!-- apply message -->
        <Message v-if="showApplyMessage" :message="$t('answer.apply.message')" :type="'alert'" customClass="custom-alert-task" :hasActions="false" />
    </div>
</template>

<script>
import { FORMAT, TYPE } from '@/constants'
import Message from '@/components/domain/ui/Message'
import Selector from '../../inputs/SelectorPopup.vue'
import i18n from '@/i18n'
import ActionPlanItems from '@/components/domain/audit/ActionPlanItems.vue'

export default {
    name: 'answer',
    components: {
        Message,
        Selector,
        ActionPlanItems
    },
    props: {
        cType: { type: Number, default: 1 },
        cId: { type: String },
        aId: { type: String },
        item: { type: Object },
        list: { type: Object },
        actionplans: { type: Object },
        isIssueClosed: { type: Boolean, default: false },
        hasDependency_tpl: { type: [Boolean, Object], default: false },
        showErrors: {
            type: Boolean
        },
        isActionPlanPage: { type: Boolean, default: false }
    },
    data() {
        return {
            showingClock: false,
            expanded: false,
            hasAccess: true,
            userAccess: false,
            selected: null,
            error: false,
            warning: false,
            linkedIssue: false,
            commenting: false,
            loading: false,
            FORMAT: FORMAT,
            TYPE: TYPE,
            valueExpanded: true,
            showApplyMessage: false,
            requireSync: false,
            isOnline: true,
            showSeeMore: true,
            descriptionIsExpandable: false,
            actionPlansItemsAreVisible: true
        }
    },
    computed: {
        showPeriodicityTag() {
            return this.list.type == TYPE.TASK
        },
        showNoApply() {
            return this.$store.getters['checklist/getShowNoApply']
        },
        periodicityTranslation() {
            return this.$t(this.getPeriodicityTranslationKey())
        },
        typename() {
            switch (parseInt(this.list.type)) {
                case 1:
                    return 'tasks'
                case 2:
                    return 'audit'
                case 3:
                    return 'register'
                case 4:
                    return 'issues'
                default:
                    return 'checklist'
            }
        },
        def() {
            return this.list.def
        },
        hasError: {
            get() {
                return this.error
            },
            set(value) {
                this.error = value
            }
        },
        hasAlert: {
            get() {
                return this.warning
            },
            set(value) {
                this.warning = value
            }
        },
        hasIssue() {
            return this.item.alerts ? !!(this.item.alerts.issues && this.item.alerts.issues.length) : false
        },
        autosave() {
            return this.def.is_autosave
        },
        completeDate() {
            return this.item.complete_date ? moment(this.item.complete_date).format('HH:mm DD/MM/YYYY') : false
        },
        // employee id
        loggedUser() {
            return this.userAccess ? this.userAccess : this.$store.getters['loginUser/getLocalEmployee']
        },
        // Si tiene tiempo limitado, tendrá distintas "fases"
        isIntime() {
            if (this.def.has_timing) {
                if (!this.itemsExcluded) {
                    return this.list.state == 'intime'
                }
            } else {
                return true
            }
        },
        isPast() {
            if (this.def.has_timing) {
                if (!this.itemsExcluded) {
                    return this.list.state == 'past'
                }
            } else {
                return false
            }
        },
        isFuture() {
            if (this.def.has_timing) {
                if (!this.itemsExcluded) {
                    return this.list.state == 'future'
                }
            } else {
                return false
            }
        },
        // Styles and template facilities
        itemTitle() {
            return this.item.title
        },
        uncompleted() {
            if (this.def.has_timing) {
                // return !this.canBeEdited || !this.canShowValue
                return !this.canBeEdited && !this.canShowValue
                // return !this.canShowValue
                // return !( this.def.item.itemsImage.includes(this.item.type) ? (this.value ? this.value.lenght : false) : this.value ) || (!this.canBeEdited && this.isPast);
            } else {
                return !this.value && !this.canBeEdited
            }
        },
        completed() {
            log(this.completeDate)
            if (this.completeEmployee() && this.completeDate != false) {
                return true
            } else {
                if ([FORMAT.PHOTO, FORMAT.SIGNATURE].includes(this.item.type)) {
                    return !!(!(typeof this.value === 'undefined' || this.value == '' || this.value == null) && typeof this.value === 'object' && this.value.length)
                } else {
                    return !!this.value
                }
            }
        },
        enabledMeta() {
            // return this.cType != 3
            return true
        },
        getReasonName() {
            var reason = this.$store.getters['getSingleReasonPostpone'](this.item.planned_reason_id)
            return reason ? reason.name : false
        },
        questionClasses() {
            return {
                past: this.isPast,
                future: this.isFuture,
                child: this.item.parent_id,
                hidden: !this.item.is_visible,
                uncompleted: this.uncompleted,
                loading: this.loading,
                'value-collapsed': !this.valueExpanded,
                'postposed-from': this.item.planned_from_date,
                'postposed-to': this.item.planned_to_date && this.item.type != 8
            }
        },
        answerClasses() {
            return {
                toReview: this.item.require_review && (this.canBeEdited || this.completed) && !this.item.planned_to_date && this.def.item.can_review,
                galery: this.item.type == FORMAT.PHOTO && this.aConfig.limit > 1
            }
        },
        itemClasses() {
            return {
                done: this.completed,
                error: this.hasError,
                issue: this.item.alerts ? !!(this.item.alerts.issues && this.item.alerts.issues.length) : false,
                alert: this.hasAlert,
                excluded: this.def.item.itemsExcluded.includes(this.item.type),
                error: !this.hasValidRole() && !this.completed
                // sync: (typeof this.item.requireSync !== 'undefined' && this.item.requireSync) || this.requireSync
            }
        },
        itemsExcluded() {
            return this.def.item.itemsExcluded.includes(this.item.type)
        },
        user() {
            if (this.loggedUser) {
                return this.$store.getters['employee/getEmployee'](this.loggedUser)
            }
            return false
        },
        roles() {
            return this.$store.getters['employee/getAllRoles']
        },
        userAppAccess() {
            return this.$store.getters['loginUser/getHasAccessApp']
        },
        isDisabled() {
            const timezone = this.$store.getters['getTimeZoneName']
            let now = moment()

            // try {
            //     const options = {
            //         timeZone: timezone,
            //         year: 'numeric',
            //         month: 'numeric',
            //         day: 'numeric',
            //         hour: 'numeric',
            //         minute: 'numeric'
            //     }
            //     const dateTimeFormat = new Intl.DateTimeFormat([], options).formatToParts()
            //     const objectDate = {}
            //     for (const item of dateTimeFormat) {
            //         objectDate[item.type] = item.value
            //     }
            //     const dateString = `${objectDate['year']}-${objectDate['month'].padStart(2, '0')}-${objectDate['day'].padStart(2, '0')} ${objectDate['hour'].padStart(2, '0')}:${objectDate['minute'].padStart(2, '0')}`
            //     now = moment(dateString)
            // } catch (error) {
            //     now = moment()
            //     logError(error)
            // }

            if (this.loggedUser) {
                if (this.list.type == 1) {
                    if (now.isBefore(this.list.limit.start)) {
                        return 'future'
                    }
                }
                if (this.user) {
                    if (!this.userAppAccess) {
                        return 'no_access_app'
                    }
                    if (!this.userAppAccess && (this.cType == 2 || this.cType == 1)) {
                        return true
                    }
                }
                if (this.isIssueClosed) {
                    return true
                }
                // if (this.hasDependency_tpl) {
                //     return 'dependency_tpl'
                // }
                if (this.def.has_timing) {
                    let now = moment().set({ s: 0, ms: 0 })
                    let start = moment(this.list.limit.start)
                    let end = moment(this.list.limit.end)

                    if (!now.isBetween(start, end) && now.isAfter(end)) {
                        // if ( !now.isBetween(start, end) && parseInt(this.list.score) < 100 ) {
                        return 'past'
                    } else {
                        return false
                    }
                    // } else if( this.list.type == 2 && ( this.list.complete_date || moment(this.list.start_date).valueOf() > moment().valueOf() ) ){
                } else if (this.list.type == 3 && this.list.complete_date) {
                    // validate edits dates
                    let currentTemplate = this.$store.getters['register/getTemplateById'](this.$route.params.id)
                    if (currentTemplate.config && typeof currentTemplate.config.edit_days !== 'undefined') {
                        let edit_days = parseInt(currentTemplate.config.edit_days)

                        // by default
                        if (edit_days == -1) {
                            return true
                        } else {
                            let completed_date = moment(this.list.complete_date).startOf('day')
                            let compare_date = moment().startOf('day').subtract(edit_days, 'days')
                            let days_difference = completed_date.diff(compare_date, 'days')
                            log('daysDifference', days_difference)

                            if (days_difference < 0) {
                                return true
                            }
                        }
                    } else {
                        // default
                        return true
                    }

                    // Registers
                    return false
                    // IS DISABLED AUDITS / AUDITORÍAS (no deshabilitar item si es plan de acción: this.cType !== 5)
                } else if (this.list.type == 2 && this.cType !== 5 && (this.list.complete_date || (this.isPast && this.def.can_edit.inPast) || (this.isFuture && this.def.can_edit.inFuture))) {
                    // Audits
                    return 'past'
                } else {
                    return false
                }
            } else {
                return true
            }
        },
        aConfig() {
            return this.item.config && Object.keys(this.item.config).length > 0 ? this.item.config : false
        },
        canShowValue() {
            let result = false

            // Action Plan checklist
            if (this.cType === 5) {
                result = true
            }

            if (!this.item.planned_to_date) {
                if (this.def.item.itemsVisibles.includes(this.item.type)) {
                    result = true
                } else if (this.def.item.itemsExcluded.includes(this.item.type)) {
                    result = true
                } else if (this.def.item.itemsImage.includes(this.item.type) && (this.canBeEdited || (this.value ? this.value.length > 0 : false))) {
                    result = true
                } else if (!this.def.item.itemsImage.includes(this.item.type) && (this.canBeEdited || this.value)) {
                    result = true
                }
            }
            return result
        },
        canPostpone() {
            if (this.def.item.can_pospone) {
                let maxDate = moment(this.list.business_date).add(parseInt(this.item.available), 'days')
                let currentDate = moment(this.list.business_date)

                if (this.item.margin > 0 && this.item.available > 0 && currentDate.isBefore(maxDate) && this.def.item.has_periodicity && !this.item.planned_to_date && (!this.value || (this.def.item.itemsImage.includes(this.item.type) && this.value.length == 0))) {
                    return true
                } else {
                    return false
                }
            }
        },
        canBeEdited() {
            if (this.isDisabled == 'no_access_app') {
                let now = moment().set({ s: 0, ms: 0 })
                if (this.list.type == 1) {
                    let start = moment(this.list.limit.start)
                    let end = moment(this.list.limit.end)

                    if (!now.isBetween(start, end) && now.isAfter(end)) {
                        return false
                    } else {
                        return true
                    }
                }
            } else if (this.isDisabled == 'future') {
                return true
            } else {
                return !this.isDisabled
            }
        },
        // for Action Plan Items
        checkRef() {
            return this.item.data && this.item.data.checklist ? this.item.data.checklist : null
        },
        itemRef() {
            return this.item.data && this.item.data.ref_item ? this.item.data.ref_item : null
        },
        // Comp values para las notas propias de ESTA tarea
        comment: {
            get() {
                return this.item.message ? this.item.message : ''
            },
            set(value) {
                const self = this

                const commentParams = {
                    cType: this.cType,
                    check: this.cId,
                    answer: this.aId,
                    message: value,
                    checkRef: this.cType === 5 ? this.checkRef : null,
                    itemRef: this.cType === 5 ? this.itemRef : null
                }

                if (this.image) {
                    commentParams.file = this.image
                }

                this.$store.dispatch(self.typename + '/comment', commentParams).then((response) => {
                    if (!response.status) {
                        self.$snackbar.error({
                            closeable: true,
                            duration: 5000,
                            message: self.$t('tasks.answer_offline')
                        })
                    } else {
                        self.item.message = value
                    }
                })
            }
        },
        image: {
            get() {
                return this.item.image ? this.item.image : false
            },
            set(value) {
                let self = this
                this.$store
                    .dispatch(self.typename + '/comment', {
                        cType: this.cType,
                        check: this.cId,
                        answer: this.aId,
                        message: this.comment,
                        file: value,
                        checkRef: this.cType === 5 ? this.checkRef : null,
                        itemRef: this.cType === 5 ? this.itemRef : null
                    })
                    .then(function (response) {
                        if (response && !response.status) {
                            self.$snackbar.error({
                                closeable: true,
                                duration: 5000,
                                message: self.$t('tasks.answer_offline')
                            })
                        } else {
                            self.commenting = false
                        }
                    })
            }
        },
        // Valor propio de la tarea
        value: {
            get() {
                if (this.autosave) {
                    if (this.def.item.itemsImage.includes(this.item.type)) {
                        let aux = this.item.value
                        if (!aux) {
                            return []
                        } else {
                            return aux
                        }
                    } else {
                        return this.item.value
                    }
                } else {
                    // TODO tiene sentido dividir el get por autosave?
                    return this.item.value
                }
            },
            set(value) {
                const self = this
                self.hasError = false
                self.$overlay.hide()

                const timezone = this.$store.getters['getTimeZoneName']
                let date = false

                try {
                    const options = {
                        timeZone: timezone,
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric'
                    }
                    const dateTimeFormat = new Intl.DateTimeFormat([], options).formatToParts()
                    const objectDate = {}
                    for (const item of dateTimeFormat) {
                        objectDate[item.type] = item.value
                    }
                    const dateString = `${objectDate['year']}-${objectDate['month'].padStart(2, '0')}-${objectDate['day'].padStart(2, '0')} ${objectDate['hour'].padStart(2, '0')}:${objectDate['minute'].padStart(2, '0')}`
                    date = moment(dateString).unix()
                } catch (error) {
                    date = moment().unix()
                    logError(error)
                }

                if (this.item.type == FORMAT.CHECK) {
                    value = value ? 1 : 0
                }

                if (this.item.type == FORMAT.MULTIPLE) {
                    value = value
                }

                if (!this.isDisabled) {
                    var employee = this.loggedUser
                    var bExistsIssueAlert = false
                    var bExistsEmailAlert = false
                    log('this.autosave -> ', this.autosave)
                    log('this.item.alerts_triggers -> ', this.item.alerts_triggers)

                    if (this.item.alerts_triggers) {
                        bExistsEmailAlert = this.item.alerts_triggers.some((alert) => {
                            return alert.type == 'email' && this.checkCondition(alert, value)
                        })

                        bExistsIssueAlert = this.item.alerts_triggers.some((alert) => {
                            return alert.type == 'info' && this.checkCondition(alert, value)
                        })
                    }

                    if (bExistsEmailAlert) {
                        this.checkIfAlert(value, employee, date, this.completeDate)

                        if (bExistsIssueAlert) {
                            this.viewAlert()
                        }
                    } else {
                        if (this.autosave) {
                            // si son de "tipo texto", cuando no tengan valor se autoresetearan (para hacer bien el recount)
                            if (value == '' && this.item.type == 4) {
                                this.$store.dispatch(self.typename + '/reset', {
                                    check: this.cId,
                                    answer: this.aId,
                                    checkRef: this.cType === 5 ? this.checkRef : null,
                                    itemRef: this.cType === 5 ? this.itemRef : null,
                                    audit: this.cType === 5 ? this.list.id : null
                                })
                            }
                            var completeDate = this.completeDate
                            if ([FORMAT.TEXT, FORMAT.DATE, FORMAT.TEXTAREA, FORMAT.TIME, FORMAT.DATETIME].includes(this.item.type)) {
                                // TODO
                                this.loading = true

                                // DISPATCH ANSWER
                                this.$store
                                    .dispatch(self.typename + '/answer', {
                                        cType: this.cType,
                                        check: this.cId,
                                        answer: this.aId,
                                        emp: employee,
                                        date: date,
                                        value: value,
                                        tTitle: this.item.config && this.item.config.custom_title ? this.list.title : undefined,
                                        checkRef: this.cType === 5 ? this.checkRef : null,
                                        itemRef: this.cType === 5 ? this.itemRef : null,
                                        audit: this.cType === 5 ? this.list.id : null
                                    })
                                    .then(function (response) {
                                        // emit popup close issue
                                        self.loading = false

                                        if (self.item.mandatory && !completeDate) {
                                            self.$emit('checkAllMandatory')
                                        }
                                        if (!response.status) {
                                            if (!self.$store.getters.getOffline) {
                                                self.$snackbar.error({
                                                    closeable: true,
                                                    duration: 5000,
                                                    message: self.$t('tasks.answer_offline')
                                                })
                                            } else {
                                                self.requireSync = true
                                            }
                                        } else {
                                            if (self.value == '' || self.value == '') {
                                                self.resetAnswer()
                                            } else {
                                                self.hasAccess = !self.item.require_pin
                                                self.userAccess = false
                                            }

                                            if (self.isAuditItemVinculatedToAnActionPlan) {
                                                self.displayCreateActionPlanConfirmPopup()
                                            } else {
                                                self.viewAlert()
                                            }
                                        }
                                    })
                            } else {
                                // TODO
                                this.loading = true

                                // SE HACE EL DISPATCH A ANSWER()
                                this.$store
                                    .dispatch(self.typename + '/answer', {
                                        cType: this.cType, // checklist type
                                        check: this.cId, // checklist ID
                                        answer: this.aId, // item ID
                                        answer_type: this.item.type, // item type
                                        emp: employee,
                                        date: date,
                                        value: value,
                                        customTitle: this.item.config && this.item.config.custom_title ? true : false,
                                        checkRef: this.cType === 5 ? this.checkRef : null,
                                        itemRef: this.cType === 5 ? this.itemRef : null,
                                        audit: this.cType === 5 ? this.list.id : null
                                    })
                                    .then(function (response) {
                                        let complete_date = self.completeDate
                                        if (self.item.mandatory && complete_date) {
                                            self.$emit('checkAllMandatory')
                                        }
                                        self.loading = false
                                        self.$overlay.hide()

                                        if (!response.status) {
                                            if (!self.$store.getters.getOffline) {
                                                self.$snackbar.error({
                                                    closeable: true,
                                                    duration: 5000,
                                                    message: self.$t('tasks.answer_offline')
                                                })
                                            } else {
                                                self.hasAccess = !self.item.require_pin
                                                self.userAccess = false

                                                // self.viewAlert()
                                                if (self.isAuditItemVinculatedToAnActionPlan) {
                                                    self.displayCreateActionPlanConfirmPopup()
                                                } else {
                                                    self.viewAlert()
                                                }

                                                self.requireSync = true
                                            }
                                        } else {
                                            self.hasAccess = !self.item.require_pin
                                            self.userAccess = false

                                            if (self.isAuditItemVinculatedToAnActionPlan) {
                                                // si es un item de Auditoría vinculado a un Plan de Acción,
                                                // mostrar el popup de confirmación de creación de un plan de acción
                                                // AUDITORÍAS - CREAR PLAN DE ACCIÓN / CREATE ACTION PLAN POPUP
                                                self.displayCreateActionPlanConfirmPopup()
                                            } else {
                                                // para otros Items (ALERTAS)
                                                self.viewAlert()
                                            }
                                        }
                                    })
                            }
                        } else {
                            if (self.mandatedValidation(value)) {
                                self.hasError = true
                            }
                            if (value == '' && this.item.type == 4) {
                                this.$store
                                    .dispatch(self.typename + '/reset', {
                                        cType: this.cType,
                                        check: this.cId,
                                        answer: this.aId,
                                        checkRef: this.cType === 5 ? this.checkRef : null,
                                        itemRef: this.cType === 5 ? this.itemRef : null,
                                        audit: this.cType === 5 ? this.list.id : null
                                    })
                                    .then((response) => {
                                        self.$emit('acTitle')
                                    })
                            }

                            this.loading = true

                            this.$store
                                .dispatch(self.typename + '/answer', {
                                    cType: this.cType,
                                    check: this.cId,
                                    answer: this.aId,
                                    answer_type: this.item.type,
                                    emp: employee,
                                    date: date,
                                    value: value,
                                    customTitle: this.item.config && this.item.config.custom_title ? true : false,
                                    checkRef: this.cType === 5 ? this.checkRef : null,
                                    itemRef: this.cType === 5 ? this.itemRef : null,
                                    audit: this.cType === 5 ? this.list.id : null
                                })
                                .then(function (response) {
                                    let complete_date = self.completeDate
                                    if (response.status) {
                                        if (response.data) {
                                            self.$emit('acTitle')
                                        } else {
                                            self.$emit('acTitle')
                                        }
                                    }
                                    if (self.item.mandatory && complete_date) {
                                        self.$emit('checkAllMandatory')
                                    }
                                    self.loading = false
                                    self.$overlay.hide()
                                    if (!response.status) {
                                        if (!self.$store.getters.getOffline) {
                                            self.$snackbar.error({
                                                closeable: true,
                                                duration: 5000,
                                                message: self.$t('tasks.answer_offline')
                                            })
                                        } else {
                                            self.requireSync = true
                                        }
                                    } else {
                                        self.viewAlert()
                                        self.hasAccess = !self.item.require_pin
                                        self.userAccess = false
                                    }
                                })
                        }
                    }
                }
            }
        },
        isReview() {
            let result = this.item.review
            if (result) {
                result.complete_date = moment(result.complete_date)
            }
            return result
        },
        bSensorLimitation() {
            return this.$store.getters['getSensorLimitation'] == 1
        },
        itemHasActionPlan() {
            return this.item.alerts !== false && this.item.alerts_triggers !== false
            // return this.item.reference && this.item.reference.type === 5 // type 5 is Action Plan
        },
        actionPlanTemplates() {
            return Object.values(this.$store.getters['audit/getActionPlanTemplates'])
        },
        isAuditItemVinculatedToAnActionPlan() {
            const self = this

            if (self.cType == 2 && self.item.alerts_triggers) {
                const alertTriggerTemplateID = self.item.alerts_triggers[0].tpl ? self.item.alerts_triggers[0].tpl : null

                if (!alertTriggerTemplateID) {
                    return false
                }

                const templateIdIsActionPlan = this.actionPlanTemplates.some((template) => template.id === alertTriggerTemplateID)

                return templateIdIsActionPlan
            } else {
                return false
            }
        }
    },
    methods: {
        // CREATE ACTION PLAN / CREAR PLAN DE ACCIÓN - calls store to create it
        createActionPlan() {
            const self = this

            this.$store
                .dispatch(self.typename + '/addActionPlan', {
                    actionPlanId: self.item.alerts.issues[0], // Action Plan ID
                    cType: 5,
                    checklist_ref: self.list.id,
                    item_ref: self.item.item_id
                })
                .then(function (response) {
                    if (response) {
                        return true
                    }
                })
        },
        // DISPLAY CREATE ACTION PLAN POPUP CONFIRM
        displayCreateActionPlanConfirmPopup() {
            const self = this

            // const alertMessage = this.item.alerts.message
            const alertMessage = this.item.alerts.message ? this.item.alerts.message : this.item.alerts_triggers[0].msg ? this.item.alerts_triggers[0].msg : this.$t('popup.action_plan_create.message2')

            // Action Plan Name
            const actionPlanTemplateId = this.item.alerts_triggers[0].tpl
            const actionPlanObj = self.actionPlanTemplates.filter((template) => template.id === actionPlanTemplateId)[0]
            const actionPlanName = actionPlanObj ? actionPlanObj.name : null

            self.$popup.confirm({
                title: `${alertMessage}`,
                tplName: actionPlanName ? `${actionPlanName}` : null,
                textSave: this.$t('menu_aux.action.create'),
                textCancel: this.$t('menu_aux.action.cancel'),
                type: 'action-plan-create',
                callCancel: () => {
                    // si la respuesta es 'Cancelar', no guardar el valor que creaba el plan de acción
                    this.resetAnswer()

                    self.$popup.close()
                },
                callSave: () => {
                    // if popup response is 'Create', create action plan:
                    this.createActionPlan() // calls de store to create the action plan

                    self.$popup.close()
                }
            })
        },
        displayDeletedActionPlanConfirmSnackbar() {
            const snackbarData = {
                duration: 4000,
                closeable: true,
                message: this.$t('issues.deleted_msg')
            }

            this.$snackbar.success(snackbarData)
        },
        // DELETE ACTION PLAN / BORRAR PLAN DE ACCIÓN (calls the store)
        async deleteActionPlan() {
            const self = this

            // data for store
            const data = {
                checklist: self.list,
                employeeId: this.loggedUser, // employee id
                item_id: this.item.item_id,
                action_plan_id: this.item.reference.checklist // action plan id
            }

            try {
                const response = await self.$store.dispatch('audit/deleteActionPlan', data)
                if (response) {
                    return true
                }
            } catch (error) {
                console.error(error)
            }
        },
        // DELETE ACTION PLAN POPUP CONFIRM
        async displayDeleteActionPlanConfirmPopup() {
            const self = this

            return new Promise((resolve) => {
                self.$popup.confirm({
                    title: self.$t('popup.action_plan_loss.title'),
                    message: self.$t('popup.action_plan_loss.message'),
                    textSave: self.$t('popup.delete.text_save'),
                    textCancel: self.$t('popup.delete.text_cancel'),
                    type: 'action-plan-loss',
                    callCancel: () => {
                        self.$popup.close()
                        resolve(false)
                    },
                    callSave: async () => {
                        self.$popup.close()
                        const actionPlanWasDeleted = await this.deleteActionPlan()

                        if (actionPlanWasDeleted) {
                            self.$popup.close()
                            this.displayDeletedActionPlanConfirmSnackbar()
                            resolve(true)
                            this.resetAnswer()
                        }
                    }
                })
            })
        },
        calculedLength(e) {
            const value = e.target.value
            const maxLength = 460
            const jsonValue = jsonEscapeUTF(JSON.stringify(value))
            const difference = jsonValue.length - value.length

            if (jsonValue.length > maxLength) {
                e.target.value = value.substring(0, maxLength - difference)
            }
        },
        completeEmployee() {
            var employee = this.item.employee
            // log('>> EMPLOYEE', this.item, this.item.item_id, employee, moment().format('HH:mm DD/MM/YYYY'))

            if (typeof employee === 'object') {
                return employee
            } else if (employee) {
                return this.$store.getters['employee/getEmployee'](employee)
            } else {
                return false
            }
        },
        getPeriodicityTranslationKey() {
            switch (this.item.periodicity) {
                case 1:
                    return this.$t('answer.daily')
                case 2:
                    return this.$t('answer.weekly')
                case 3:
                    return this.$t('answer.biweekly')
                case 4:
                    return this.$t('answer.monthly')
                case 5:
                    return this.$t('answer.quarterly')
                case 6:
                    return this.$t('answer.biannually')
                case 7:
                    return this.$t('answer.annually')
                default:
                    return ''
            }
        },
        checkCondition(conditions, value) {
            const val = conditions.value
            const operator = conditions.operator

            switch (this.item.type) {
                case FORMAT.EMAIL:
                case FORMAT.DOCUMENT:
                case FORMAT.CHECK:
                case FORMAT.YESNO:
                    return compare(parseInt(val) == 1 || (val == '' && operator == '!empty'), value == true || value == 'true' || (value != null && val == '' && operator == '!empty'), operator)
                    break
                case FORMAT.YESNONC:
                    return compare(parseInt(val) || (val == '' && operator == '!empty'), value || (val == '' && operator == '!empty'), operator)
                    break
                case FORMAT.MULTIPLE:
                    return compare(val || (val == '' && operator == '!empty'), value, operator)
                    break
                case FORMAT.TEXT:
                case FORMAT.TEXTAREA:
                case FORMAT.PHOTO:
                case FORMAT.DATE:
                case FORMAT.TIME:
                case FORMAT.DATETIME:
                case FORMAT.SIGNATURE:
                    return compare(val, value, operator)
                    break
                case FORMAT.NUMBER:
                    var aux = value.toString().replace(',', '.')
                    return compare(parseFloat(aux), val, operator) || (val == '' && operator == '!empty')
                    break
                case FORMAT.TEMPERATURE:
                    var aux = value.toString().replace(',', '.')
                    return compare(parseFloat(aux), val, operator) || (val == '' && operator == '!empty')
                    break
                default:
                    break
            }

            return true
        },
        checkIfAlert(value, employee, date, completeDate) {
            setTimeout(() => {
                const email = this.item.alerts_triggers.reduce((acc, alert) => {
                    if (alert.type === 'email') {
                        acc = alert.to
                    }
                    return acc
                }, '')

                let valueToPopUp = value

                if (this.item.type === FORMAT.CHECK) {
                    valueToPopUp = this.$t('popup.email-alert.check')
                }

                if (this.item.type === FORMAT.EMAIL) {
                    valueToPopUp = this.item.config.to.join(', ')
                }

                if (this.item.type === FORMAT.YESNONC || this.item.type === FORMAT.YESNO) {
                    if (value === 0) {
                        valueToPopUp = this.$t('popup.email-alert.s_n_ns_0')
                    } else if (value === 1) {
                        valueToPopUp = this.$t('popup.email-alert.s_n_ns_1')
                    } else if (value === 2) {
                        valueToPopUp = this.$t('popup.email-alert.s_n_ns_2')
                    }
                }

                let message = this.$t('popup.email-alert.message', { email: email, value: valueToPopUp })

                if (this.item.type === FORMAT.SIGNATURE || this.item.type === FORMAT.PHOTO || this.item.type === FORMAT.GALLERY) {
                    valueToPopUp = `<img src='${value}' class='image-preview'/>`
                    message = this.$t('popup.email-alert.message-img', { email: email, value: valueToPopUp })
                }

                this.$popup.emailAlert({
                    message: message,
                    callSave: () => {
                        if (this.autosave) {
                            // si son de "tipo texto", cuando no tengan valor se autoresetearan (para hacer bien el recount)
                            if ([FORMAT.TEXT, FORMAT.DATE, FORMAT.TEXTAREA, FORMAT.TIME, FORMAT.DATETIME].includes(this.item.type)) {
                                // TODO
                                this.loading = true
                                this.$store
                                    .dispatch(this.typename + '/answer', {
                                        cType: this.cType,
                                        check: this.cId,
                                        answer: this.aId,
                                        emp: employee,
                                        date: date,
                                        value: value,
                                        checkRef: this.cType === 5 ? this.checkRef : null,
                                        itemRef: this.cType === 5 ? this.itemRef : null,
                                        audit: this.cType === 5 ? this.list.id : null
                                    })
                                    .then((response) => {
                                        // emit popup close issue
                                        this.loading = false

                                        if (this.item.mandatory && !completeDate) {
                                            this.$emit('checkAllMandatory')
                                        }
                                        if (!response.status) {
                                            if (!this.$store.getters.getOffline) {
                                                this.$snackbar.error({
                                                    closeable: true,
                                                    duration: 5000,
                                                    message: this.$t('tasks.answer_offline')
                                                })
                                            } else {
                                                this.requireSync = true
                                            }
                                        } else {
                                            if (this.value == '') {
                                                this.resetAnswer()
                                            } else {
                                                this.hasAccess = !this.item.require_pin
                                                this.userAccess = false
                                            }

                                            this.viewAlert()
                                        }
                                    })
                            } else {
                                // TODO
                                this.loading = true
                                this.$store
                                    .dispatch(this.typename + '/answer', {
                                        cType: this.cType,
                                        check: this.cId,
                                        answer: this.aId,
                                        answer_type: this.item.type,
                                        emp: employee,
                                        date: date,
                                        value: value,
                                        checkRef: this.cType === 5 ? this.checkRef : null,
                                        itemRef: this.cType === 5 ? this.itemRef : null,
                                        audit: this.cType === 5 ? this.list.id : null
                                    })
                                    .then((response) => {
                                        if (this.item.mandatory && completeDate) {
                                            this.$emit('checkAllMandatory')
                                        }
                                        this.loading = false
                                        this.$overlay.hide()
                                        if (!response.status) {
                                            if (!this.$store.getters.getOffline) {
                                                this.$snackbar.error({
                                                    closeable: true,
                                                    duration: 5000,
                                                    message: this.$t('tasks.answer_offline')
                                                })
                                            } else {
                                                this.requireSync = true
                                            }
                                        } else {
                                            this.hasAccess = !this.item.require_pin
                                            this.userAccess = false

                                            this.viewAlert()
                                        }
                                    })
                            }
                        } else {
                            if (this.mandatedValidation(value)) {
                                this.hasError = true
                            }
                            this.loading = true
                            this.$store
                                .dispatch(this.typename + '/answer', {
                                    cType: this.cType,
                                    check: this.cId,
                                    answer: this.aId,
                                    answer_type: this.item.type,
                                    emp: employee,
                                    date: date,
                                    value: value,
                                    checkRef: this.cType === 5 ? this.checkRef : null,
                                    itemRef: this.cType === 5 ? this.itemRef : null,
                                    audit: this.cType === 5 ? this.list.id : null
                                })
                                .then((response) => {
                                    if (this.item.mandatory && completeDate) {
                                        this.$emit('checkAllMandatory')
                                    }
                                    this.loading = false
                                    this.$overlay.hide()
                                    if (!response.status) {
                                        if (!this.$store.getters.getOffline) {
                                            this.$snackbar.error({
                                                closeable: true,
                                                duration: 5000,
                                                message: this.$t('tasks.answer_offline')
                                            })
                                        } else {
                                            this.requireSync = true
                                        }
                                    } else {
                                        this.viewAlert()
                                        this.hasAccess = !this.item.require_pin
                                        this.userAccess = false
                                    }
                                })
                        }
                    }
                })
            }, 100)
        },
        canExpandSection(update) {
            var bUpdate = typeof update !== 'undefined' ? update : true
            let subitems = this.$store.getters[this.typename + '/getItemsBySection'](this.list.id, this.item.item_id)
            if (Object.values(subitems).length > 0) {
                // if all items of section are mandatory, then section can't be expanded
                if (Object.values(subitems).every((item) => item.mandatory) && bUpdate) {
                    this.toggleMeta(false)
                }
                return !Object.values(subitems).every((item) => item.mandatory)
            }
            return false
        },
        showActions() {
            return !this.isDisabled
        },
        hasValidRole() {
            var itemRoles = this.aConfig ? this.aConfig.roles : false

            if (itemRoles && itemRoles.length > 0) {
                // IF ALL ROLES HAS BEEN DELETED
                if (itemRoles.length == 1 && !itemRoles.every((v) => Object.keys(this.roles).includes(v))) {
                    return true
                } else return itemRoles.includes(this.user.rol)
            } else return true
        },
        textParse(text) {
            if (!text) {
                return
            }

            let result = text

            result = result.replace(/\n/gi, ' <br> ')
            result = result.replace(/&lt;br&gt;/g, '<br>')

            result = result.replace(/([^\S]|^)(((https?\:\/\/)|(http?\:\/\/)|(www\.))(\S+))/gi, function (match, space, url) {
                var hyperlink = url.startsWith('www') ? 'https://' + url : url
                return space + '<a target="_blank" href="' + hyperlink + '">' + url + '</a>'
            })

            return result
        },
        descriptionParse(msg) {
            return this.textParse(msg)
        },
        getAlertEmail(oAlert) {
            return this.$tc('supervise.checklists.alert_email', 0, { to: oAlert.to })
        },
        getString(str) {
            return this.$t(str)
        },
        getStringObject(str, obj) {
            return i18n.t(str, obj)
        },
        getStringVariable(str, name) {
            return this.$t(str, { s: name })
        },
        getStringIssueState(id) {
            let issueState = this.$store.getters['getSingleIssueStates'](id)
            return issueState ? issueState.name.toLowerCase() : ''
        },
        getStringLimitRoles(roles) {
            var nameRoles = []
            for (var role in roles) {
                if (this.roles && roles && roles[role] && this.roles[roles[role]]) {
                    nameRoles.push(this.roles[roles[role]].name)
                }
            }
            return nameRoles
                .join(', ')
                .toString()
                .replace(/,(?=[^,]*$)/, ' ' + this.$t('answer.or'))
        },
        getFormattedPostponedDate(date, withName) {
            if (withName) {
                return {
                    name: this.completeEmployee().name + ' ' + this.completeEmployee().surname,
                    s1: moment(date).format('dddd'),
                    s2: moment(date).format('DD'),
                    s3: moment(date).format('MMMM')
                }
            }
            return { s1: moment(date).format('dddd'), s2: moment(date).format('DD'), s3: moment(date).format('MMMM') }
        },
        handleFocus(event, id, atElement) {
            if (this.checkAccess(undefined, atElement)) {
                atElement = typeof atElement === 'undefined' ? false : atElement

                var scroll = {
                    container: this.$parent.$refs.container,
                    target: document.getElementById(id),
                    offset: -105
                }
                if (atElement) {
                    var parentHeight = scroll.target.offsetHeight
                    var elHeight = event.target.offsetHeight
                    scroll.offset = elHeight
                    // scroll.offset = parentHeight - elHeight;
                }
                this.$root.handleFocusKeyboard(this, scroll)
            }
        },
        handleBlur(e) {
            this.$root.handleBlurKeyboard(this)
        },
        checkAvailability() {
            let finalPhrase = ''
            if (this.list.limit.start && this.list.limit.end) {
                finalPhrase += this.list.limit.start.format('DD/MM/YYYY HH:mm') + ' ' + this.$tc('assets.timing.to') + ' '

                if (this.list.limit.end.format('DD/MM/YYYY') != this.list.business_date.format('DD/MM/YYYY')) {
                    finalPhrase += this.list.limit.end.format('DD/MM/YYYY HH:mm') + 'h'
                } else {
                    finalPhrase += this.list.limit.end.format('HH:mm') + 'h'
                }
                return finalPhrase
            }
        },

        // CHECKACCESS Valida si se puede o no realizar una accion
        async checkAccess(val, isNote) {
            const self = this
            const type = self.item.type
            const toolType = self.cType

            log('checkAccess - checkAccess')
            log(' - type: ' + type)
            log(' - val: ' + val)

            // --- ACTION PLAN LOSS - only for Audits (toolType 2) where there is an action plan for the item ---
            // para que, si el item de la auditoría que ha generado un plan de acción ya tiene un valor,
            // no se pueda hacer click en el mismo valor y marcar el item como "no realizado":
            if (toolType === 2 && self.itemHasActionPlan && val == self.item.value) {
                return
            }
            // si se intenta cambiar el valor que ya tiene el item de una Auditoría EN PROGRESO que ha generado Plan de Acción...
            if (toolType === 2 && self.isDisabled != 'past' && self.itemHasActionPlan && val != self.item.value && self.item.reference && self.item.reference.type == 5) {
                // si es el listado de Plan de Acción, que no permita cambiar el valor:
                if (self.isActionPlanPage) {
                    self.$popup.alert({ message: self.$t('popup.action_plan_audit_item_not_allowed.message') })

                    return
                }

                // para que no aparezca el popup de confirmación de
                // borrar plan de acción al intentar agregar una nota
                if (isNote) {
                    return
                }

                // si es el listado de Auditoría (y no el de Plan de Acción),
                // se permite cambiar el valor del item y borrar el Plan de Acción previa confirmación:
                // DELETE ACTION PLAN POPUP CONFIRM:
                const popupResult = await this.displayDeleteActionPlanConfirmPopup()

                // si en el popup se respondió "No", que no se cambie el valor del item
                if (popupResult === false) {
                    return
                }
            }

            if (this.isDisabled) {
                if (this.isDisabled == 'future') {
                    let text = this.checkAvailability()
                    this.$popup.alert({
                        message: this.$t('detail.checklist.future', { name: self.user.name, date: text })
                    })
                }

                if (this.isDisabled == 'past' && this.list.type == 2 && this.cType !== 5) {
                    this.$popup.alert({ message: this.$t('detail.audit.past', { name: self.user.name }) })
                } else if (this.isDisabled == 'past' || (this.isPast && !this.def.can_edit.inPast)) {
                    this.$popup.alert({ message: this.$t('answer.timing.cannot_past') })
                } else if (this.isFuture && !this.def.can_edit.inFuture) {
                    this.$popup.alert({ message: this.$t('answer.timing.cannot_future') })
                } else if (this.isIssueClosed) {
                    this.$popup.alert({ message: this.$t('answer.issue_closed', { name: self.user.name }) })
                } else {
                    this.$popup.alert({ message: this.$t('answer.no_access_app', { name: self.user.name }) })
                }
            } else if (!this.hasValidPermissions()) {
                this.$popup.alert({
                    message: this.$t('answer.timing.not_has_role_permission', {
                        name: self.user.name,
                        roles: self.rolesWithWritePermissions()
                    })
                })
            } else if (!this.hasValidRole()) {
                this.$popup.alert({
                    message: this.$t('answer.timing.not_has_role', {
                        name: self.user.name,
                        roles: self.getStringLimitRoles(self.item.config.roles)
                    })
                })
                // return false
            } else if (this.hasDependency_tpl) {
                this.$popup.confirm({
                    message: self.$t('answer.dependency.label', { name: self.user.name, tpl: self.hasDependency_tpl.title }),
                    textSave: self.$t('answer.dependency.view'),
                    textCancel: self.$t('popup.default.text_close'),
                    callSave: function () {
                        self.$emit('cleanDependency')
                        self.$router.push({ name: 'ChecklistDetail', params: { id: self.hasDependency_tpl.id } })
                    }
                })
            } else if (this.item.require_pin && this.loggedUser && !this.isReview && this.canBeEdited) {
                if (!this.hasAccess) {
                    this.$popup.access({
                        list: this.$store.getters['employee/getAccessUsers'](0),
                        callAfterAccess: function (response) {
                            if (response) {
                                if (response.status) {
                                    self.hasAccess = true
                                    self.userAccess = response.emp_id
                                    self.$popup.close()

                                    if (type == 1) self.qCheck(val)
                                    if (type == 2 || type == 3) self.qOption(val)
                                    if (type == 6) self.qNumeric()
                                    if (type == 14) self.qSignature()
                                    if (type == 15) self.qTemperature()
                                    if (type == 16) self.qSendMail()
                                    if (type == 1) self.qDocument(val)
                                    if (type == 19) self.qLink()
                                }
                            }
                        }
                    })
                } else {
                    if (type == 1) self.qCheck(val)
                    if (type == 2 || type == 3) self.qOption(val)
                    if (type == 6) self.qNumeric()
                    if (type == 14) self.qSignature()
                    if (type == 15) self.qTemperature()
                    if (type == 16) self.qSendMail()
                    if (type == 1) self.qDocument(val)
                    if (type == 19) self.qLink()
                }
            } else if (!this.item.require_pin && this.loggedUser && this.canBeEdited) {
                this.hasAccess = true

                if (!isNote) {
                    if (type == 1) {
                        this.qCheck(val)
                    } else if (type == 2 || type == 3) {
                        this.qOption(val)
                    } else if (type == 6) {
                        this.qNumeric()
                    } else if (type == 14) {
                        this.qSignature()
                    } else if (type == 15) {
                        this.qTemperature()
                    } else if (type == 16) {
                        this.qSendMail()
                    } else if (type == 1) {
                        this.qDocument(val)
                    } else if (type == 19) {
                        this.qLink()
                    } else {
                        return true
                    }
                } else {
                    return true
                }
            } else if (!this.loggedUser && !this.isReview && this.canBeEdited) {
                this.$popup.login({
                    list: this.$store.getters['employee/getLoginUsers'](1),
                    callAfterAccess: function (response) {
                        if (response) {
                            if (!self.hasAccess) {
                                self.checkAccess()
                            } else {
                                self.$popup.close()
                            }
                        }
                    }
                })
            } else {
                return true
            }
        },
        hasValidPermissions() {
            const permissions = {}
            if (Object.keys(this.actionplans).includes(this.cID)) {
                permissions = this.actionplans[this.cID]
            }
            
            let isValid = false
            const rolEmployee = this.$store.getters['employee/getRol'](this.user.rol)

            if(!permissions.config) return true
            
            if (permissions.config && !(permissions.config.nRoles || permissions.config.roles)) {
                return true
            }

            if (permissions && permissions.config && permissions.config.nRoles && permissions.config.nRoles.length > 0) {
                //LVL >= 8
                if (rolEmployee.level >= 8) {
                    return true
                }

                permissions.config.nRoles.forEach((role) => {
                    if (this.user.rol == role.roleId && role.permissions.includes('w')) {
                        isValid = true
                        return
                    }
                })
            }
            
            return isValid
        },
        rolesWithWritePermissions() {
            const permissions = this.list
            const roles = []
            if (permissions && permissions.config && permissions.config.nRoles && permissions.config.nRoles.length > 0) {
                const idOfRoles = permissions.config.nRoles.map((role) => {
                    if (role.permissions.includes('w')) {
                        return role.roleId
                    }
                })
                Object.values(this.roles).map((role) => {
                    if (idOfRoles.includes(role.id)) {
                        roles.push(role.name)
                    }
                })
            }

            return roles
                .join(', ')
                .toString()
                .replace(/,(?=[^,]*$)/, ' ' + this.$t('answer.or'))
        },
        canClicked(val, isNote) {
            var self = this
            var type = this.item.type
            log('checkAccess')
            log(' - type: ' + type)
            log(' - val: ' + val)

            if (this.isDisabled) {
                if (this.isDisabled == 'future') {
                    return false
                }

                if (this.isDisabled == 'past' && this.list.type == 2) {
                    return false
                } else if (this.isDisabled == 'past' || (this.isPast && !this.def.can_edit.inPast)) {
                    return false
                } else if (this.isFuture && !this.def.can_edit.inFuture) {
                    return false
                } else if (this.isIssueClosed) {
                    return false
                } else {
                    return false
                }
            } else if (!this.hasValidRole()) {
                return false
                // return false
            } else if (this.hasDependency_tpl) {
                return false
            } else if (this.item.require_pin && this.loggedUser && !this.isReview && this.canBeEdited) {
                if (!this.hasAccess) {
                    this.$popup.access({
                        list: this.$store.getters['employee/getAccessUsers'](0),
                        callAfterAccess: function (response) {
                            if (response) {
                                if (response.status) {
                                    self.hasAccess = true
                                    self.userAccess = response.emp_id
                                    self.$popup.close()

                                    if (type == 1) self.qCheck(val)
                                    if (type == 2 || type == 3) self.qOption(val)
                                    if (type == 6) self.qNumeric()
                                    if (type == 14) self.qSignature()
                                    if (type == 15) self.qTemperature()
                                    if (type == 16) self.qSendMail()
                                    if (type == 1) self.qDocument(val)
                                    if (type == 19) self.qLink()
                                }
                            }
                        }
                    })
                } else {
                    if (type == 1) self.qCheck(val)
                    if (type == 2 || type == 3) self.qOption(val)
                    if (type == 6) self.qNumeric()
                    if (type == 14) self.qSignature()
                    if (type == 15) self.qTemperature()
                    if (type == 16) self.qSendMail()
                    if (type == 1) self.qDocument(val)
                    if (type == 19) self.qLink()
                }
            } else if (!this.item.require_pin && this.loggedUser && this.canBeEdited) {
                this.hasAccess = true
                if (!isNote) {
                    if (type == 1) {
                        this.qCheck(val)
                    } else if (type == 2 || type == 3) {
                        this.qOption(val)
                    } else if (type == 6) {
                        this.qNumeric()
                    } else if (type == 14) {
                        this.qSignature()
                    } else if (type == 15) {
                        this.qTemperature()
                    } else if (type == 16) {
                        this.qSendMail()
                    } else if (type == 1) {
                        this.qDocument(val)
                    } else if (type == 19) {
                        this.qLink()
                    } else {
                        return true
                    }
                } else {
                    return true
                }
            } else if (!this.loggedUser && !this.isReview && this.canBeEdited) {
                return false
            } else {
                return true
            }
        },
        qCheck(newVal) {
            if (!this.isDisabled || this.isDisabled == 'no_access_app') {
                if (newVal && newVal != '') {
                    this.value = newVal
                } else {
                    this.resetAnswer()
                }
            }
        },
        qOption(newVal) {
            if (!this.isDisabled) {
                if (newVal != this.value) {
                    this.value = newVal
                } else {
                    this.resetAnswer()
                }
            }
        },
        qMultiple(newVal) {
            if (!this.isDisabled) {
                if (!newVal) {
                    this.resetAnswer()
                } else {
                    this.value = newVal
                }
            }
        },

        qImagePreview(pos, force = false) {
            // let self = this

            if (this.isOnline && ((typeof this.value[pos] !== 'undefined' && this.value[pos] != false) || force)) {
                this.$popup.media({
                    image: this.value[pos],
                    callDelete: () => {
                        this.$popup.close()
                        if (this.checkAccess()) {
                            const galleryCopy = Array.from(this.value)

                            galleryCopy.splice(pos, 1)

                            let value = Array.from(galleryCopy)

                            if (value && value.length <= 0) {
                                this.resetAnswer()
                            } else {
                                this.value = Array.from(value)
                            }
                        }
                    }
                })
            }
        },
        qImageDelete(pos) {
            if (!this.isDisabled) {
                let self = this

                this.$popup.confirm({
                    message: self.$t('popup.delete.title'),
                    textSave: self.$t('popup.delete.text_save'),
                    textCancel: self.$t('popup.delete.text_cancel'),
                    callSave: function () {
                        if (self.value && self.value.length <= 1) {
                            self.resetAnswer()
                        } else {
                            let aux = self.value
                            aux.splice(pos, 1)
                            self.value = JSON.parse(JSON.stringify(aux))
                        }
                    }
                })
            }
        },
        qImageReload(e) {
            const isOfflineActive = this.$store.getters.getOffline
            let self = this
            let valueItem = JSON.parse(JSON.stringify(this.value))

            if (!self.isDisabled) {
                if (!valueItem || valueItem.length <= 0) {
                    valueItem = []
                }
                if (fnCheckConnection() || isOfflineActive) {
                    if (typeof self.aConfig.limit === 'undefined' || valueItem.length < self.aConfig.limit || self.aConfig.limit == 1) {
                        const files = e.target.files || e.dataTransfer.files
                        if (!files.length) return

                        const image = new Image()
                        const reader = new FileReader()

                        reader.onload = (e) => {
                            image.onload = () => {
                                if (self.aConfig.limit == 1) {
                                    valueItem = []
                                }

                                valueItem.push(this.resizeImage(image))

                                self.value = JSON.parse(JSON.stringify(valueItem))
                            }
                            image.src = e.target.result
                        }
                        reader.readAsDataURL(files[0])
                    } else {
                        self.$popup.alert({ message: self.$t('answer.galery.message') })
                    }
                } else {
                    // TODO show error
                }
            }
        },

        resizeImage(image) {
            const maxWidth = andyImageLimit.width
            const maxHeight = andyImageLimit.height

            let newWidth, newHeight

            if (image.width > image.height) {
                newWidth = maxWidth
                newHeight = (image.height / image.width) * maxWidth
            } else {
                newHeight = maxHeight
                newWidth = (image.width / image.height) * maxHeight
            }

            const canvas = document.createElement('canvas')
            const ctx = canvas.getContext('2d')

            canvas.width = newWidth
            canvas.height = newHeight

            ctx.drawImage(image, 0, 0, newWidth, newHeight)

            const newImage = canvas.toDataURL('image/jpeg', 0.7)

            return newImage
        },

        capture(e, type) {
            const isOfflineActive = this.$store.getters.getOffline
            const isComment = type === 'comment'

            if ((fnCheckConnection() || isOfflineActive) && this.checkAccess(true, isComment)) {
                if (process.env.VUE_APP_IS_APP == 'TRUE') {
                    var self = this

                    e.preventDefault()
                    e.stopPropagation()
                    var self = this

                    var cameraOptions = {
                        destinationType: 1,
                        quality: 50,
                        encodingType: 0, // JEPG
                        targetWidth: andyImageLimit.width,
                        targetHeight: andyImageLimit.height,
                        correctOrientation: true
                    }

                    const check = isComment ? this.$store.getters.getNoteImg : this.item.config.capture
                    let valueItem = JSON.parse(JSON.stringify(this.value))
                    if (!isComment && typeof self.aConfig.limit !== 'undefined' && valueItem && valueItem.length >= self.aConfig.limit && self.aConfig.limit != 1) {
                        this.$popup.alert({ message: this.$t('answer.galery.message') })
                    } else {
                        if (!check || check === 'all') {
                            this.$popup.confirmUpload({
                                message: this.$t('cordova.plugins.camera.title'),
                                textSaveStart: this.$t('cordova.plugins.camera.text_camera'),
                                textSave: this.$t('cordova.plugins.camera.text_library'),
                                callSaveStart: function () {
                                    if (fnCheckConnection() || isOfflineActive) {
                                        if (self.$store.getters.getIsAndroid) {
                                            self.captureNative(type)
                                        } else {
                                            navigator.camera.getPicture(
                                                function (imageData) {
                                                    if (fnCheckConnection()) {
                                                        getFileContentAsBase64(
                                                            imageData,
                                                            function (base64Image) {
                                                                // Then you'll be able to handle the myimage.png file as base64
                                                                log(base64Image)
                                                                imageData = base64Image

                                                                var bgImg = new Image()
                                                                bgImg.onload = function () {
                                                                    switch (type) {
                                                                        case 'answer':
                                                                            let aux = self.value
                                                                            if (!aux) {
                                                                                aux = []
                                                                            }
                                                                            aux.push(bgImg.src)
                                                                            self.value = JSON.parse(JSON.stringify(aux))
                                                                            // self.value.push(bgImg.src);
                                                                            break
                                                                        case 'comment':
                                                                            self.image = bgImg.src
                                                                            break
                                                                    }
                                                                }
                                                                bgImg.src = imageData
                                                            },
                                                            function (err) {
                                                                self.$snackbar.error({ closeable: true, message: self.$t('popup.media.image_empty') })
                                                            }
                                                        )
                                                    } else {
                                                        this.$snackbar.error({
                                                            duration: 5000,
                                                            closeable: true,
                                                            message: this.$t('empty_pages.connection_description')
                                                        })
                                                    }
                                                },
                                                function (message) {},
                                                cameraOptions
                                            )
                                        }
                                    } else {
                                        this.$snackbar.error({
                                            duration: 5000,
                                            closeable: true,
                                            message: this.$t('empty_pages.connection_description')
                                        })
                                    }
                                },
                                callSave: function () {
                                    cameraOptions.sourceType = 0
                                    if (fnCheckConnection() || isOfflineActive) {
                                        if (self.$store.getters.getIsAndroid) {
                                            self.captureNativeGallery(type)
                                        } else {
                                            navigator.camera.getPicture(
                                                function (imageData) {
                                                    if (fnCheckConnection()) {
                                                        getFileContentAsBase64(
                                                            imageData,
                                                            function (base64Image) {
                                                                // Then you'll be able to handle the myimage.png file as base64
                                                                log(base64Image)
                                                                imageData = base64Image

                                                                var bgImg = new Image()
                                                                bgImg.onload = function () {
                                                                    switch (type) {
                                                                        case 'answer':
                                                                            let aux = self.value
                                                                            if (!aux) {
                                                                                aux = []
                                                                            }
                                                                            aux.push(bgImg.src)
                                                                            self.value = JSON.parse(JSON.stringify(aux))
                                                                            // self.value.push(bgImg.src);
                                                                            break
                                                                        case 'comment':
                                                                            self.image = bgImg.src
                                                                            break
                                                                    }
                                                                }
                                                                bgImg.src = imageData
                                                            },
                                                            function (err) {
                                                                self.$snackbar.error({ closeable: true, message: self.$t('popup.media.image_empty') })
                                                            }
                                                        )
                                                    } else {
                                                        this.$snackbar.error({
                                                            duration: 5000,
                                                            closeable: true,
                                                            message: this.$t('empty_pages.connection_description')
                                                        })
                                                    }
                                                },
                                                function (message) {},
                                                cameraOptions
                                            )
                                        }
                                    } else {
                                        this.$snackbar.error({
                                            duration: 5000,
                                            closeable: true,
                                            message: this.$t('empty_pages.connection_description')
                                        })
                                    }
                                }
                            })
                        } else if (this.item.config.capture == 'camera') {
                            this.$popup.confirm({
                                message: this.$t('cordova.plugins.camera.title'),
                                textSave: this.$t('cordova.plugins.camera.text_camera'),
                                callSave: function () {
                                    if (fnCheckConnection() || isOfflineActive) {
                                        if (self.$store.getters.getIsAndroid) {
                                            self.captureNative(type)
                                        } else {
                                            navigator.camera.getPicture(
                                                function (imageData) {
                                                    if (fnCheckConnection()) {
                                                        getFileContentAsBase64(
                                                            imageData,
                                                            function (base64Image) {
                                                                // Then you'll be able to handle the myimage.png file as base64
                                                                log(base64Image)
                                                                imageData = base64Image

                                                                var bgImg = new Image()
                                                                bgImg.onload = function () {
                                                                    switch (type) {
                                                                        case 'answer':
                                                                            let aux = self.value
                                                                            if (!aux) {
                                                                                aux = []
                                                                            }
                                                                            aux.push(bgImg.src)
                                                                            self.value = JSON.parse(JSON.stringify(aux))
                                                                            // self.value.push(bgImg.src);
                                                                            break
                                                                        case 'comment':
                                                                            self.image = bgImg.src
                                                                            break
                                                                    }
                                                                }
                                                                bgImg.src = imageData
                                                            },
                                                            function (err) {
                                                                self.$snackbar.error({ closeable: true, message: self.$t('popup.media.image_empty') })
                                                            }
                                                        )
                                                    } else {
                                                        this.$snackbar.error({
                                                            duration: 5000,
                                                            closeable: true,
                                                            message: this.$t('empty_pages.connection_description')
                                                        })
                                                    }
                                                },
                                                function (message) {},
                                                cameraOptions
                                            )
                                        }
                                    } else {
                                        this.$snackbar.error({
                                            duration: 5000,
                                            closeable: true,
                                            message: this.$t('empty_pages.connection_description')
                                        })
                                    }
                                }
                            })
                        } else if (this.item.config.capture == 'gallery') {
                            this.$popup.confirm({
                                message: this.$t('cordova.plugins.camera.title'),
                                textSave: this.$t('cordova.plugins.camera.text_library'),
                                callSave: function () {
                                    cameraOptions.sourceType = 0
                                    if (fnCheckConnection() || isOfflineActive) {
                                        if (self.$store.getters.getIsAndroid) {
                                            self.captureNativeGallery(type)
                                        } else {
                                            navigator.camera.getPicture(
                                                function (imageData) {
                                                    if (fnCheckConnection()) {
                                                        imageData = 'data:image/jpeg;base64,' + imageData
                                                        var bgImg = new Image()
                                                        bgImg.onload = function () {
                                                            switch (type) {
                                                                // TODO revisar
                                                                case 'answer':
                                                                    let aux = self.value
                                                                    if (!aux) {
                                                                        aux = []
                                                                    }
                                                                    aux.push(bgImg.src)
                                                                    self.value = JSON.parse(JSON.stringify(aux))
                                                                    // self.value.push(bgImg.src);
                                                                    break
                                                                case 'comment':
                                                                    self.image = bgImg.src
                                                                    break
                                                            }
                                                        }
                                                        bgImg.src = imageData
                                                    } else {
                                                        this.$snackbar.error({
                                                            duration: 5000,
                                                            closeable: true,
                                                            message: this.$t('empty_pages.connection_description')
                                                        })
                                                    }
                                                },
                                                function (message) {},
                                                cameraOptions
                                            )
                                        }
                                    } else {
                                        this.$snackbar.error({
                                            duration: 5000,
                                            closeable: true,
                                            message: this.$t('empty_pages.connection_description')
                                        })
                                    }
                                }
                            })
                        }
                    }
                }
            } else if (!isOfflineActive) {
                e.preventDefault()
                e.stopPropagation()
                this.$snackbar.error({ duration: 5000, closeable: true, message: this.$t('empty_pages.connection_description') })
            }
        },
        captureNativeGallery(type) {
            var self = this
            var input = document.createElement('input')
            input.type = 'file'
            input.accept = 'image/*'
            input.capture = 'camera'

            input.onchange = function (ev) {
                const file = ev.target.files[0] // get the file
                const blobURL = URL.createObjectURL(file)
                const img = new Image()
                img.src = blobURL
                img.onerror = function () {
                    URL.revokeObjectURL(this.src)
                    // Handle the failure properly
                    self.$snackbar.error({ closeable: true, message: self.$t('popup.media.image_empty') })
                }
                img.onload = function () {
                    URL.revokeObjectURL(this.src)
                    const [newWidth, newHeight] = calculateImgSize(img, andyImageLimit.width, andyImageLimit.height)
                    const canvas = document.createElement('canvas')
                    canvas.width = newWidth
                    canvas.height = newHeight
                    const ctx = canvas.getContext('2d')
                    ctx.drawImage(img, 0, 0, newWidth, newHeight)
                    canvas.toBlob(
                        (blob) => {
                            // Handle the compressed image. es. upload or save in local state
                            displayFileInfo('Original file', file)
                            displayFileInfo('Compressed file', blob)
                            var reader = new FileReader()
                            reader.readAsDataURL(blob)
                            reader.onloadend = function () {
                                var base64data = reader.result
                                switch (type) {
                                    case 'answer':
                                        let aux = self.value
                                        if (!aux) {
                                            aux = []
                                        }
                                        aux.push(base64data)
                                        self.value = JSON.parse(JSON.stringify(aux))
                                        break
                                    case 'comment':
                                        self.image = base64data
                                        break
                                }
                            }
                        },
                        andyImageLimit.mime,
                        andyImageLimit.quality
                    )
                }
            }

            input.click()
        },
        captureNative(type) {
            var self = this
            this.$overlay.loading()
            this.$store.commit('setCameraActive', true)
            var imageCapture
            var track
            var currentCamera = 'back'
            var flash = false

            setTimeout(function () {
                navigator.mediaDevices
                    .getUserMedia({ video: { facingMode: { exact: 'environment' } } })
                    .then((mediaStream) => {
                        track = mediaStream.getVideoTracks()[0]
                        imageCapture = new ImageCapture(track)
                        document.querySelector('video').srcObject = mediaStream
                    })
                    .catch((error) => console.error(error))

                document.getElementById('swap-camera').addEventListener('click', function (e) {
                    log('swap camera')
                    e.preventDefault()
                    e.stopPropagation()

                    track.stop()

                    if (currentCamera == 'front') {
                        navigator.mediaDevices
                            .getUserMedia({ video: { facingMode: { exact: 'environment' } } })
                            .then((mediaStream) => {
                                track = mediaStream.getVideoTracks()[0]
                                imageCapture = new ImageCapture(track)
                                document.querySelector('video').srcObject = mediaStream
                            })
                            .catch((error) => console.error(error))
                    } else {
                        if (flash) {
                            track.applyConstraints({
                                advanced: [{ torch: !flash }]
                            })
                            flash = !flash
                            document.getElementById('flash-camera').classList.remove('bounce')
                        }

                        navigator.mediaDevices
                            .getUserMedia({ video: true })
                            .then((mediaStream) => {
                                track = mediaStream.getVideoTracks()[0]
                                imageCapture = new ImageCapture(track)
                                document.querySelector('video').srcObject = mediaStream
                            })
                            .catch((error) => console.error(error))
                    }

                    currentCamera = currentCamera == 'back' ? 'front' : 'back'
                })

                document.getElementById('flash-camera').addEventListener('click', function (e) {
                    log('flash photo')
                    e.preventDefault()
                    e.stopPropagation()

                    if (currentCamera == 'back') {
                        track.applyConstraints({
                            advanced: [{ torch: !flash }]
                        })
                        flash = !flash
                        if (flash) {
                            document.getElementById('flash-camera').classList.remove('bounce')
                        } else {
                            document.getElementById('flash-camera').classList.add('bounce')
                        }
                    }
                })

                document.getElementById('cancel-camera').addEventListener('click', function (e) {
                    log('cancel photo')
                    e.preventDefault()
                    e.stopPropagation()

                    track.stop()
                    self.$store.commit('setCameraActive', false)
                })

                document.getElementById('take-camera').addEventListener('click', function (e) {
                    log('take photo')
                    e.preventDefault()
                    e.stopPropagation()

                    imageCapture
                        .takePhoto()
                        .then((blob) => createImageBitmap(blob))
                        .then((imageBitmap) => {
                            const [newWidth, newHeight] = calculateImgSize(imageBitmap, andyImageLimit.width, andyImageLimit.height)
                            const canvas = document.createElement('canvas')
                            canvas.width = newWidth
                            canvas.height = newHeight
                            const ctx = canvas.getContext('2d')
                            ctx.drawImage(imageBitmap, 0, 0, newWidth, newHeight)
                            canvas.toBlob(
                                (blob) => {
                                    // Handle the compressed image. es. upload or save in local state
                                    // displayFileInfo('Original file', file)
                                    displayFileInfo('Compressed file', blob)
                                    var reader = new FileReader()
                                    reader.readAsDataURL(blob)
                                    reader.onloadend = function () {
                                        var base64data = reader.result
                                        log('base64 image:', base64data)
                                        switch (type) {
                                            case 'answer':
                                                let aux = self.value
                                                if (!aux) {
                                                    aux = []
                                                }
                                                aux.push(base64data)
                                                self.value = JSON.parse(JSON.stringify(aux))
                                                // self.value.push(base64data);
                                                break
                                            case 'comment':
                                                self.image = base64data
                                                break
                                        }

                                        self.$store.commit('setCameraActive', false)
                                        track.stop()
                                    }
                                },
                                andyImageLimit.mime,
                                andyImageLimit.quality
                            )

                            // drawCanvas(canvas, imageBitmap)
                        })
                        .catch((error) => console.error(error))
                })

                self.$overlay.hide()
            }, 200)
        },
        qNumeric() {
            if (!this.isDisabled) {
                var thisAns = this
                var decimal = thisAns.aConfig ? (thisAns.aConfig.decimal ? thisAns.aConfig.decimal : false) : false
                var hasNegative = thisAns.aConfig ? (thisAns.aConfig.negative ? thisAns.aConfig.negative : false) : false

                thisAns.$popup.numeric({
                    decimal: decimal,
                    hasNegative: hasNegative,
                    inputVal: thisAns.value && thisAns.value != '-' ? thisAns.value : '',
                    callSave: function (self, store) {
                        if (self.inputVal == '-') {
                            thisAns.value = '-'
                        } else if (self.inputVal != '' && typeof self.inputVal !== 'undefined') {
                            thisAns.value = self.inputVal
                        } else {
                            thisAns.resetAnswer()
                        }
                    }
                })
            }
        },
        qTemperature() {
            if (!this.isDisabled) {
                let thisAns = this
                const type = this.$store.getters.getIsApp && this.bSensorLimitation && typeof thisAns.aConfig.input !== 'undefined' && thisAns.aConfig.input ? thisAns.aConfig.input : 'manual'

                thisAns.$popup.temperature({
                    tempOptions: {
                        mesure: thisAns.aConfig.units ? thisAns.aConfig.units : 'ºC',
                        sensorType: type
                    },
                    inputVal: thisAns.value && thisAns.value != '-' ? thisAns.value.toString() : '',
                    callSave: function (self, store) {
                        if (self.inputVal == '-') {
                            thisAns.value = '-'
                        } else if (self.inputVal != '' && typeof self.inputVal !== 'undefined') {
                            thisAns.value = self.inputVal
                        } else {
                            thisAns.resetAnswer()
                        }
                    }
                })
            }
        },
        qSignature() {
            if (!this.isDisabled) {
                var thisAns = this
                if (this.item.config.require_emp) {
                    this.$popup.sign({
                        list: this.$store.getters['employee/getAllEmployees'],
                        selectedEl: this.loggedUser,
                        callSave: function (self2, store) {
                            if (self2.inputVal != '') {
                                thisAns.userAccess = self2.selectedEl
                                thisAns.value = self2.inputVal
                            } else {
                                thisAns.resetAnswer()
                            }
                        }
                    })
                } else {
                    this.$popup.freeSign({
                        callSave: function (self2, store) {
                            if (self2.inputVal != '') {
                                thisAns.value = self2.inputVal
                            } else {
                                thisAns.resetAnswer()
                            }
                        }
                    })
                }
            }
        },
        qSignaturePreview() {
            if (this.value) {
                this.$popup.previewSignature({
                    image: this.value
                })
            }
        },
        qDocument(newVal) {
            if (fnCheckConnection()) {
                if (!this.isDisabled) {
                    if (newVal) {
                        this.value = newVal
                    } else {
                        this.resetAnswer()
                    }
                }
            } else {
                this.$snackbar.error({ duration: 10000, closeable: true, message: this.$t('empty_pages.connection_description') })
            }
        },
        qSendMail() {
            if (!this.isDisabled) {
                const isOfflineActive = this.$store.getters.getOffline
                if (isOfflineActive || fnCheckConnection()) {
                    this.value = 1
                    if (fnCheckConnection()) {
                        this.$snackbar.success({ message: this.$t('answer.sended_mail'), duration: 5000, closeable: true })
                    } else {
                        this.$snackbar.warning({ duration: 10000, closeable: true, message: this.$t('answer.sended_mail_pending_sync') })
                    }
                }
            }
        },
        qLink() {
            const self = this
            if (fnCheckConnection()) {
                if (self.$store.getters.getIsApp) {
                    cordova.InAppBrowser.open(self.item.config.url, '_blank')
                } else {
                    window.open(self.item.config.url)
                }
            } else {
                this.$snackbar.error({ duration: 10000, closeable: true, message: this.$t('empty_pages.connection_description') })
            }
        },
        qResource(val) {
            if (fnCheckConnection()) {
                const isNote = val ? val : false

                this.checkAccess(undefined, isNote)

                this.$popup.resource({
                    resource: this.item.resource
                })
            } else {
                this.$snackbar.error({ duration: 10000, closeable: true, message: this.$t('empty_pages.connection_description') })
            }
        },
        resetAnswer() {
            const self = this

            if (this.isReview != false && this.item.require_pin_review) {
                this.unvalidateAnswer()
                // this.unvalidateAnswer(true);
            } else {
                if (this.autosave) {
                    this.dispatchReset()
                } else {
                    if (self.item.mandatory && self.def.has_mandatory) {
                        this.hasError = true
                    }
                    this.dispatchReset()
                }
                this.unvalidateAnswer()
            }
        },

        dispatchReset() {
            const self = this
            // TODO
            this.loading = true

            this.$store
                .dispatch(self.typename + '/reset', {
                    cType: this.cType,
                    check: this.cId,
                    answer: this.aId,
                    checkRef: this.cType === 5 ? this.checkRef : null,
                    itemRef: this.cType === 5 ? this.itemRef : null,
                    audit: this.cType === 5 ? this.list.id : null
                })
                .then((response) => {
                    if (!response.status) {
                        self.$snackbar.error({
                            closeable: true,
                            duration: 5000,
                            message: self.$t('tasks.answer_offline')
                        })
                    } else {
                        self.loading = false
                        self.hasAccess = !self.item.require_pin
                        self.userAccess = false
                        self.hasError = false

                        if (self.item.mandatory) {
                            self.$emit('checkAllMandatory', true)
                        }
                    }
                })
        },
        cantValidateAnswer() {
            var self = this

            const reviewRoles = this.item.config && typeof this.item.config.review_roles !== 'undefined' && this.item.config.review_roles ? this.item.config.review_roles : false
            const employee = this.$store.getters['loginUser/getUserInformation']

            if (reviewRoles && !reviewRoles.includes(employee.rol)) {
                self.$popup.alert({
                    closable: false,
                    blocking: false,
                    message: self.$t('popup.access.review_roles', { employee: employee.name }),
                    textSave: self.$t('popup.access.understood_andy'),
                    callSave: function (response) {
                        self.$popup.close()
                    }
                })
            } else if (this.def.item.can_review) {
                this.$popup.alert({ message: this.$t('popup.review.cant_until') })
            }
        },
        validateAnswer() {
            if (this.def.item.can_review) {
                let self = this
                const reviewRoles = this.item.config && typeof this.item.config.review_roles !== 'undefined' && this.item.config.review_roles ? this.item.config.review_roles : false
                const employee = this.$store.getters['loginUser/getUserInformation']

                if (reviewRoles && !reviewRoles.includes(employee.rol)) {
                    self.$popup.alert({
                        closable: false,
                        blocking: false,
                        message: self.$t('popup.access.review_roles', { employee: employee.name }),
                        textSave: self.$t('popup.access.understood_andy'),
                        callSave: function (response) {
                            self.$popup.close()
                        }
                    })
                } else {
                    if (self.canBeEdited && !self.isReview) {
                        if (self.item.require_pin_review) {
                            self.$popup.access({
                                list: self.$store.getters['employee/getAccessUsers'](1),
                                callAfterAccess: function (response) {
                                    if (response) {
                                        if (response.status) {
                                            self.$store
                                                .dispatch(self.typename + '/validate', {
                                                    cType: self.cType,
                                                    check: self.cId,
                                                    answer: self.aId,
                                                    emp: response.emp_id,
                                                    date: moment().unix()
                                                })
                                                .then(function () {})
                                            self.$popup.close()
                                        }
                                    }
                                }
                            })
                        } else {
                            self.$store
                                .dispatch(self.typename + '/validate', {
                                    cType: self.cType,
                                    check: self.cId,
                                    answer: self.aId,
                                    emp: self.loggedUser,
                                    date: moment().unix()
                                })
                                .then(function () {})
                        }
                    }
                }
            }
        },
        unvalidateAnswer(thenReset = false) {
            if (this.def.item.can_review) {
                var self = this
                const reviewRoles = this.item.config && typeof this.item.config.review_roles !== 'undefined' && this.item.config.review_roles ? this.item.config.review_roles : false
                const employee = this.$store.getters['loginUser/getUserInformation']
                if (reviewRoles && !reviewRoles.includes(employee.rol)) {
                    self.$popup.alert({
                        closable: false,
                        blocking: false,
                        message: self.$t('popup.access.review_roles', { employee: employee.name }),
                        textSave: self.$t('popup.access.understood_andy'),
                        callSave: function (response) {
                            self.$popup.close()
                        }
                    })
                } else {
                    if (this.canBeEdited && this.isReview) {
                        if (this.item.require_pin_review) {
                            this.$popup.access({
                                list: this.$store.getters['employee/getAccessUsers'](1),
                                callAfterAccess: function (response) {
                                    if (response) {
                                        if (response.status) {
                                            self.$store
                                                .dispatch(self.typename + '/unvalidate', {
                                                    cType: self.cType,
                                                    check: self.cId,
                                                    answer: self.aId
                                                })
                                                .then(function () {
                                                    if (thenReset) {
                                                        // self.dispatchReset();
                                                    } else {
                                                    }
                                                })
                                            self.$popup.close()
                                        }
                                    }
                                }
                            })
                        } else {
                            this.$store.dispatch(self.typename + '/unvalidate', { cType: this.cType, check: this.cId, answer: this.aId }).then(function () {
                                if (thenReset) {
                                    // self.dispatchReset();
                                } else {
                                }
                            })
                        }
                    }
                }
            }
        },
        mandatedValidation(value) {
            if (this.item.mandatory && this.def.has_mandatory && (typeof value === 'undefined' || value === '' || value == null) && !this.autosave && !this.def.item.itemsExcluded.includes(this.item.type) && this.item.is_visible) {
                return true
            } else {
                return false
            }
        },
        imagePreview() {
            const self = this

            if (!this.commenting && this.image) {
                this.$popup.media({
                    image: this.image,
                    textCancel: self.$t('menu_aux.action.cancel'),
                    textEdit: self.$t('menu_aux.action.edit'),
                    textDelete: self.$t('menu_aux.action.delete'),
                    callCancel: function (ref, store, item) {
                        self.$popup.close()
                        self.$overlay.hide()
                    },
                    callEdit: function (ref, store, item) {
                        //self.capture(null, 'comment')
                        //self.$popup.close()

                        self.$popup.close()
                        var input = document.createElement('input')
                        input.type = 'file'
                        input.accept = 'image/*'
                        input.capture = 'camera'

                        input.onchange = function (e) {
                            self.imageReload(e)
                        }
                        input.click()
                        self.$refs.changeimage.click()
                    },
                    callDelete: function (ref, store, item) {
                        self.image = ''
                        self.$popup.close()
                    }
                })
            } else {
                this.capture()
            }
        },

        imageReload(e) {
            if (this.commenting) {
                const files = e.target.files || e.dataTransfer.files
                if (!files.length) return

                const reader = new FileReader()
                reader.onerror = (error) => {
                    console.error('Error reading file:', error)
                }

                reader.onload = (e) => {
                    // this.image = e.target.result

                    const image = new Image()

                    image.onload = () => {
                        this.image = this.resizeImage(image)
                    }

                    image.src = e.target.result
                }

                reader.readAsDataURL(files[0])
            }
        },
        inputComment() {
            var self = this
            if (!this.hasValidPermissions()) {
                this.$popup.alert({
                    message: this.$t('answer.timing.not_has_role_permission', {
                        name: self.user.name,
                        roles: self.rolesWithWritePermissions()
                    })
                })
                return false
            }
            if (!this.hasValidRole()) {
                self.$popup.alert({
                    message: self.$t('answer.timing.not_has_role', {
                        name: self.user.name,
                        roles: self.getStringLimitRoles(self.item.config.roles)
                    })
                })
                return false
            }
            this.commenting = !this.commenting

            this.$nextTick(() => {
                if (this.$refs.refInputComment) {
                    this.$refs.refInputComment.focus()
                    this.$refs.refInputComment.scrollIntoView({ block: 'center', behavior: 'smooth' })
                }
            })
        },
        toggleMeta(expandValue) {
            if (this.descriptionIsExpandable) {
                this.showSeeMore = !this.showSeeMore
            }

            if (this.item.type !== FORMAT.TITLE || this.showActions()) {
                if (expandValue) {
                    this.valueExpanded = !this.valueExpanded
                } else {
                    this.expanded = !this.expanded
                }
            }
        },
        postpone() {
            // validate role
            var self = this
            if (fnCheckConnection()) {
                if (!this.hasValidRole()) {
                    self.$popup.alert({
                        message: self.$t('answer.timing.not_has_role', {
                            name: self.user.name,
                            roles: self.getStringLimitRoles(self.item.config.roles)
                        })
                    })
                    return false
                }
                if (this.canPostpone) {
                    let thisAns = this
                    let start = ''
                    let end = ''
                    let today = moment().format('YYYY-MM-DD')
                    let data = {
                        selectedDate: '',
                        showReason: false,
                        showErrorReason: false,
                        errorReason: thisAns.$t('answer.postpone.error')
                    }
                    let list = thisAns.$store.getters['getReasonPostpone']

                    if (thisAns.item.planned_from_date) {
                        start = moment(thisAns.item.planned_from_date)
                    } else {
                        start = moment(thisAns.list.business_date, 'YYYY-MM-DD')
                    }
                    end = moment(start).add(thisAns.item.margin, 'days').format('YYYY-MM-DD')
                    let start_calendar = moment(thisAns.list.business_date, 'YYYY-MM-DD').add(1, 'days').format('YYYY-MM-DD')

                    data.selectedDate = {
                        selectedDate: moment(thisAns.list.business_date, 'YYYY-MM-DD').add(1, 'days').format('YYYY-MM-DD')
                    }

                    data.available = this.item.available ? this.item.available : ''
                    thisAns.$popup.postpone({
                        title: thisAns.$t('popup.postpone.title'),
                        dateStart: start_calendar,
                        dateEnd: end,
                        textSave: this.$t('popup.default.text_save'),
                        textCancel: this.$t('popup.default.text_cancel'),
                        data: data,
                        list: list,
                        callSave: async function (self2, store) {
                            if (fnCheckConnection()) {
                                if (!self2.selected || (list[self2.selected].id == 0 && self2.message.trim() == '')) {
                                    self2.data.showErrorReason = true
                                } else {
                                    thisAns.$overlay.loading()
                                    self2.data.showErrorReason = false

                                    store
                                        .dispatch(thisAns.typename + '/postpone', {
                                            cType: thisAns.cType,
                                            check: thisAns.cId,
                                            answer: thisAns.aId,
                                            date: self2.data.selectedDate.selectedDate,
                                            reason: self2.selected,
                                            description: self2.message
                                        })
                                        .then(function (response) {
                                            thisAns.$popup.close()
                                            thisAns.$overlay.hide()
                                            thisAns.$snackbar.info({ message: thisAns.$t('tooltip.done'), closeable: true })
                                            // thisAns.$router.go();
                                        })
                                }
                            } else {
                                await self.$popup.close()

                                self.$popup.alert({
                                    closable: false,
                                    blocking: false,
                                    message: self.$t('empty_pages.connection_description'),
                                    textSave: self.$t('audit.error.alert_save'),
                                    callSave: function (response) {
                                        self.$popup.close()
                                    }
                                })
                            }
                        }
                    })
                } else {
                    this.$popup.alert({
                        title: this.$t('popup.postpone.title'),
                        message: this.item.planned_to_date ? (this.completeEmployee() ? this.$t('answer.postpone.planned_to', this.getFormattedPostponedDate(this.item.planned_to_date, true)) : this.$t('answer.postpone.planned_to_noname', this.getFormattedPostponedDate(this.item.planned_to_date, false))) : this.$t('answer.postpone.cannot'),
                        showingError: !this.canPostpone
                    })
                }
            } else {
                this.$popup.alert({
                    closable: false,
                    blocking: false,
                    message: self.$t('empty_pages.connection_description'),
                    textSave: self.$t('audit.error.alert_save'),
                    callSave: function (response) {
                        self.$popup.close()
                    }
                })
            }
        },
        openFile() {
            this.$popup.library({ file: this.item.resource })
        },
        viewAlert() {
            const self = this

            if (self.isAuditItemVinculatedToAnActionPlan) {
                return
            }

            if (this.item.alerts || this.item.alerts_triggers) {
                let alerts = this.item.alerts
                let issues = self.hasIssue && self.item.reference
                let issue_id = false

                if (alerts.issues && alerts.issues.length > 0) {
                    log(this.list.type)
                    self.$popup.issueAlert({
                        mood: alerts.class,
                        message: alerts.message,
                        list: { ...self.$store.getters['issues/getTemplates']() },
                        selectedEl: alerts.issues[0],
                        showingError: true,
                        selected: issues,
                        showSaveStart: parseInt(this.list.type) != 3,
                        textSave: issues ? self.$t('popup.issue_alert.text_redirect') : self.$t('popup.issue_alert.text_save'),
                        callSave: async function (pSelf, pStore) {
                            if (!fnCheckConnection()) {
                                log('>> CREAR UNA ISSUES', pSelf, pStore)
                                self.$overlay.loading()
                                if (issues) {
                                    log(issues)
                                    self.goToReference()
                                    self.$overlay.hide()
                                    return Promise.resolve(pSelf, pStore)
                                } else {
                                    log(pSelf)
                                    if (typeof pSelf.selectedEl !== 'undefined') {
                                        log('entra if', pSelf.selectedEl)

                                        return pStore
                                            .dispatch(self.typename + '/addIssue', {
                                                tpl_id: pSelf.selectedEl,
                                                cType: 4,
                                                item_ref: self.item.item_id,
                                                checklist_ref: self.list.id,
                                                isOfflineCreated: true,
                                                toolQueue: self.typename
                                            })
                                            .then(function (response) {
                                                if (response) {
                                                    // TODO REVIEW por que esta esto aqui?
                                                    log('>> RESPONSE CREATED ISSUE', response)
                                                    // pStore.dispatch(self.typename + '/loadById', { id: self.list.id })

                                                    issue_id = Object.values(response)[0].id
                                                    self.$overlay.hide()
                                                    return Promise.resolve(pSelf, pStore)
                                                }
                                            })
                                    } else {
                                        self.$overlay.hide()
                                        return Promise.resolve(pSelf, pStore)
                                    }
                                }
                            } else {
                                self.$overlay.loading()
                                if (issues) {
                                    log(issues)
                                    self.goToReference()
                                    self.$overlay.hide()
                                    return Promise.resolve(pSelf, pStore)
                                } else {
                                    log(pSelf)
                                    if (typeof pSelf.selectedEl !== 'undefined') {
                                        log('entra if', pSelf.selectedEl)
                                        return pStore
                                            .dispatch(self.typename + '/addIssue', {
                                                tpl_id: pSelf.selectedEl,
                                                cType: 4,
                                                checklist_ref: self.list.id,
                                                item_ref: self.item.item_id
                                            })
                                            .then(function (response) {
                                                if (response) {
                                                    // TODO REVIEW por que esta esto aqui?
                                                    log('>> RESPONSE CREATED ISSUE', response)
                                                    // pStore.dispatch(self.typename + '/loadById', { id: self.list.id })

                                                    issue_id = Object.keys(response)[0]
                                                    self.$overlay.hide()
                                                    return Promise.resolve(pSelf, pStore)
                                                }
                                            })
                                    } else {
                                        self.$overlay.hide()
                                        return Promise.resolve(pSelf, pStore)
                                    }
                                }
                            }
                        },
                        callSaveStart: async function (pSelf, pStore) {
                            log(issue_id)
                            await self.$popup.close()
                            self.$overlay.loading()
                            self.$router.push({ name: 'IssuesDetail', params: { id: issue_id } })
                            return Promise.resolve(pSelf, pStore)
                        },
                        callCancel: function (pSelf, pStore) {
                            issues ? true : self.resetAnswer()
                            self.$popup.close()
                            self.$overlay.hide()
                            return Promise.resolve(pSelf, pStore)
                        }
                    })
                } else if (alerts && alerts.message != '' && this.item.alerts_triggers[0].type != 'email') {
                    this.$popup.alert({
                        mood: alerts.class,
                        message: alerts.message
                    })
                }
            }
        },
        goToReference() {
            if (this.item.reference) {
                this.$router.push({
                    name: 'IssuesDetail',
                    params: { id: this.item.reference.checklist, checkType: this.item.reference.type }
                })
            }
        },
        /**
         * @param {Number} apply (0 => no apply, 1 => apply)
         */
        apply(apply) {
            const self = this
            const employee = this.loggedUser

            // control roles
            if (!this.hasValidRole()) {
                self.$popup.alert({
                    message: self.$t('answer.timing.not_has_role', {
                        name: self.user.name,
                        roles: self.getStringLimitRoles(self.item.config.roles)
                    })
                })
                return false
            }

            if (this.roles[this.user.rol].level >= 2) {
                if (apply == 0) {
                    self.$snackbar.hide()
                    this.handleNotApply(0) // 0 = no apply
                } else {
                    const isOfflineActive = !fnCheckConnection() ? { customClass: 'offline' } : {}
                    self.$popup.confirm({
                        ...isOfflineActive,
                        message:
                            this.item.type == FORMAT.TITLE
                                ? self.$t('answer.apply.remember_apply_section_text_', {
                                      username: self.user.name,
                                      task: self.item.title
                                  })
                                : self.$t('answer.apply.remember_apply_text', {
                                      username: self.user.name,
                                      task: self.item.title
                                  }),
                        textSave: self.$t('popup.confirm.not_apply_text_save'),
                        textCancel: self.$t('popup.confirm.not_apply_text_cancel'),
                        textCancel2: self.$t('popup.confirm.text_cancel'),
                        callSave: function () {
                            self.$overlay.loading()
                            self.$store
                                .dispatch(self.typename + '/applyrecurrent', {
                                    cType: self.cType,
                                    check: self.cId,
                                    answer: self.aId,
                                    emp: employee,
                                    date: moment().unix(),
                                    apply: apply,
                                    checkRef: self.cType === 5 ? self.checkRef : null,
                                    itemRef: self.cType === 5 ? self.itemRef : null
                                })
                                .then(function (response) {
                                    self.$popup.close()
                                    self.$overlay.hide()
                                    self.showApplyMessage = false
                                    self.toggleMeta(false)
                                })
                                .catch((error) => {
                                    logError(error)
                                    self.$overlay.hide()
                                })
                        },
                        // BUTTON "Only for now"
                        callCancel: function (ref, store, item) {
                            if (item == 'button') {
                                self.$overlay.loading()

                                self.$store
                                    .dispatch(self.typename + '/apply', {
                                        cType: self.cType,
                                        check: self.cId,
                                        answer: self.aId,
                                        emp: employee,
                                        date: moment().unix(),
                                        apply: apply,
                                        checkRef: self.cType == 5 ? self.checkRef : null,
                                        itemRef: self.cType == 5 ? self.itemRef : null
                                    })
                                    .then(function (response) {
                                        self.$popup.close()
                                        self.$overlay.hide()
                                        self.showApplyMessage = false
                                        self.$snackbar.main({
                                            message: self.item.type == FORMAT.TITLE ? self.$t('answer.apply.revert_section') : self.$t('answer.apply.revert'),
                                            duration: 5000,
                                            closeable: true,
                                            type: 'info'
                                        })
                                        self.toggleMeta(false)
                                    })
                                    .catch((error) => {
                                        logError(error)
                                        self.$popup.close()
                                        self.$overlay.hide()
                                        self.showApplyMessage = false
                                        self.$snackbar.main({
                                            message: self.item.type == FORMAT.TITLE ? self.$t('answer.apply.revert_section') : self.$t('answer.apply.revert'),
                                            duration: 5000,
                                            closeable: true,
                                            type: 'info'
                                        })
                                        self.toggleMeta(false)
                                    })
                                // clicked outside
                            } else {
                                self.$popup.close()
                            }
                        },
                        callCancel2: function (ref, store, item) {
                            self.$popup.close()
                            self.$overlay.hide()
                            self.toggleMeta(false)
                        }
                    })
                }
            } else {
                self.$overlay.loading()

                self.$store
                    .dispatch(self.typename + '/apply', {
                        cType: self.cType,
                        check: self.cId,
                        answer: self.aId,
                        emp: employee,
                        date: moment().unix(),
                        apply: apply,
                        checkRef: this.cType == 5 ? this.checkRef : null,
                        itemRef: this.cType == 5 ? this.itemRef : null
                    })
                    .then(function (response) {
                        self.$overlay.hide()
                        if (apply == 0) {
                            self.showApplyMessage = true
                        } else {
                            self.showApplyMessage = false
                        }
                        self.toggleMeta(false)
                    })
                    .catch((error) => {
                        console.error(error)
                        logError(error)
                        self.$overlay.hide()
                    })
            }
        },
        handleNotApply(apply) {
            // handleNotApply function displays self.$popup.confirm() first and,
            // after user click on "Yes, remember it", displays self.$popup.reconfirm()

            const self = this
            const employee = this.loggedUser

            // function that does the remember not apply (will be called for self.$popup.reconfirm())
            const rememberNotApply = () => {
                if (fnCheckConnection()) {
                    self.$overlay.loading()
                    self.$store
                        .dispatch(self.typename + '/applyrecurrent', {
                            cType: self.cType,
                            check: self.cId,
                            answer: self.aId,
                            emp: employee,
                            date: moment().unix(),
                            apply: apply,
                            checkRef: self.cType === 5 ? self.checkRef : null,
                            itemRef: self.cType === 5 ? self.itemRef : null
                        })
                        .then(function (response) {
                            self.$popup.close()
                            self.$overlay.hide()
                            self.showApplyMessage = true
                            self.toggleMeta(false)
                        })
                        .catch((error) => {
                            logError(error)
                            self.$overlay.hide()
                        })
                } else {
                    self.$popup.close()
                    self.$overlay.hide()
                    self.$snackbar.error({
                        duration: 10000,
                        closeable: true,
                        message: self.$t('empty_pages.connection_description')
                    })
                }
            }

            const reconfirmPopupData = {
                message: self.$t('answer.apply.confirm_not_apply'),
                callSave: rememberNotApply,
                // cancel button has to close "reconfirm" modal and open again "confirm" modal:
                callCancel: async function () {
                    // close this "reconfirm" modal and open again "confirm" modal:
                    await self.$popup.close()
                    self.$popup.confirm(confirmPopupData)
                }
            }

            // callsave to self.$popup.confirm({})
            const callReconfirmPopup = async () => {
                // close "confirm" modal in order to open "reconfirm" modal:
                await self.$popup.close()
                if (fnCheckConnection()) {
                    self.$popup.reconfirm(reconfirmPopupData)
                } else {
                    self.$popup.alert({
                        closable: false,
                        blocking: false,
                        message: self.$t('empty_pages.connection_description'),
                        textSave: self.$t('audit.error.alert_save'),
                        callSave: function (response) {
                            self.$popup.close()
                        }
                    })
                }
            }

            // function that applies "not apply" only for now:
            // callCancel for self.$popup.confirm({})
            const applyNotApplyOnlyForNow = (ref, store, item) => {
                if (fnCheckConnection() || this.$store.getters.getOffline) {
                    if (item == 'button' || typeof item === 'undefined') {
                        self.$overlay.loading()

                        self.$store
                            .dispatch(self.typename + '/apply', {
                                cType: self.cType,
                                check: self.cId,
                                answer: self.aId,
                                emp: employee,
                                date: moment().unix(),
                                apply: apply,
                                checkRef: self.cType == 5 ? self.checkRef : null,
                                itemRef: self.cType == 5 ? self.itemRef : null
                            })
                            .then(function (response) {
                                self.$popup.close()
                                self.$overlay.hide()
                                self.showApplyMessage = true
                                self.toggleMeta(false)
                            })
                            .catch((error) => {
                                logError(error)
                                self.$overlay.hide()
                            })
                        // clicked outside
                    } else {
                        self.$popup.close()
                    }
                } else {
                    self.$popup.close()
                    self.$overlay.hide()
                    self.$snackbar.error({
                        duration: 10000,
                        closeable: true,
                        message: self.$t('empty_pages.connection_description')
                    })
                }
            }

            // function that cancel "not apply"
            // callCancel2 for self.$popup.confirm({})
            const cancelNotApply = (ref, store, item) => {
                self.$popup.close()
                self.$overlay.hide()
                self.toggleMeta(false)
            }

            // message value for self.$popup.confirm({})
            const notApplyConfirmPopupMsg =
                this.item.type == FORMAT.TITLE
                    ? self.$t('answer.apply.remember_no_apply_section_text', {
                          username: self.user.name,
                          task: self.item.title
                      })
                    : self.$t('answer.apply.remember_no_apply_text', {
                          username: self.user.name,
                          task: self.item.title
                      })

            let confirmPopupData = {
                message: notApplyConfirmPopupMsg,
                textSave: self.$t('popup.confirm.not_apply_text_save'),
                textCancel: self.$t('popup.confirm.not_apply_text_cancel'),
                textCancel2: self.$t('popup.confirm.text_cancel'),
                classSave: 'act save',
                callSave: callReconfirmPopup,
                callCancel: applyNotApplyOnlyForNow,
                callCancel2: cancelNotApply
            }

            if (!fnCheckConnection()) {
                confirmPopupData = {
                    ...{
                        message: notApplyConfirmPopupMsg,
                        textSave: self.$t('popup.confirm.not_apply_text_cancel'),
                        callSave: applyNotApplyOnlyForNow
                    }
                }
            }

            self.$popup.confirm(confirmPopupData)
        },
        isBase64(string) {
            log('isBase64', string)
            const regex = /^data:image\/(jpeg|png|jpg);base64,/i
            return regex.test(string)
        },
        getOfflineImg() {
            return css_image_url + 'offline/no_icon_light.svg'
        },

        checkIfDescriptionWasShortened() {
            const description = this.$refs.description

            if (description) {
                const descriptionWasShortened = description.scrollHeight > description.clientHeight

                // this.showSeeMore = descriptionWasShortened

                return descriptionWasShortened
            }
        }
    },
    watch: {},
    created() {
        this.isOnline = this.$store.getters.getConnState

        if (this.$store.getters.getIsApp) {
            setTimeout(function () {
                document.getElementsByTagName('a').forEach((e) => {
                    var link = e.innerHTML
                    if (link) {
                        e.onclick = function () {
                            cordova.InAppBrowser.open(link, '_blank')
                            return false
                        }
                        e.href = ''
                    }
                }),
                    500
            })
        }
    },
    mounted() {
        this.isOnline = this.$store.getters.getConnState
        setTimeout(() => {
            this.showSeeMore = this.checkIfDescriptionWasShortened()
            this.descriptionIsExpandable = this.checkIfDescriptionWasShortened()
        }, 200)
    }
}
</script>

<style lang="scss" scoped>
$ans-border: #ccc;
$ans-bg: #fff;
$ans-value-bg: #f3f3f3;
// $ans-value-bg-hover: #b3b3b3;
$ans-text-color: #222;
$ans-desc-color: #666;
$meta-bg: #fbfbfb;

$answer-size: rem(40px);

.questionCopy {
    &:first-child {
        margin-top: 15px;
    }
    width: 100%;
    //margin: 16px auto;
    padding: 0 6px 0 12px;
    position: relative;

    .action-plan-section {
        width: 100%;
        padding-top: 24px;
        // padding-bottom: 24px;

        .title-and-button {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 4px;
        }

        .action-plan-title {
            font-size: 18px;
        }

        .show-hide {
            display: flex;
            gap: 4px;
            align-items: center;
            background-color: none;

            .label {
                color: #374673;
                font-size: 18px;
                font-weight: bold;
            }
        }

        .show-hide:hover {
            background-color: transparent;
        }

        .action-plan-items-container {
            padding-left: 16px;
        }
    }

    .unknown {
        @include border-radius(3px);
        @include font-size(sm);
        font-family: $text;
        color: $error;
        font-style: italic;
        text-align: center;
        padding: 10px 0;
        width: rem(100px);
        margin-left: rem(10px);
        display: inline-block;
        width: 100%;
        max-width: 100%;
    }

    .answer,
    .answerCopy > .line {
        width: 100%;
        min-height: $answer-size;

        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .statement {
            @include font-size(m);
            width: 100%;
            color: $ans-text-color;
            font-family: $text-light;
            max-width: 100%;

            p {
                padding: 0px !important;
            }

            .answer-title {
                @include font-size(m);
                width: 100%;
                color: $ans-text-color;
                font-family: $text-light;
                padding-top: 8px;
                font-weight: bold;

                b {
                    @include font-size(inherit);
                    color: inherit;
                }
                ul {
                    list-style: disc;
                    padding: 5px 0;
                    padding-left: 30px;
                    color: inherit;
                }
                li {
                    @include font-size(inherit);
                    color: inherit;
                }

                &.mandatory {
                    // color: $error-dark;
                    // text-decoration: underline;
                    font-family: $text;

                    &:after {
                        content: ' *';
                        // color: $error;
                    }
                }
                &.optional {
                    font-family: $text-light;

                    &:after {
                        @include font-size(s);
                        color: #888;
                        content: attr(data-after);
                    }
                }
            }

            .text-container {
                max-height: 2.4em; /* Dos líneas con una altura de línea de 1.2em */
                overflow: hidden;
                position: relative;
            }

            .text-container .expanded {
                max-height: none;
            }

            .text-container::after {
                content: '\2026';
                position: absolute;
                bottom: 0;
                right: 0;
                background: white;
            }

            .description {
                @include font-size(s);
                display: block;
                color: $ans-desc-color;
            }

            .description-short {
                p {
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: normal;
                }
            }

            .see-more {
                font-weight: 800;
                position: relative;
                display: flex;
                padding-top: 4px;
            }

            .see-more:hover {
                background-color: transparent;
            }

            .see-less {
                font-weight: 800;
                position: relative;
                display: flex;
                padding-top: 4px;
            }

            .see-less:hover {
                background-color: transparent;
            }
        }
        .stat-description:after {
            @include font-size(s);
            content: attr(data-desc);
            display: block;
            color: $ans-desc-color;
        }

        .responseCopy {
            text-align: right;
            display: flex;
            align-items: center;
            gap: 4px;
            flex-direction: row;
            justify-content: flex-end;

            @media only screen and (max-width: 650px) {
                flex-wrap: wrap;
                // justify-content: flex-end;
                gap: 6px;
                flex-direction: column;
                align-items: flex-end;
            }

            > div {
                // width: 100%;
                @include display-inline-flex();
                @include flex-wrap(wrap);
                @include align-items(center);
                @include justify-content(flex-end);
                @include flex-direction(row);
            }

            .yes-no {
                display: flex;
                flex-direction: row;
                gap: 4px;
                flex-wrap: nowrap;
            }

            .responseNoApply {
                @include display-inline-flex();
                @include flex-wrap(wrap);
                @include align-items(flex-end);
                @include justify-content(flex-end);
                height: 100%;
                width: 150px;
                cursor: pointer;

                .text {
                    @include display-inline-flex();
                    @include flex-wrap(wrap);
                    @include align-items(center);
                    @include justify-content(center);
                    @include background($color: $main-t80, $image: img('offline/noApply.svg'), $size: 50px, $position: center 5px);
                    @include font-size(m);
                    @include border-radius(4px);
                    font-family: $conden-bold;
                    color: $main-s40;
                    padding: 9px 15px 30px 30px;
                    height: auto;
                    width: max-content;
                }
            }

            .valueCopy {
                @include interaction();

                @include font-size(s);
                @include border-radius(3px);
                // line-height: 1;
                font-family: $text;
                text-transform: none;
                text-align: center;
                width: $answer-size;
                height: $answer-size;
                display: inline-block;
                border: none;

                // @include bgHover($ans-value-bg);

                &.disabled {
                    pointer-events: none;
                }
            }

            .postposed {
                @include border-radius(3px);
                @include background($size: 16px, $position: left 3px center, $color: $main);
                @include font-size(s);
                line-height: $answer-size;
                font-family: $text-bold;
                color: #fff;
                text-align: center;
                padding: 0;
                width: 80px;
                height: $answer-size;
                display: inline-block;
                float: right;
            }
        }
    }

    .answerCopy {
        width: calc(100% - 24px);
        padding: 6px;

        background-color: $ans-bg;
    }

    .answer-state {
        @include border-radius(5px);
        height: 5px;
        width: 100%;
        margin: 0;
        background-color: $accent-dark;
    }

    // ITEM REVIEW (DOUBLE CHECK)

    .toReview {
        gap: 6px;

        .line {
            // width: calc(100% - 55px);
            padding-right: 0;
        }

        .checkaccess {
            width: 171px;
        }

        .review-check {
            height: 2.5rem !important;
            width: 2.5rem !important;
            border-radius: 100%;
            background-size: initial;
            background-clip: padding-box;
        }

        .review-check-disabled {
            @extend .review-check;
            background: img('icons/double_check_grey.svg');
            background-repeat: no-repeat;
            background-color: #eeeeee;
            background-position: center;
        }

        .review-check-pending {
            @extend .review-check;
            background: img('icons/double_check_white.svg');
            background-repeat: no-repeat;
            background-color: #eeeeee;
            background-position: center;
            padding: 20px;
        }

        .review-check-done {
            @extend .review-check;
            background: img('icons/double_check_white.svg');
            background-repeat: no-repeat;
            background-color: #348567;
            background-position: center;

            &.portrait {
                height: 2.5rem;
                padding: 20px;
            }
        }
    }

    .meta {
        min-height: 60px;
        height: auto;
        width: 100%;
        padding: 10px 0;
        background-color: $meta-bg;

        .hidden {
            display: none !important;
        }

        div.task-label {
            display: grid;
            place-content: center;
            margin-left: 12px;
            width: fit-content;
            height: 17px;
            flex-shrink: 0;
            border-radius: 2px;
            padding: 4px;
            &.daily {
                background-color: $color-daily;
            }

            &.weekly {
                background-color: $color-weekly;
            }

            &.biweekly {
                background-color: $color-biweekly;
            }

            &.monthly {
                background-color: $color-monthly;
            }

            &.quarterly {
                background-color: $color-quarterly;
            }

            &.biannually {
                background-color: $color-biannually;
            }

            &.annually {
                background-color: $color-annually;
            }

            span {
                display: flex;
                height: 15px;
                flex-direction: column;
                justify-content: center;
                flex-shrink: 0;
                text-align: justify;
                font-size: 12px;
                font-style: normal;
                font-weight: 600;
                line-height: 16px;
            }
        }

        .actions,
        .info {
            font-family: $text !important;
            // float: left;
            display: inline-block;
            min-height: $answer-size;
            height: auto;

            .sign {
                font-family: $text !important;

                .name {
                    font-family: $text !important;

                    .bold {
                        font-family: $text !important;
                    }
                }
            }

            .datetime {
                font-family: $text !important;
                font-size: 14px !important;
            }
        }
        .actions {
            width: 235px;
            float: right;
            padding: 0 10px;
        }
        .info {
            width: calc(100% - 235px);

            .offline-message-box,
            .sign,
            .review,
            .unreview,
            .period,
            .role,
            .timing {
                display: block;
                width: 100%;
                padding-left: 12px;
                padding-bottom: 6px;
                min-height: 20px;
                height: auto;

                .bold,
                .datetime,
                .name,
                .date-info,
                .reason {
                    @include font-size(s);
                    color: $ans-desc-color;
                    margin-right: 3px;
                }
                .reason {
                }
                .date-info {
                    color: $main;
                    font-family: $text;
                }
                .bold {
                    font-family: $text;
                }
                .datetime,
                span.offline-message {
                    @include font-size(15);
                    font-family: Avenir-Regular;
                    color: $ans-desc-color;
                }
                .bold,
                .datetime {
                    .avatar {
                        @include border-radius(100%);
                        width: 15px;
                        height: 15px;
                    }
                }
                .ico {
                    @include border-radius(50%);
                    background-size: 20px;
                    width: 20px;
                    height: 20px;
                    position: relative;
                    bottom: -4px;
                    background-color: $main;
                    margin-right: 5px;
                }
                .task-icon-done {
                    margin-top: -2px;
                    margin-right: 4px;
                    background-image: img('checked_ok.svg');
                    background-size: contain;
                    background-repeat: no-repeat;
                    width: 16px;
                    height: 16px;
                    display: inline-block;
                    vertical-align: middle;
                }
                .task-icon-notdone {
                    margin-top: -2px;
                    margin-right: 4px;
                    background-image: img('check-not-done.svg');
                    background-size: contain;
                    background-repeat: no-repeat;
                    width: 16px;
                    height: 16px;
                    display: inline-block;
                    vertical-align: middle;
                }
                .task-icon-reviewed {
                    margin-top: -2px;
                    margin-right: 4px;
                    background-image: img('icons/double_check_white.svg');
                    background-color: #348567;
                    border-radius: 100%;
                    background-position: center;
                    background-size: 10px;
                    background-repeat: no-repeat;
                    width: 16px;
                    height: 16px;
                    display: inline-block;
                    vertical-align: middle;
                }
                .task-icon-toreview {
                    margin-top: -2px;
                    margin-right: 4px;
                    background-image: img('icons/double_check_white.svg');
                    background-size: 10px;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-color: #b1b1b1;
                    border-radius: 100%;
                    width: 16px;
                    height: 16px;
                    display: inline-block;
                    vertical-align: middle;
                }
                .task-icon-date {
                    margin-top: -2px;
                    margin-right: 4px;
                    background-image: img('input_datetime_neutro_s50.svg');
                    background-size: contain;
                    background-repeat: no-repeat;
                    width: 16px;
                    height: 16px;
                    display: inline-block;
                    vertical-align: middle;
                }

                .task-icon-postpone {
                    margin-top: -2px;
                    margin-right: 4px;
                    background-image: img('postpone.svg');
                    background-size: contain;
                    background-repeat: no-repeat;
                    width: 16px;
                    height: 16px;
                    display: inline-block;
                    vertical-align: middle;
                }
                .task-icon-postpone_done {
                    margin-top: -2px;
                    margin-right: 4px;
                    background-image: img('postpone_done.svg');
                    background-size: contain;
                    background-repeat: no-repeat;
                    width: 16px;
                    height: 16px;
                    display: inline-block;
                    vertical-align: middle;
                }
                .task-icon-reason {
                    margin-top: -2px;
                    margin-right: 4px;
                    background-image: img('message.svg');
                    background-size: contain;
                    background-repeat: no-repeat;
                    width: 16px;
                    height: 16px;
                    display: inline-block;
                    vertical-align: middle;
                }
                .task-icon-sync {
                    margin-top: -2px;
                    margin-right: 4px;
                    background-image: img('offline/sync_icon.svg');
                    background-size: contain;
                    background-repeat: no-repeat;
                    width: 16px;
                    height: 16px;
                    display: inline-block;
                    vertical-align: middle;
                }
            }
        }
        .roles {
            @include border-radius(3px);
            background-color: $neutro-t10;
            // color: #fff;
            font-family: $text-light;
            padding: 2px 7px;
            width: fit-content;
            margin-right: 3px;
        }
    }

    .item-alert {
        width: 100%;
        padding: 5px 0;
        margin-top: 6px;

        .alex {
            @include background($image: img('alexhead_08.svg'), $size: contain, $position: left bottom);
            width: rem(40px);
            height: rem(40px);
            display: inline-block;
            margin-right: 6px;
        }

        .message {
            @include border-radius(3px);
            @include font-size(s);
            line-height: 25px;
            font-family: $text;
            color: $main-s40;
            padding: 0 6px;
            display: inline-block;
            background-color: $main-t70;
            overflow: hidden;
            width: auto;
            max-width: calc(100% - #{rem(40px)} - #{rem(6px)});
            height: rem(40px);

            b {
                @include font-size(s);
                color: $main-text;
            }

            ul {
                list-style: disc;
                padding-left: 30px;
            }

            li {
                color: $main-text;
                @include font-size(s);
            }

            .preview,
            .read-more {
                display: inline-block;
                line-height: rem(40px);
                font-family: $text;
                float: left;
            }

            .preview {
                @include text-ellipsis();
                width: calc(100% - 80px - 12px);
            }
            .read-more {
                @include interaction();
                font-family: $text;
                color: $main-text;
                margin-left: 12px;
                width: 80px;
                text-align: center;
            }
            &.email {
                width: 100%;
                max-width: fit-content;
                .preview {
                    width: auto;
                }
            }
        }

        &.has-issue {
            .alex {
                background-image: img('alexhead_03.svg');
            }
            .message {
                background-color: tint($error, 70%);
                color: $error-dark;

                .read-more {
                    color: $error-dark;
                }
            }
        }

        &.info {
            // same as parent
        }
        &.warning {
            .alex {
                background-image: img('alexhead_04.svg');
            }

            .message {
                background-color: tint($warning, 70%);
                color: $warning-dark;

                .read-more {
                    color: $warning-dark;
                }
            }
        }
        &.error {
            .alex {
                background-image: img('alexhead_03.svg');
            }

            .message {
                background-color: tint($error, 70%);
                color: $error-dark;

                .read-more {
                    color: $error-dark;
                }
            }
        }
    }

    & > .reference-link {
        width: 100%;
        margin-left: 9px;
        // padding: 0 0 5px 66px;

        .message {
            @include background($image: img('alert_alert.svg'), $size: 18px, $position: left center, $color: transparent);
            @include border-radius(3px);
            @include font-size(s);
            line-height: 25px;
            font-family: $text;
            color: $main-s40;
            // padding: 9px 12px 6px 25px !important;
            overflow: hidden;
            // display: inline-block;
            // width: auto;
            max-width: calc(100% - 6px);
            height: auto;
            width: 100%;
            display: flex;

            .preview {
                margin-left: 30px;
            }

            .read-more {
                // font-family: $text;
                color: $error-dark;
                margin-left: 9px;
                text-align: center;
            }
        }
    }

    .state {
        .planned {
            width: calc(100% - 100px);
            display: inline-block;
            padding: 0 12px;

            .name,
            .date,
            .hour {
                @include font-size(14);
                font-family: $text;
                color: #666;
                text-transform: uppercase;
                text-align: center;
                margin-left: 3px;
            }
            .name {
                @include font-size(xs);
                font-family: $text;
            }
            // .date,
            // .hour {
            //     // color: $main-dark;
            // }
        }

        .reviewed {
            @include background($color: transparent);
            @include font-size(s);
            display: inline-block;
            float: right;
            padding: 0;
            padding-right: 6px;
            margin: 0;
            color: $ans-desc-color;
            font-style: italic;
            font-family: $text-light;
            text-align: right;
            width: 100px;
        }
    }

    .btn.action {
        color: $ans-text-color;

        &.review {
            margin: 0;
            margin-left: 10px;
            padding: 0;
            height: $answer-size;
            width: 90px;
            text-transform: none;
            box-shadow: none;
            background-color: $accent-t70;
            @include display-flex();
            @include justify-content();
            @include align-items(center);

            .text {
                @include font-size(s);
                color: $accent-s60;
            }

            .text {
                font-family: $text-bold;
            }

            &.disabled {
                @include opacity(0.5);
                @include filter(grayscale(100%));
            }
        }

        &.unreview {
            @include background($color: transparent);
            margin: 0;
            margin-left: 10px;
            padding: 0;
            height: $answer-size;
            width: 90px;
            text-transform: none;
            box-shadow: none;
            @include display-flex();
            @include justify-content();
            @include align-items(center);

            .text {
                @include font-size(s);
                color: #888;
                text-align: right;
            }

            .text {
                font-style: italic;
                font-family: $text;
            }
        }

        &.toggleMeta {
            @include background($color: transparent, $size: 18px);
            background-image: img('more_vert_aaaaaa.svg');
            width: 24px;
            display: inline-block;
            padding: 0;

            &.open {
                background-image: img('more_vert_primary.svg');
            }
        }

        &.hasFile,
        &.share,
        &.comment,
        &.postpone,
        &.noApply,
        &.apply {
            @include background($color: transparent, $size: 20px, $position: top 3px center);
            @include font-size(s);
            text-align: center;
            font-family: $conden;
            color: $ans-text-color;
            width: fit-content;
            max-width: rem(100px);
            min-width: rem(40px);
            height: rem(40px);
            float: right;
            padding: 25px 10px 10px;
            margin-left: 5px;

            @include bgHover($meta-bg);
        }
        &.postpone {
            background-image: img('offline/postpone_aaaaaa.svg');
            &.cannot {
                @include opacity(0.25);
            }
        }
        &.hasFile {
            background-image: img('offline/attach_aaaaaa.svg');
        }
        &.noApply {
            background-image: img('offline/noApply.svg');
            background-size: 40px;
        }
        &.apply {
            background-image: img('offline/arrows-retweet.svg');
        }
        &.share {
            background-image: img('share_aaaaaa.svg');
            background-size: 18px;
        }
        &.comment {
            background-image: img('offline/notes_create_aaaaaa.svg');
        }
    }

    .itemCopy {
        @include display-inline-flex();
        @include flex-wrap(wrap);
        @include align-items();
        @include justify-content(flex-end);
        @include border-radius(3px $default-border-radius $default-border-radius 3px);
        @include height-fit-content();
        background-color: $ans-bg;
        width: 100%;
        padding: 0;
        overflow: hidden;
        margin: 0;
        transition-duration: 0.2s;
        border-left: 5px solid #ccc;
        height: auto;
        min-height: $answer-size + rem(10px);
        // border-left: 5px solid $accent;

        &.excluded {
            border-color: #ccc;
        }
        &.done {
            border-color: $done;
        }
        &.showErrors {
            border-color: #bd1f1e;
        }
        &.error {
            border-color: $error;

            .roles {
                background-color: $error !important;
                color: #fff;
            }
        }
        &.alert {
            border-color: $warning;
        }
        &.issue {
            border-color: $error;
        }
        &.sync {
            border-color: $sync-main !important;
        }
    }

    .disabled {
        background-color: $color-neutral-200;
        border: 1px solid $color-neutral-500;
        border-color: $color-neutral-300 !important;
        border-left: 5px solid $color-neutral-500;

        .answerCopy {
            background-color: $color-neutral-200;

            .answer-title,
            .description {
                color: $color-neutral-600 !important;
            }

            .responseCopy {
                .valueCopy {
                    cursor: not-allowed !important;
                }
            }
        }
    }

    .has-comment {
        @include flex-wrap(wrap);
        display: flex;
        justify-content: space-between;
        width: 100%;
        max-height: 80px;
        //height: 60px;
        position: relative;
        .editIcon {
            @include background($image: img('offline/notes_create_aaaaaa.svg'), $size: 24px);
            background-color: #eae8e4;
            width: 24px;
            height: 24px;
            padding: 30px 10px 30px 30px;
            border-radius: 5px 0 0 5px;
        }
        &.editing {
            height: 80px;
        }

        &.hide {
            display: none;
        }

        .has-note {
            $image-size: rem(60px);
            width: calc(100% - 70px);
            min-height: $image-size;
            height: 100%;
            max-height: inherit;
            margin: 0px;
            padding: 12px 0px 0px 0px;
            display: flex;
            justify-content: flex-start;

            .note,
            .note:focus {
                display: inline-block;
                width: 100%;
                height: $image-size;
                margin: 0;
                background-color: $ans-bg;
                color: #666666;
                -webkit-text-fill-color: #666;
            }
            .note[disabled],
            .note:focus[disabled] {
                background-color: #eae8e4;
                padding-left: 0px;
                border-radius: 0px 5px 5px 0px;
            }

            &.hide {
                display: none;
            }
        }
        &.editing .has-note {
            .note,
            .note:focus {
                height: 60px;
                background-color: $ans-bg;
            }
        }

        .has-image {
            @include display-inline-flex();
            @include flex-wrap(wrap);
            @include flex-direction(column);
            width: 60px;
            height: 71px;
            text-align: center;
            margin-left: 10px;

            .preview {
                @include border-radius(3px);
                width: 60px;
                height: 60px;
                overflow: hidden;
                // border: 1px solid #eee;
                display: block;
                margin-top: 13px;

                .image {
                    // width: auto;
                    // max-width: unset;
                    // height: rem(40px);
                    height: 100% !important;
                    width: 100% !important;
                    object-fit: cover !important;
                }
            }

            .add-file,
            .change-image,
            .btn.delete,
            .btn.view {
                @include border-radius(3px);
                visibility: hidden;
                background-color: transparent;
                width: rem(40px);
                height: rem(40px);
                display: inline-block;
            }

            .input-file {
                left: -60px !important;
                top: 12px;
                width: rem(40px) !important;
                height: rem(40px) !important;
                max-width: unset;
            }
            .btn.delete,
            .btn.view {
                @include opacity(0);
            }

            &.hide {
                display: none;
            }
        }
        .has-image.editing {
            .btn.delete {
                @include opacity(0.3);
                background-position: left 6px top 13px;
                background-size: 18px;
            }
            .btn.view {
                @include opacity(0.3);
                background-position: left 6px top 13px;
                background-size: 18px;
            }
        }
        &.editing .has-image {
            height: 71px;

            .preview {
                @include background($image: img('offline/camera_cccccc.svg'), $color: #fff, $size: 30px);
                width: 60px;
                height: 60px;

                .image {
                    // height: 60px;
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }
            }
            .add-file,
            .change-image,
            .btn.delete,
            .btn.view {
                visibility: visible;
            }
            .input-file {
                width: 60px !important;
                height: 60px !important;
                border-radius: 5px;
            }
        }
    }

    .suggest {
        -webkit-animation-duration: 1500ms;
        animation-duration: 1500ms;
        -webkit-animation-name: attention;
        animation-name: attention;
        -webkit-animation-timing-function: linear;
        animation-timing-function: linear;
        -webkit-animation-iteration-count: 2;
        animation-iteration-count: 2;
        -webkit-animation-direction: normal;
        animation-direction: normal;
        // transition-duration: 0.2s;
    }

    &.loading {
        $loading-color: #fff;
        animation-duration: 0.2s;

        .itemCopy {
            position: relative;
            border-color: $loading-color;

            &:after {
                @include background($color: $loading-color, $image: img('loader_primary.svg'), $size: 50px);
                @include opacity(0.8);
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                background-color: $loading-color;
                width: 100%;
                height: 100%;
                z-index: 5;
            }
        }
    }

    &[data-type='1'] {
        // CHECK
        .answerCopy {
            .statement {
                max-width: calc(100% - #{rem(40px)});
            }
            .line {
                flex-wrap: nowrap;
                width: calc(100%);
            }

            .responseCopy {
                // height: rem(40px);
                display: flex;
                // gap: 0.625rem;

                .valueCopy {
                    @include border-radius(100%);
                    // @include background($size: 16px, $color: $ans-value-bg);
                    // @include background($size: 16px, $image: img('check_to_do.svg'));
                    background: img('check_to_do.svg');
                    background-size: cover;

                    // &:hover {
                    //     background-color: $ans-value-bg-hover;
                    // }
                }
                .valueCopy.checked {
                    @include background($size: cover, $image: img('checked_ok.svg'));
                    border: none;
                }
            }
        }

        .error {
            .valueCopy {
                background-color: $error-t90 !important;
                border-color: $error-t60 !important;

                &:focus {
                    background-color: tint($error-t90, 95%) !important;
                }
            }
        }
    }

    &[data-type='2'] {
        // YES/NO

        .answerCopy {
            .statement {
                max-width: calc(100% - 15%);
            }

            .responseCopy {
                // width: 15%;
                @include justify-content(flex-end);
                // gap: 0.625rem;

                @media only screen and (max-width: 400px) {
                    flex-direction: column;
                    align-items: flex-end;
                }

                .valueCopy {
                    @include background($color: $ans-bg);

                    &[data-value='0'] {
                        background-image: img('error_dddddd.svg');
                        margin: 0px;
                    }
                    &[data-value='1'] {
                        background-image: img('checked_dddddd.svg');
                        margin: 0px;
                    }

                    &.selected {
                        &[data-value='0'] {
                            background-image: img('error_alert.svg');
                        }
                        &[data-value='1'] {
                            background-image: img('checked_ok.svg');
                        }
                    }

                    // &:hover {
                    //     background-color: $ans-value-bg-hover;
                    // }
                }
            }
        }

        .error {
            .responseCopy {
                [data-value='0'] {
                    background-image: img('error_error.svg') !important;
                    background-color: #bd1f1e !important;
                    border: 1px solid #bd1f1e;
                }
                [data-value='1'] {
                    background-image: img('checked_error.svg') !important;
                    background-color: #bd1f1e !important;
                    border: 1px solid #bd1f1e;
                }
            }
        }
    }

    &[data-type='3'] {
        // YES/NO/NC
        .answerCopy {
            .statement {
                max-width: calc(100% - 20%);
            }

            .responseCopy {
                width: 20%;
                // gap: 0.625rem;
                @include justify-content(flex-end);
            }

            .valueCopy {
                background-color: $ans-value-bg;
            }
            .valueCopy.selected {
                color: $color-white;
            }

            .valueCopy[data-value='0'].selected {
                background-color: $error;
            }
            .valueCopy[data-value='1'].selected {
                background-color: $done;
            }
            .valueCopy[data-value='2'] {
                width: 70px;
            }
            .valueCopy[data-value='2'].selected {
                background-color: #aaa;
            }
        }
        .error {
            .valueCopy {
                background-color: $error-t90 !important;
                color: #bd1f1e !important;
            }
        }
    }

    &[data-type='2'] .answer,
    &[data-type='3'] .answerCopy {
        // YES/NO /NC

        .responseCopy {
            @include display-flex();

            .valueCopy {
                @include border-radius(rem(40px));
                line-height: $answer-size;
                color: $ans-text-color;

                &.selected {
                    color: #fff;
                }
            }
        }
    }

    &[data-type='4'] {
        // TEXT
        .answerCopy {
            @include flex-wrap(wrap);
            .statement {
                max-width: calc(100%);
                display: inline-block;
            }

            .line {
                flex-direction: column;

                .responseCopy {
                    width: 100%;
                    margin-top: 6px;
                    padding-top: 4px !important;
                    border-top: 1px solid #eee;

                    border-top: none;
                    padding-top: 0;
                    margin-top: 0;

                    color: $ans-text-color;

                    .valueCopy {
                        @include background($image: img('input_text_cccccc.svg'), $size: 28px, $position: left 8px center);
                        @include border-radius(0);
                        background-color: $ans-value-bg;
                        width: calc(100%);
                        text-transform: none;
                        text-align: left;
                        padding: 0 0 0 44px;

                        .checkaccess {
                            width: 100%;
                        }
                    }

                    .text-temp {
                        display: none;
                        margin: 0;
                        padding: 0;
                        width: 0;
                        height: 0;
                    }
                }
            }
        }
        .error {
            .valueCopy {
                background-color: $error-t90 !important;
                background-image: img('input_text_error.svg') !important;
            }
        }
    }

    &[data-type='5'] {
        // IMAGE
        .answerCopy {
            $qImage: rem(60px);
            $qActions: rem(40px);
            $qTotal: $qImage + $qActions;
            padding-right: 6px;

            .statement {
                max-width: calc(100% - #{$qTotal});
            }

            .responseCopy {
                @include display-flex();
                // width: $qTotal;

                .preview-image {
                    @include interaction();
                    @include background($image: img('offline/camera_cccccc.svg'), $color: $ans-value-bg);
                    @include border-radius(3px);
                    background-size: calc(#{$qImage} - #{rem(20px)});
                    float: left;
                    width: $qImage;
                    height: $qImage;
                    margin-top: 3px;
                    overflow: hidden;

                    .image-offline {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        @include background($image: img('offline/no_icon_light.svg'), $color: #fff, $size: 30px);
                    }

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        visibility: hidden;
                    }

                    .galery-img {
                        visibility: unset;
                    }

                    &.has-image {
                        background-image: none;
                        img {
                            visibility: inherit;
                            height: 100%;
                            object-fit: contain;
                        }
                    }
                }

                .actions-image {
                    width: $qActions;
                    height: $qImage + rem(5px);
                    float: left;

                    .btn {
                        padding: 0;
                        min-height: unset;
                    }

                    .add-file {
                        width: 0;
                        height: 0;
                        // height: $qImage / 2;
                        .input-file {
                            &.disabled {
                                pointer-events: none;
                            }
                        }
                    }
                    .btn.delete {
                        @include border-radius(3px);
                        width: $qActions;
                        height: $qActions;
                        min-height: $qActions - rem(10px);
                    }
                    .btn.view {
                        @include border-radius(3px);
                        width: $qActions;
                        height: $qActions - rem(10px);
                    }
                    .btn.view + .btn.delete {
                        height: $qActions - rem(10px);
                    }

                    .input-file {
                        width: $qImage !important;
                        height: $qImage !important;
                        max-width: unset;
                        // left: $qActions;
                        background-color: $ans-value-bg;
                        top: -40px;
                        padding: unset;
                    }
                }
            }

            .actions-image:not(.editing) {
                .btn.delete,
                .btn.view {
                    display: none;
                }
            }

            .actions-image.editing {
                .btn.delete {
                    @include background($image: img('delete_cccccc.svg'), $color: transparent, $size: 20px);
                }
                .btn.view {
                    @include background($image: img('eye_cccccc.svg'), $color: transparent, $size: 20px);
                    z-index: 5;
                }
            }
            &.galery {
                // IMAGE GALERY
                $qImgGalery: rem(40px);

                .statement {
                    position: relative;
                    z-index: 2;
                }

                .title-no-description {
                    position: relative;
                    // top: 13px;
                }

                .responseCopy {
                    // display: block;
                    width: 100%;
                    @include display-flex();
                    @include flex-direction(column);
                    @include align-items(flex-end);
                    // margin-top: -$qImgGalery;
                    &.empty-gallery {
                        @include flex-direction(row);
                    }

                    .add-image {
                        .input-file {
                            $qActions: rem(40px);
                            $qImage: rem(40px);
                            width: $qImage !important;
                            height: $qImage !important;
                            max-width: unset;
                            left: $qActions;
                            background-color: $ans-value-bg;
                            top: 0;
                            left: 40px;
                            &.disabled {
                                pointer-events: none;
                            }
                            &.empty {
                                top: 0;
                                left: 40px;
                            }
                        }

                        .preview-image {
                            // margin-top: rem(20px);
                            background-image: img('more_cccccc.svg');
                            background-size: 20px;
                            width: $qImgGalery;
                            height: $qImgGalery;
                        }
                        .actions-image {
                            height: $qImgGalery;
                        }
                    }

                    .galery-list {
                        width: 100%;
                        text-align: right;
                        padding-top: 6px;

                        .galery-item {
                            display: inline-block;
                            margin-left: rem(12px);

                            .preview-image {
                                background: none;

                                .image-offline {
                                    width: 100%;
                                    height: 100%;
                                    object-fit: cover;
                                    @include background($image: img('offline/no_icon_light.svg'), $color: #fff, $size: 30px);
                                }
                            }
                        }
                    }

                    // &.no-description {
                    //     // margin-top: 0px;
                    //     // .add-image {
                    //     //     margin-top: 21px;
                    //     // }
                    // }
                }
            }
        }

        .error {
            .preview-image {
                background-image: img('camera_error.svg') !important;
                background-color: $error-t90 !important;
            }
        }
    }

    &[data-type='6'] {
        // NUMERIC

        .answerCopy {
            padding-right: 6px;

            .statement {
                max-width: calc(100% - #{rem(100px)});
            }

            .responseCopy {
                @include display-flex();

                .valueCopy {
                    @include background($color: $ans-value-bg, $size: 25px);
                    @include font-size(ml);
                    line-height: $answer-size;
                    width: rem(100px);
                    min-height: $answer-size;
                    height: auto;
                    display: block;

                    &:focus {
                        background-color: tint($main, 95%);
                    }
                    &.showTap {
                        background-image: img('input_number_cccccc.svg');
                    }
                    &.no-value {
                        @include font-size(xs);
                        line-height: rem(16px);
                        font-family: $text;
                        background-image: none;
                        padding-top: 5px;
                    }
                }
            }
        }
        .error {
            .valueCopy {
                background-color: $error-t90 !important;
                &:focus {
                    background-color: tint($error-t90, 95%);
                }
                &.showTap {
                    background-image: img('input_number_error.svg') !important;
                }
            }
        }
    }

    // DATE AND TIME TYPES
    &[data-type='7'],
    &[data-type='12'],
    &[data-type='13'] {
        .answerCopy {
            .responseCopy {
                .valueCopy {
                    @include background($size: 28px, $position: left 8px center);
                    width: 100%;
                    text-transform: none;
                    padding: 0 0 0 rem(44px);
                    text-align: left;
                    background-color: $ans-value-bg;
                }
            }
        }
        .error {
            .responseCopy {
                .valueCopy {
                    background-color: $error-t90 !important;
                }
            }
        }
    }

    &[data-type='7'] .itemCopy {
        // DATE
        .statement {
            width: calc(100% - #{rem(206px)});
        }
        .responseCopy {
            width: rem(200px);

            .valueCopy {
                background-image: img('input_date_cccccc.svg');
            }
        }
        &.error {
            .answerCopy {
                .responseCopy {
                    width: 180px;

                    > .valueCopy {
                        background-image: img('input_datetime_error.svg') !important;

                        &::-webkit-input-placeholder {
                            color: #bd1f1e;
                            font-weight: lighter;
                        }

                        &::-moz-placeholder {
                            color: #bd1f1e;
                            font-weight: lighter;
                        }

                        &:-ms-input-placeholder {
                            color: #bd1f1e;
                            font-weight: lighter;
                        }

                        &::-ms-input-placeholder {
                            color: #bd1f1e;
                            font-weight: lighter;
                        }

                        &::placeholder {
                            color: #bd1f1e;
                            font-weight: lighter;
                        }
                    }
                }
            }
        }
    }

    &[data-type='8'] {
        // TITLE
        margin: rem(60px) auto 0px auto;

        .answer-title {
            @include font-size($size: l);
            font-family: $title !important;
            color: $main-s30 !important;
            text-transform: uppercase;
            // background-color: $default-sec-color;
        }
        .statement {
            width: 100% !important;
        }
    }
    &[data-type='9'] {
        // SUBTITLE
        margin: rem(45px) auto 0px auto;

        .answer-title {
            @include font-size($size: ml);
            font-family: $title !important;
            color: $main-dark !important;
        }
    }

    &[data-type="8"], // TITLE &
			&[data-type="9"] {
        // SUBTITLE
        .answerCopy {
            position: relative;
            background-color: transparent;
            padding: unset;
            width: 100%;
            cursor: pointer;
        }
        .answer-title {
            @include display-flex();
            @include justify-content(center);
            @include align-items(center);
            @include font-size(ml);
            @include border-radius(4px);
            background-color: $default-sec-color;
            height: 40px;
            color: $neutro-s90 !important;
            font-family: $text-bold !important;
        }
        .description {
            text-align: center;
        }
        .item,
        .itemCopy.done {
            border: none;
        }

        .sign,
        .alert,
        .state {
            display: none;
        }

        .itemCopy {
            @include border-radius(0);
            width: 100%;
            margin: 0 auto;
            background-color: transparent;
        }

        .btn.action {
            top: auto !important;
            bottom: unset;
            position: absolute;
            // display: none;

            &.open {
                top: 0px !important;
            }
        }

        .actions {
            .action {
                top: unset !important;
                bottom: 10px !important;
                right: 10px;
            }
        }

        .statement {
            max-width: unset !important;
        }
        .response,
        .line {
            padding: 0;
            width: 100% !important;
        }
    }

    // SECTION TITLE
    &[data-type='8'] {
        .answerCopy {
            .answer-title {
                min-height: 50px;
            }
        }

        .line {
            flex-direction: column;

            .statement {
                min-height: 50px;
            }
        }
    }

    &[data-type='10'] {
        // TEXTAREA
        .answerCopy {
            @include flex-wrap(wrap);
            .line {
                flex-direction: column;

                .statement {
                    max-width: calc(100%);
                    display: inline-block;
                }

                .responseCopy {
                    width: 100%;
                    padding-top: 6px;

                    .checkaccess {
                        width: 100%;
                    }

                    .valueCopy {
                        @include background($image: img('input_text_cccccc.svg'), $size: 28px, $position: left 8px center);
                        @include border-radius(0);
                        background-color: $ans-value-bg;
                        width: calc(100%);
                        text-transform: none;
                        text-align: left;
                        padding: 10px 5px 10px 44px;
                        border: none;
                        color: $ans-text-color;
                    }

                    .text-temp {
                        display: none;
                        margin: 0;
                        padding: 0;
                        width: 0;
                        height: 0;
                    }
                }
            }
        }
        .error {
            .valueCopy {
                background-color: $error-t90 !important;
                background-image: img('input_text_error.svg') !important;
            }
        }
    }

    // &[data-type='11'] {
    //     // MULTIPLE SELECT
    //     .answer {
    //         > .line {
    //             @include display-flex();
    //             @include justify-content(space-between);
    //             .statement {
    //                 width: calc(100% - #{rem(200px)});
    //             }
    //         }

    //         .response {
    //             //width: rem(200px);
    //             // display: flex;
    //             //width: 31%;
    //             // gap: 12px;

    //             > div {
    //                 display: unset;
    //                 // width: 140px;
    //             }

    //             .value {
    //                 // @include text-ellipsis();
    //                 @include background($color: $ans-value-bg, $size: 10px, $position: right 9px center, $image: img('down_caret_aaaaaa.svg'));
    //                 -webkit-appearance: none;
    //                 -moz-appearance: none;
    //                 width: 100%;
    //                 padding: 8px;
    //                 padding-right: 26px;

    //                 &:focus {
    //                     background-color: tint($main, 95%);
    //                 }
    //                 &.showTap {
    //                     background-image: img('down_caret_aaaaaa.svg');
    //                     -webkit-appearance: none;
    //                     -moz-appearance: none;
    //                 }
    //             }

    //             option[disabled] {
    //                 display: none;
    //             }
    //         }
    //         .review-check-disabled {
    //             padding: 20px;
    //         }
    //     }
    //     .error {
    //         .value {
    //             &.showTap {
    //                 background-image: img('down_caret_error.svg') !important;
    //                 border: 1px solid $error-t90 !important;
    //                 background-color: $error-t90 !important;
    //             }
    //         }
    //     }
    // }

    &[data-type='12'] .itemCopy {
        // HOUR
        .statement {
            width: calc(100% - #{rem(150px)});
        }
        .responseCopy {
            width: rem(150px);

            .valueCopy {
                width: 100%;
                text-align: center;
                padding-left: 43px;
                background-image: img('input_time_cccccc.svg');
            }
        }
    }

    &[data-type='13'] {
        // DATETIME
        .answerCopy {
            .statement {
                max-width: calc(100% - #{rem(250px)});
            }

            .responseCopy {
                width: rem(250px);

                .valueCopy {
                    @include background($image: img('input_datetime_cccccc.svg'), $size: 28px, $position: left 8px center);
                    width: 100%;
                    padding: 0 0 0 44px;
                    text-align: left;
                    display: flex;

                    &::-webkit-input-placeholder {
                        color: #ccc;
                        font-weight: lighter;
                    }

                    &::-moz-placeholder {
                        color: #ccc;
                        font-weight: lighter;
                    }

                    &:-ms-input-placeholder {
                        color: #ccc;
                        font-weight: lighter;
                    }

                    &::-ms-input-placeholder {
                        color: #ccc;
                        font-weight: lighter;
                    }

                    &::placeholder {
                        color: #ccc;
                        font-weight: lighter;
                    }
                }
                input[type='datetime-local']::-webkit-calendar-picker-indicator {
                    background: none;
                }
            }
        }
        .error {
            .valueCopy {
                background-image: img('input_datetime_error.svg') !important;
            }
        }
    }

    &[data-type='14'] {
        // SIGNATURE
        .answerCopy {
            padding-right: rem(6px);

            .statement {
                // max-width: calc(100% - #{rem(140px)} - #{rem(6px)});+
                max-width: 60%;
            }

            .responseCopy {
                // @include display-flex();
                // // width: rem(146px);
                // align-items: flex-end;

                .valueCopy {
                    @include background($color: $ans-value-bg, $size: 28px);
                    @include font-size(sm);
                    width: rem(40px);
                    display: block;

                    &:focus {
                        background-color: tint($main, 95%);
                    }
                    &.unsigned {
                        // margin-left: auto;
                        background-image: img('input_sign_cccccc.svg');
                    }
                }

                .sign-block {
                    display: flex;
                    gap: 4px;
                    align-items: center;
                }

                .signed {
                    // width: rem(100px);
                    width: 60px;
                    height: 40px;
                    // margin-right: rem(6px);

                    .text {
                        @include text-ellipsis();
                        width: 100%;
                        height: calc(100% - #{rem(50px)});
                    }
                    .ico {
                        @include background($size: cover);
                        width: 100%;
                        // height: rem(50px);
                        height: 40px;
                    }
                }

                .value,
                .signed {
                    display: block;
                    float: right;
                }
            }
        }
        .error {
            .valueCopy {
                background-color: $error-t90 !important;

                &.unsigned {
                    background-image: img('input_sign_error.svg') !important;
                }
            }
        }
    }

    &[data-type='15'] {
        // TEMPERATURE
        .answerCopy {
            padding-right: 6px;

            .statement {
                max-width: calc(100% - #{rem(90px)});
            }

            .responseCopy {
                @include display-flex();

                .valueCopy {
                    @include background($color: $ans-value-bg, $size: 25px);
                    @include font-size(ml);
                    line-height: $answer-size;
                    width: rem(90px);
                    display: block;

                    &:focus {
                        background-color: tint($main, 95%);
                    }
                    &.showTap {
                        background-image: img('input_temperature_cccccc.svg');
                    }
                    &.no-value {
                        @include font-size(xs);
                        line-height: rem(16px);
                        font-family: $text;
                        background-image: none;
                        padding-top: 5px;
                    }

                    // &:not(.showTap):not(.no-value):after {
                    //     content: '\00b0';
                    // }
                }
            }
        }
        .error {
            .valueCopy {
                background-color: $error-t90 !important;
                @include font-size(ml);
                line-height: $answer-size;
                width: rem(90px);
                display: block;

                &:focus {
                    background-color: tint($error-t90, 95%) !important;
                }
                &.showTap {
                    background-image: img('input_temperature_error.svg') !important;
                }
                &.no-value {
                    @include font-size(xs);
                    line-height: rem(16px);
                    font-family: $text;
                    background-image: none;
                    padding-top: 5px;
                }

                // &:not(.showTap):not(.no-value):after {
                //     content: '\00b0';
                // }
            }
        }
    }

    &[data-type='16'] {
        // SEND MAIL
        .answerCopy {
            .statement {
                max-width: calc(100% - #{rem(120px)});
            }

            .responseCopy {
                @include display-flex();

                .valueCopy {
                    @include background($color: $ans-value-bg, $size: 25px, $image: img('send_cccccc.svg'), $position: right 6px center);
                    @include font-size(xs);
                    width: rem(120px);
                    padding-right: 30px;
                    display: block;
                    text-transform: none;
                    font-family: $text;
                    color: $ans-text-color;

                    &:focus {
                        background-color: tint($main, 95%);
                    }
                    &.sended {
                        @include background($color: transparent, $image: none);
                        padding-right: 0;
                    }
                }
            }
        }
        .error {
            .valueCopy {
                color: #bd1f1e !important;
                background-color: $error-t90 !important;
                background-image: img('send_error.svg') !important;
            }
        }
    }

    &[data-type="17"] .answer,  // DOCUMENT
		&[data-type="18"] .answerCopy {
        // MESSAGE

        .statement {
            @include interaction();
            @include background($image: img('up_dark.svg'), $size: 14px, $position: right 6px center);
            max-width: calc(100%);
            display: inline-block;
            padding-right: $answer-size;
        }

        .responseCopy {
            @include flex-direction(column);
            min-height: unset;
            width: 100%;

            .value-content {
                @include background($color: $ans-value-bg);
                @include border-radius(0);
                width: calc(100%);
                text-transform: none;
                text-align: left;
                padding: 10px 5px 10px 44px;
                height: auto;
                color: $ans-text-color;
            }
        }
    }
    &[data-type="17"].value-collapsed .answer,  // DOCUMENT
		&[data-type="18"].value-collapsed .answerCopy {
        // MESSAGE

        .statement {
            @include background($image: img('down_dark.svg'), $size: 14px, $position: right 6px center);
        }

        .responseCopy {
            .value-content {
                height: 0;
                display: none;
                overflow: hidden;
            }
        }
    }
    &[data-type='17'] .answerCopy {
        // DOCUMENT

        .responseCopy {
            @include flex-direction(column);
            padding-top: 12px;

            .conditions {
                @include display-flex();
                @include justify-content();
                width: 100%;
                height: rem(40px);
                text-align: center;
                margin: 12px 0 6px 0;

                .text-check,
                .valueCopy {
                    display: inline-block;
                    color: $ans-text-color;
                }
                .text-check {
                    @include font-size(sm);
                    font-family: $text;
                    line-height: rem(40px);
                    height: rem(40px);
                    max-width: calc(100% - #{rem(40px)});
                    width: auto;
                    padding-right: 12px;
                }
                .valueCopy {
                    @include border-radius(100%);
                    @include background($size: 16px, $color: $ans-value-bg);
                    width: rem(40px);
                    border: 2px solid $ans-border;

                    &.checked {
                        @include background($size: cover, $image: img('checked_ok.svg'));
                        border: none;
                    }
                }
            }
        }
    }

    &[data-type='18'] .answerCopy {
        // MESSAGE
        @include flex-wrap(wrap);

        .responseCopy {
            padding-top: 6px;
        }
    }

    &[data-type='19'] {
        // LINK

        .answerCopy {
            padding-right: 6px;
            .statement {
                max-width: calc(100% - #{$answer-size});
            }

            .responseCopy {
                @include display-flex();

                .valueCopy {
                    @include background($image: img('link_primary.svg'), $color: $ans-value-bg, $size: 25px);
                    @include font-size(ml);
                    line-height: $answer-size;
                    width: $answer-size;
                    display: block;

                    &:focus {
                        background-color: tint($main, 95%);
                    }
                }
            }
        }
        .error {
            .valueCopy {
                background-image: img('link_error.svg') !important;
                background-color: $error-t90 !important;
            }
        }
    }

    &[data-type='20'] {
        .itemCopy {
            border-color: $default-sec-color;
            .answerCopy {
                // LINK
                padding-right: 6px;

                .statement {
                    max-width: calc(100% - #{$answer-size});
                }

                .responseCopy {
                    @include display-flex();

                    .valueCopy {
                        @include background($image: img('eye_preview_dark.svg'), $color: $ans-value-bg, $size: 25px);
                        @include font-size(ml);
                        line-height: $answer-size;
                        width: $answer-size;
                        display: block;

                        &:focus {
                            background-color: tint($main, 95%);
                        }
                    }
                }
            }
            &.error {
                border-color: $error;
                .answerCopy {
                }
                .valueCopy {
                    background-color: $error-t90 !important;
                    background-image: img('eye_preview_error.svg') !important;
                }
            }
        }
    }

    &.uncompleted {
        .done {
            border-color: #ccc;
        }
        .answerCopy {
            .statement {
                width: calc(100% - #{rem(120px)});
                max-width: calc(100% - #{rem(120px)});
            }
            .responseCopy {
                // width: rem(120px)
                width: fit-content;
                display: block;
                text-align: center;
                margin: 0;
                display: grid;
                align-items: center;
            }
        }
    }

    &[data-type='17'].uncompleted .answer,
    &[data-type='18'].uncompleted .answerCopy {
        // MESSAGE

        .statement {
            width: 100%;
            max-width: 100%;
        }
        .responseCopy {
            width: 100%;
        }
    }

    &.complete-all {
        margin-bottom: rem(60px);

        .itemCopy {
            border: none;
        }

        .answerCopy {
            width: 100%;
            background-color: $main-t80;

            .line {
                padding: 0 10px;
            }
            .statement {
                max-width: calc(100% - #{$answer-size});

                .answer-title {
                    color: $main-dark;
                }
            }

            .responseCopy {
                height: $answer-size;

                .valueCopy {
                    @include border-radius(100%);
                    @include background($size: 16px, $color: $main-t80);
                    border: 2px solid $main-t10;

                    @include bgHover($main-t80);
                }
                .valueCopy.semichecked {
                    @include background($size: cover, $image: img('checked_aaaaaa.svg'));
                    border: none;
                }
                .valueCopy.checked {
                    @include background($size: cover, $image: img('checked_primary.svg'));
                    border: none;
                }
            }
        }
    }

    &[noApply='1'] .answerCopy {
        .statement {
            width: calc(100% - #{rem(150px)});
            max-width: calc(100% - #{rem(150px)});
        }
        .responseCopy {
            width: rem(150px);
            display: block;
        }
    }
}

.questionCopy.past,
.questionCopy.future {
    @include filter(grayscale(25%));
    position: relative;

    .ico.lock {
        @include background($image: img('lock_aaaaaa.svg'), $size: 25px, $position: top 6px center);
        position: absolute;
        width: rem(50px);
    }

    .itemCopy {
        //border-width: 50px;
        border-color: #eee;
    }

    .responseCopy {
        @include opacity(0.8);
    }
}

.questionCopy.future .ico.lock {
    background-image: img('unlock_primary.svg');
}

.questionCopy.postposed-to {
    .statement {
        max-width: calc(100% - #{rem(80px)}) !important;
    }
    .responseCopy {
        width: rem(80px) !important;
    }

    .itemCopy {
        border-color: $info;
        // border-color: $ans-bg;

        .line {
            padding-right: 0px;

            .responseCopy {
                .postposed {
                    width: 100%;
                }
            }
        }
    }
}

.questionCopy.child {
    width: 95% !important;
    // margin-top: rem(30px) !important;
    // margin-bottom: rem(30px) !important;
    margin-left: 5% !important;
}
.questionCopy.hidden {
    display: none;
}

.answerCopy > .flex-row {
    display: flex;
    flex-direction: row !important;

    .responseCopy {
        width: 100%;
        max-width: 100%;
        display: flex !important;
        align-content: flex-end;
    }
}

@-webkit-keyframes attention {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    25% {
        -webkit-transform: rotate(-10deg) scale(1.2);
        transform: rotate(-10deg) scale(1.2);
        @include opacity(0.9);
    }
    50% {
        -webkit-transform: rotate(0) scale(1);
        transform: rotate(0) scale(1);
    }
    75% {
        -webkit-transform: rotate(10deg) scale(1.2);
        transform: rotate(10deg) scale(1.2);
        @include opacity(0.9);
    }
    100% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
}
@keyframes attention {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    25% {
        -webkit-transform: rotate(-10deg) scale(1.2);
        transform: rotate(-10deg) scale(1.2);
        @include opacity(0.9);
    }
    50% {
        -webkit-transform: rotate(0) scale(1);
        transform: rotate(0) scale(1);
    }
    75% {
        -webkit-transform: rotate(10deg) scale(1.2);
        transform: rotate(10deg) scale(1.2);
        @include opacity(0.9);
    }
    100% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
}
</style>

<!-- // PORTRAIT STYLES -->
<style lang="scss" scoped>
.questionCopy.portrait {
    padding: 0 !important;
    margin: 8px 0 !important;
    // overflow: hidden;
    max-width: 100%;

    .has-comment {
        overflow: hidden;
        flex-wrap: nowrap;

        .has-note {
            margin-left: 0px !important;
            width: 100%;
            .note {
                font-size: 0.7rem;
                height: 60px;
            }
            // .note[disabled],
            // .note:focus[disabled] {
            // }
        }

        .has-image {
            position: relative;

            .add-file {
                position: absolute;

                input {
                    left: 0 !important;
                }
            }
            // width: 60px;

            .image {
                height: 100% !important;
                width: 100% !important;
                object-fit: cover !important;
            }
        }

        &:not(.editing) {
            .has-note {
                width: calc(100% - 68px);
            }

            .has-image {
                width: auto;
            }
        }
    }
    &[data-type='1'] {
        // CHECK
        .answerCopy {
            .statement {
                max-width: calc(100% - #{rem(40px)});
            }
            .line {
                flex-wrap: nowrap;
                width: 100%;
            }
            // .responseCopy {
            //     // display: table;
            //     // gap: 0.625rem;
            // }
        }
    }
    &[data-type='2'] {
        // S/N/NS
        .answerCopy {
            .statement {
                max-width: calc(100% - 35%);
            }
            .responseCopy {
                // width: 35%;
                // flex-wrap: wrap;
                @include justify-content(flex-end);
            }
        }
    }
    &[data-type='3'] {
        // YES/NO/NC
        .answerCopy {
            .statement {
                max-width: calc(100% - 60%);
            }

            .responseCopy {
                width: 60%;
                flex-wrap: wrap;
                // @include justify-content(flex-end);
                justify-content: flex-end !important;
                gap: 8px;
            }

            .valueCopy {
                margin-left: 2px;
            }
        }
    }
    &[data-type='5'] .itemCopy {
        $qImage: rem(40px);
        $qActions: rem(40px);
        $qTotal: $qImage + $qActions;

        // IMAGE
        .statement {
            max-width: calc(100% - #{$qImage} - 25px);

            &.has-image {
                max-width: calc(100% - #{$qTotal} - 25px);
            }
        }
        .responseCopy {
            position: relative;
            width: min-content;

            &.empty-gallery {
                justify-content: flex-end !important;
            }

            .actions-image {
                position: absolute;
                left: right;
                width: 50px;

                .add-file {
                    width: 50px;
                    height: 0px;
                    position: absolute;

                    input {
                        left: 0;
                    }
                }
            }

            .editing {
                position: relative;
                display: initial;
                width: $qActions;

                .input-file {
                    top: 0;
                    left: 50px !important;
                }
            }

            // .valueCopy {
            // }
        }
        .galery {
            .add-image {
                // margin-top: 30px !important;

                .add-file {
                    width: 50px;
                    height: 50px;

                    .input-file {
                        left: 0 !important;
                        top: 0;

                        &.disabled {
                            pointer-events: none;
                        }
                    }
                }
            }
        }
    }

    &[data-type='12'] .itemCopy {
        // HOUR
        .statement {
            width: calc(100% - #{rem(100px)});
        }
        .responseCopy {
            width: rem(100px);

            .valueCopy {
                width: 100%;
                text-align: center;
                padding-left: 25px;
                background-image: img('input_time_cccccc.svg');
                background-size: 20px;
                padding-top: 15px;
            }
        }
    }

    &[data-type='15'] .itemCopy {
        // TEMPERATURE
        .statement {
            width: calc(100% - #{rem(100px)});
        }
        .responseCopy {
            width: rem(100px);

            .valueCopy {
                font-family: $text-light;
                width: 100%;
            }
        }
    }
    // Datetime
    &[data-type='13'] .answerCopy {
        .line {
            @include display-flex();
            @include flex-direction(row);
            width: 100% !important;
            justify-content: space-between;

            .statement {
                width: 100%;
                max-width: calc(100% - 220px);
            }

            .responseCopy {
                width: 220px;

                .valueCopy {
                    @include background($image: img('input_datetime_cccccc.svg'), $size: 28px, $position: left 8px center);
                    // width: 100%;
                    padding: 0 0 0 44px;
                    text-align: left;
                    max-width: 223px;

                    &::-webkit-input-placeholder {
                        color: #ccc;
                        font-weight: lighter;
                    }

                    &::-moz-placeholder {
                        color: #ccc;
                        font-weight: lighter;
                    }

                    &:-ms-input-placeholder {
                        color: #ccc;
                        font-weight: lighter;
                    }

                    &::-ms-input-placeholder {
                        color: #ccc;
                        font-weight: lighter;
                    }

                    &::placeholder {
                        color: #ccc;
                        font-weight: lighter;
                    }
                }
            }
        }
    }
    // DATE
    &[data-type='7'] {
        .answerCopy {
            .line {
                @include display-flex();
                @include flex-direction(row);
                width: 100% !important;
                justify-content: space-between;

                .statement {
                    width: 100%;
                    max-width: calc(100% - 180px);
                }

                .responseCopy {
                    width: 180px;

                    .valueCopy {
                        @include background($image: img('input_datetime_cccccc.svg'), $size: 28px, $position: left 8px center);
                        width: 100%;
                        padding: 0 0 0 44px;
                        text-align: left;

                        width: 171px;

                        &::-webkit-input-placeholder {
                            color: #ccc;
                            font-weight: lighter;
                        }

                        &::-moz-placeholder {
                            color: #ccc;
                            font-weight: lighter;
                        }

                        &:-ms-input-placeholder {
                            color: #ccc;
                            font-weight: lighter;
                        }

                        &::-ms-input-placeholder {
                            color: #ccc;
                            font-weight: lighter;
                        }

                        &::placeholder {
                            color: #ccc;
                            font-weight: lighter;
                        }
                    }
                }
            }
        }
    }
    // SELECT
    &[data-type='11'] .answerCopy {
        .line {
            @include display-flex();
            @include flex-direction(row);
            justify-content: space-between;
            width: 100%;

            .statement {
                width: calc(100% - #{rem(100px)}) !important;
                /* .answer-title {
                        width: 130px;
                    } */
                .description {
                    overflow-wrap: break-word;
                }
            }
            .responseCopy {
                // display: initial;

                .valueCopy {
                    @include text-ellipsis();
                    // width: 160px !important;
                }
            }
            .review-check-disabled {
                margin-top: 6px;
            }
        }
    }
    // Email
    &[data-type='16'] .answerCopy {
        .line {
            @include display-flex();
            @include flex-direction(row);
            justify-content: space-between;
            width: 100%;

            .statement {
            }

            .responseCopy {
                width: auto;

                .valueCopy {
                    padding: 10px 10px;
                    padding-right: 30px;
                    background-size: 25px;
                }
            }
        }
    }

    .itemCopy.portrait {
        .answerCopy {
            @include justify-content(flex-end);
            // width: calc(100%) !important;
            .line {
                padding-right: 0 !important;
                .statement {
                    .answer-title {
                        @include font-size(s);
                        line-height: 0.875rem !important;
                        padding-right: 5px;
                        font-family: $text-bold;
                        word-break: break-word;
                        &:after {
                            font-family: $text-light;
                            @include font-size(xs);
                        }
                    }
                    .description {
                        @include font-size(xs);
                        font-family: $text-light;
                        line-height: 0.9rem !important;
                        color: #666 !important;
                        margin-top: 5px;
                        li {
                            color: #666 !important;
                            font-family: $text-light !important;
                        }
                    }

                    @include portrait-tablet {
                        .answer-title {
                            // @include font-size(m);
                            font-size: 18px;
                            line-height: 23px !important;
                        }

                        .description {
                            @include font-size(s);
                            line-height: 23px !important;
                        }
                    }
                }
                .responseCopy {
                    justify-content: flex-start;
                    .unknown {
                        @include font-size(xs);
                        width: max-content;
                    }
                    // .value {
                    // }

                    .responseNoApply {
                        .text {
                            @include font-size(s);
                            // padding: 6px 10px 3px 30px;
                        }
                    }
                }
            }

            .review,
            .unreview {
                @include font-size(xs);
                margin-top: 5px;
                height: 40px;
                padding: 0px 5px;
                min-height: unset;
                width: auto;
            }

            &.toReview {
                .line {
                    width: calc(100%);
                }
            }
        }

        .btn.action.noApply {
            background-image: img('noApply-portrait.svg');
            background-size: 30px;
        }

        .btn.action.toggleMeta {
            width: 24px;
        }

        .meta {
            min-height: auto !important;
            .info {
                width: 100% !important;
                min-height: unset;
                .sign,
                .review {
                    @include font-size(xs);
                    widows: 100% !important;
                    .name,
                    .bold,
                    .datetime {
                        @include font-size(xs);
                    }
                }
                .timing,
                .role {
                    .line {
                        .name {
                            @include font-size(xs);
                        }
                        .roles {
                            @include font-size(xs);
                        }
                    }
                }
            }
            .actions {
                width: unset;

                button.btn.action {
                    @include font-size(0px);
                    background-color: $default-bg-color;
                    line-height: 16px;
                    background-size: 25px;
                    background-position: center center;
                    height: 45px;
                    width: 45px;
                    margin-left: 15px;
                    margin-top: 5px;

                    &:last-child {
                        margin-left: 0;
                    }
                }
            }
            .sync {
                @include font-size(xs);
            }
        }
    }
    .custom-alert-task {
        padding-left: 6px;
        .andy {
            left: 0px;
        }
    }
    &[noApply='1'] .answerCopy {
        .line {
            width: 100%;
        }
        .statement {
            width: calc(100% - 150px);
            max-width: calc(100% - 150px);
        }
        .responseCopy {
            height: 100%;
            width: 150px;
        }
    }

    & > .reference-link {
        margin-left: 0px !important;
        .message {
            display: block;
            margin-left: 9px;
            padding: 0px;

            .preview {
                width: calc(100% - 38px);
            }

            .read-more {
                text-align: left;
                margin-left: 30px;
            }
        }
    }
}
</style>
