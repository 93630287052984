<template>
    <div class="item slideRight" :class="[{ w_picture: item.image && item.num_progress == 0, w_progress: !item.image && item.num_progress > 0, extended: item.image && item.num_progress > 0 }, $mq]" @click="sendedId(item.id)">
        <div class="list">
            <template v-if="!auditsNoPhoto">
                <div v-if="item.image" class="picture" :style="{ backgroundImage: 'url(' + item.image + ')', backgroundSize: 'cover' }"></div>
                <!-- <cld-image class="picture" v-if="item.image" :publicId="item.image" type="fetch" loading="lazy" :style="{ backgroundSize: 'cover' }">
                    <cld-transformation height="300" crop="fill" fetchFormat="webp" />
                </cld-image> -->
                <div v-else class="picture"></div>
            </template>
            <h6 class="name">{{ item.name }}</h6>
            <div class="progress" v-if="item.num_progress > 0">{{ item.num_progress }} {{ $t(item.num_progress > 1 ? 'audit.num_progress_pl' : 'audit.num_progress_sg') }}</div>
        </div>
        <div class="nav" v-if="['landscape', 'desktop'].includes($mq)"></div>
    </div>
</template>
<script>
export default {
    props: {
        item: { type: Object },
        auditsNoPhoto: { type: Boolean }
    },
    data() {
        return {}
    },
    computed: {},
    methods: {
        sendedId(id) {
            this.$emit('sendedId', id)
        },
        sendedAddedit(id) {
            this.$emit('sendedAddedit', id)
        }
    },
    filters: {
        moment: function (date) {
            return date ? moment(date).fromNow() : '--'
        }
    }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.item {
    @include interaction();
    @include border-radius();
    @include display-flex();
    @include align-items();
    @include flex-wrap(wrap);
    background-color: #fff;
    width: calc(50% - 24px);
    height: auto;
    margin: 12px;
    overflow: hidden;
    padding: 3px;
    position: relative;
    @include bgHover(#fff);
    .list {
        width: calc(100% - 70px);
        height: auto;
        padding: 6px;
        .picture {
            @include border-radius(3px);
            @include background($color: $neutro-t50, $image: img('image_neutro.svg'), $size: cover);
            height: 100px;
            width: 100%;
            margin-bottom: 10px;
            object-fit: cover;
        }
        .name {
            @include font-size(l);
            margin: 0;
            font-family: $text-bold;
            color: $main-s30;
            width: calc(100%);
            height: auto;
            max-height: calc(100% - 24px);
            display: inline-block;
        }
        .subname {
            @include font-size(sm);
            height: auto;
            max-width: calc(100%);
            padding: 3px 0px;
            display: inline-block;
            .total {
                font-family: $text-bold;
            }
        }
        .progress {
            width: fit-content;
            padding: 2px 10px 2px 25px;
            background-color: $warning;
            border-radius: 5px;
            color: #ffffff;
            font-family: $text-bold;
            @include font-size(s);
            margin-top: 10px;
            @include background($image: img('edit_ffffff.svg'), $position: left 5px center, $size: 15px);
        }
    }
    .nav {
        position: absolute;
        right: 0;
        width: 70px;
        height: 100%;
        @include background($image: img('right_dark.svg'), $position: center center, $size: 20px);
    }
    &.portrait {
        width: 100%;
        margin: 12px;

        .list {
            width: 100%;

            .name {
                @include font-size(sm);
                width: 100%;
                padding-left: unset;
                padding-right: unset;
            }
            .subname {
                @include font-size(xs);
            }
            .progress {
                @include font-size(xs);
            }
        }
    }
}
@include media('>tablet', '<desktop') {
    .item {
        width: calc(50% - 24px);
        margin: 12px;
    }
}
@include media('<900px', '>650px') {
    .item {
        width: calc(50% - 24px) !important;
        margin: 12px;

        .list {
            @include display-flex();
            @include flex-direction(column);
            justify-content: space-between;
            height: 100%;
        }

        .progress {
            margin-top: 0px;
        }
    }
}
@include media('>=desktop') {
    .item {
        width: calc(33% - 24px);
        margin: 12px;
    }
}
</style>
