<template>
    <div id="content" class="audit" :class="[{ detail: addeditId }, $mq]">
        <div v-if="!addeditId" class="content scrollbar full" :class="[{ 'hide-scrollbar': ['portrait'].includes($mq) }]">
            <item v-if="!detailId" v-for="reg in templates" :item="reg" :auditsnoPhoto="auditsNoPhoto" @sendedId="openDetail" @sendedAddedit="createAudit"></item>
            <EmptyPage v-if="!detailId && Object.values(templates).length == 0" :title="$t('empty_pages.generic_title')" :description="$t('empty_pages.audit_template_description')"></EmptyPage>

            <resume :hasError="errorAudit()" v-if="detailId && audit && !addeditId" :audit="audit" :detailId="detailId" @sendedAddedit="loadAuditEdit"></resume>
            <p v-if="detailId && audit && !addeditId && !audit" class="empty">
                {{ $t('audit.empty_templates') }}
            </p>
        </div>

        <detail v-if="detailId && addeditId" :cType="checkType" :gId="addeditId" :showErrors="showErrors" @update-show-errors="updateShowErrors" :permissions="detailPermissions()" :openSection="openSection" @updateToggle="updateToggle"></detail>
    </div>
</template>

<script>
import { FORMAT } from '@/constants'
import EmptyPage from '@/components/layout/EmptyPage'
import item from '@/components/domain/audit/item'
// Este detail es la tabla
import resume from '@/components/domain/audit/resume'
// Este detail es el comun de cheklist para crear
import detail from '@/components/domain/audit/detail'
import i18n from '@/i18n'

export default {
    name: 'Audit',
    components: { item, resume, detail, EmptyPage },
    data() {
        return {
            checkType: 2,
            detail: false,
            addedit: false,
            FORMAT: FORMAT,
            auditsNoPhoto: true,
            showErrors: false,
            openSection: null
        }
    },
    computed: {
        user_id() {
            return this.$store.getters['loginUser/getLocalEmployee']
        },
        user() {
            var employee = this.$store.getters['employee/getEmployee'](this.user_id)
            if (employee) {
                return employee
            }
        },

        templates() {
            log('audtis templates -> ', this.$store.getters['audit/getTemplates'])
            var templates = this.$store.getters['audit/getTemplates']

            var result = {}
            var rolEmployee = this.$store.getters['employee/getRol'](this.user.rol)

            for (var templates_index in templates) {
                var template = templates[templates_index]
                if (template && template.config != false && template.config.nRoles && template.config.nRoles.length > 0) {
                    const templateRoles = template.config.nRoles
                    let bAuditAdded = false
                    templateRoles.forEach((role) => {
                        if (role.roleId == this.user.rol) {
                            result[templates_index] = template
                            bAuditAdded = true
                        }
                    })

                    if (rolEmployee.level >= 8 && !bAuditAdded) {
                        result[templates_index] = template
                    }
                } else if (template && template.config != false && template.config.roles && template.config.roles.length > 0) {
                    // Si el rol del usuario está en el listado del template o es nivel 8 se muestra
                    var templateroles = template.config.roles

                    if (templateroles.includes(this.user.rol) || rolEmployee.level == 8) {
                        result[templates_index] = template
                    }
                    // SI NO TIENE NINGUN ROL SE MUESTRA
                } else result[templates_index] = template
            }

            // DETECT IF ANY ITEM HAS A PHOTO
            var self = this
            var imageFound = Object.values(result).filter((obj) => {
                return obj.image != false ? (self.auditsNoPhoto = false) : true
            })

            return result
        },
        audit() {
            const audit = this.$store.getters['audit/getItems']
            const sortedReturn = Object.keys(audit)
                .sort((a, b) => moment(audit[b].created_date) - moment(audit[a].created_date))
                .reduce((obj, key) => {
                    obj[key] = audit[key]
                    return obj
                }, {})
            return sortedReturn
        },
        detailId: {
            get() {
                return this.detail
            },
            set(value) {
                this.detail = value
            }
        },
        addeditId: {
            get() {
                return this.addedit
            },
            set(value) {
                this.addedit = value
            }
        },

        // obtener score para mostrar el popup
        check() {
            var check = this.$store.getters['audit/getById'](this.$route.params.edit)
            return check
        },
        // saber si mostrar el popup o no
        template() {
            // log('audtis templates -> ', this.$store.getters['audit/getTemplates'])
            var template = this.$store.getters['audit/getTemplateById'](this.$route.params.id)
            // var showScore = { show_score: 0 }
            // templates.config = showScore

            return template
        },
        userAppAccess() {
            return this.$store.getters['loginUser/getHasAccessApp']
        },
        itemsExcluded() {
            return this.$store.getters['tasks/getItemsExcluded']
        },
        roles() {
            return this.$store.getters['employee/getAllRoles']
        },
        auditsWithTempId() {
            return this.$store.getters['audit/getItemsWithTemporalId']
        }
    },
    methods: {
        updateShowErrors(newShowErrorsValue) {
            this.showErrors = newShowErrorsValue
        },
        updateToggle(newOpenSectionValue) {
            this.openSection = newOpenSectionValue
        },
        rolesWithWritePermissions() {
            const permissions = this.templates[this.$route.params.id]
            const roles = []
            if (permissions && permissions.config && permissions.config.nRoles && permissions.config.nRoles.length > 0) {
                const idOfRoles = permissions.config.nRoles.map((role) => {
                    if (role.permissions.includes('w')) {
                        return role.roleId
                    }
                })
                Object.values(this.roles).map((role) => {
                    if (idOfRoles.includes(role.id)) {
                        roles.push(role.name)
                    }
                })
            }

            return roles
                .join(', ')
                .toString()
                .replace(/,(?=[^,]*$)/, ' ' + this.$t('answer.or'))
        },
        errorAudit() {
            if (this.templates.length == 0 || this.detailId == undefined) {
                return false
            }
            for (var template in this.templates) {
                var idx = this.templates[template]
                log(idx, this.detailId)
                if (idx.id == this.detailId) {
                    return false
                }
            }
            return true
        },
        load(refresh = true) {
            var self = this
            log('-----------------------------LOAD')
            log(self.$route.params.id)
            log(self.$route.params.edit)
            log('-----')
            // if (this.templates && Object.values(this.templates).length > 0 && this.userAppAccess) {
            //     this.$bar.addAction('create', {
            //         callback: function () {
            //             self.listAudits()
            //         },
            //         label: self.$t('popup.add_template.text_save')
            //     })
            // }

            self.detailId = self.$route.params.id
            //   self.addeditId = self.$route.params.edit

            if (self.detailId && !self.$route.params.edit) {
                self.showErrors = false
                self.openSection = null
                self.addeditId = false
                self.$store.dispatch('audit/loadItems', { template: self.detailId, noRefresh: self.$route.params.noRefresh }).then(function () {
                    self.openDetail(self.detailId)
                })
            } else if (self.detailId && self.$route.params.edit) {
                log(' - hay addedit')
                self.$store.dispatch('audit/loadById', { id: self.$route.params.edit, noRefresh: self.$route.params.noRefresh }).then(function () {
                    log('call openAdd')
                    self.addeditId = self.$route.params.edit
                    self.openAddedit(self.addeditId)
                })
            } else {
                self.addeditId = false
            }
        },

        currentEmployee() {
            var emp_id = this.$store.getters['loginUser/getLocalEmployee']

            if (emp_id) {
                var emp = this.$store.getters['employee/getEmployee'](emp_id)
            } else {
                var emp = this.$store.getters['loginUser/getLocalLocation']
            }

            return emp
        },

        openDetail(id) {
            var self = this

            if (self.$store.getters['hasAccess'](self.currentEmployee().id, 0)) {
                self.goToDetail(id)
            } else {
                this.$popup.login({
                    list: this.$store.getters['employee/getLoginUsers'](1),
                    callAfterAccess: function (response) {
                        if (response) {
                            self.goToDetail(id)
                            self.$popup.close()
                        }
                    }
                })
            }
        },
        goToDetail(id) {
            log(' >> openDetail', id, this.detailId)
            var self = this

            if (!self.detailId) {
                self.$router.push({ name: 'AuditDetail', params: { id: id } })
            } else {
                self.$bar.reset()

                // self.$bar.addAction('back', {
                //     callback: function() {
                //         self.$router.push({ name: 'Audit' })
                //     },
                //     label: self.$t('menu_aux.action.back')
                // })
                // validate if template has roles
                var template = this.templates[this.$route.params.id]
                var templateroles = template && template.config && template.config.roles && template.config.roles.length > 0 ? template.config.roles : false
                let userRoleHasTemplateRole = templateroles ? templateroles.includes(this.user.rol) : true
                // if (this.templates && Object.values(this.templates).length > 0 && Object.values(this.audit).length > 0 && this.userAppAccess && userRoleHasTemplateRole) {
                //     self.$bar.addAction('create', {
                //         callback: function () {
                //             self.createAudit(id)
                //         },
                //         label: self.$t('menu_aux.action.create')
                //     })
                // }
            }
        },

        createAudit(id) {
            var self = this
            log(' >> createAudit', id)
            if (!fnCheckConnection() && !this.$store.getters['getOffline']) {
                self.$popup.alert({
                    closable: false,
                    blocking: false,
                    message: self.$t('popup.audit.offline_add'),
                    textSave: self.$t('popup.audits.exit'),
                    callSave: function (response) {
                        self.$popup.close()
                    }
                })
                return
            }

            self.$overlay.loading()
            self.$store.dispatch('audit/loadItems', { template: id }).then(function () {
                var audit_tmpl = self.$store.getters['audit/getTemplateById'](id)
                var roles = typeof audit_tmpl.config.roles !== 'undefined' && audit_tmpl.config.roles != '' ? audit_tmpl.config.roles.toString() : []
                var level = typeof audit_tmpl.config.level !== 'undefined' ? audit_tmpl.config.level : 3

                var empl_curr = self.$store.getters['loginUser/getLocalEmployee']
                empl_curr = self.$store.getters['employee/getEmployee'](empl_curr)
                const roleLevel = self.$store.getters['employee/getRol'](empl_curr.rol).level
                log('rolLevel', roleLevel, level)
                if ((self.checkPermissions(id, true) && (roles.length == 0 || roles.includes(empl_curr.rol))) || roleLevel >= level) {
                    // if (self.$store.getters['hasAccess'](self.currentEmployee().id, role)) {
                    // self.$router.push({
                    //   name: 'AuditEdit',
                    //   params: { id: id, edit: 'add' }
                    // })
                    self.$store.dispatch('audit/changeSupervisor', { emp_id: self.currentEmployee().id })
                    self.$popup.close()

                    self.$store
                        .dispatch('audit/add', {
                            tpl_id: id,
                            cType: audit_tmpl.type,
                            emp_ref: self.currentEmployee().id
                        })
                        .then(function (response) {
                            self.addeditId = response.id

                            self.$router.push({
                                name: 'AuditEdit',
                                params: { id: id, edit: self.addeditId }
                            })
                            self.$overlay.hide()
                        })
                } else {
                    self.$overlay.hide()
                    self.$popup.alert({
                        closable: false,
                        blocking: false,
                        message: self.$t('popup.audits.not_level_permission'),
                        textSave: self.$t('popup.audits.exit'),
                        callSave: function (response) {}
                    })
                }
            })
        },

        checkPermissions(id, isTemplateParam = false) {
            log('checkPermissions', id, isTemplateParam)
            const template = isTemplateParam ? this.$store.getters['audit/getTemplateById'](id) : this.$store.getters['audit/getById'](id)
            log('checkPermissions', id, template)
            const userRole = this.$store.getters['employee/getRol'](this.user.rol)
            if (userRole.level >= 8) {
                return true
            }

            if (template && template.config && !template.config.nRoles && template.config.roles) {
                return true
            }

            if (template && template.config && !(template.config.nRoles || template.config.roles)) {
                return true
            }

            if (template && template.config && template.config.nRoles && template.config.nRoles.length > 0) {
                const templateRoles = template.config.nRoles
                let isValid = false

                templateRoles.forEach((role) => {
                    if (role.roleId == userRole.id && role.permissions.includes('w')) {
                        isValid = true
                        return
                    }
                })
                return isValid
            }
            return false
        },

        detailPermissions() {
            if (this.addeditId) {
                log('$$ detailPermissions', this.addeditId)
                return this.checkPermissions(this.addeditId)
            }
        },

        checkReadPermissions(id) {
            const template = this.$store.getters['audit/getTemplateById'](id)
            const userRole = this.$store.getters['employee/getRol'](this.user.rol)
            if (userRole.level >= 8) {
                return true
            }

            if (template && template.config && !(template.config.nRoles || template.config.roles)) {
                return true
            }

            if (template && template.config && template.config.nRoles && template.config.nRoles.length > 0) {
                const templateRoles = template.config.nRoles
                let isValid = false

                templateRoles.forEach((role) => {
                    if (role.roleId == userRole.id && role.permissions.includes('r')) {
                        isValid = true
                        return
                    }
                })
                return isValid
            }
            return false
        },

        loadAuditEdit(id) {
            if (typeof id === 'undefined' || id === 'add') {
                var templateID = this.$route.params.id
                if (!this.checkPermissions(templateID, true)) {
                    this.$overlay.hide()
                    this.$popup.alert({
                        message: this.$t('answer.timing.not_has_role_permission', {
                            name: this.user.name,
                            roles: this.rolesWithWritePermissions()
                        })
                    })
                    return
                }
                this.createAudit(templateID)
            } else {
                this.addeditId = id

                this.openAddedit(id)

                this.$router.push({
                    name: 'AuditEdit',
                    params: { id: this.detailId, edit: this.addeditId }
                })
            }
        },

        async openAddedit(id) {
            log(' >> openAddedit', id, this.addeditId)
            var self = this
            // self.$bar.reset();
            if ((id == self.addeditId && typeof id !== 'undefined' && id != 'add') || (typeof self.addeditId !== 'undefined' && self.addeditId != 'add')) {
                log('Continua con sus cosas de ADDEDIT')
                await new Promise((resolve) => setTimeout(()=>{self.$bar.reset();resolve()}, 300))

                self.$bar.addAction('back', {
                    callback: function () {
                        self.showErrors = false
                        self.openSection = null
                        self.$router.push({
                            name: 'AuditDetail',
                            params: { id: self.detailId }
                        })
                    },
                    label: self.$t('menu_aux.action.back')
                })

                let audit = self.$store.getters['audit/getById'](id)
                let empl = audit.employee_ref.id
                let empl_curr = self.$store.getters['loginUser/getLocalEmployee']
                empl_curr = self.$store.getters['employee/getEmployee'](empl_curr)
                let audit_tmpl = this.$store.getters['audit/getTemplateById'](self.detailId)
                let level = audit_tmpl.config != null && typeof audit_tmpl.config.level !== 'undefined' ? audit_tmpl.config.level : 3
                let employeeRolLevel = self.$store.getters['employee/getRol'](empl_curr.rol).level

                log(empl, empl_curr, audit)

                if ((empl && empl_curr && empl_curr.id == empl) || (audit.complete_date && employeeRolLevel >= level) || employeeRolLevel == 8 || this.checkReadPermissions(this.detailId)) {
                    log('Puede editar')
                    // Quitar botón finalizar si el audit no tiene tu rol asignado
                    let template = this.templates[this.$route.params.id]
                    let templateroles = template && template.config && template.config.roles && template.config.roles.lenght > 0 ? template.config.roles : false
                    let includeRol = templateroles ? templateroles.includes(this.user.rol) : true

                    if (/*empl === empl_curr.id &&*/ this.templates && Object.values(this.templates).length > 0 && !audit.complete_date && !(audit.state == 'past' && !audit.def.can_edit.inPast) && !(audit.state == 'future' && !audit.def.can_edit.inFuture) && includeRol && this.userAppAccess && audit.status !== 'completed' && this.checkPermissions(this.addeditId)) {
                        self.$bar.addAction('save', {
                            opened: true,
                            label: i18n.t('popup.audits.complete'),
                            callback: function () {
                                self.$popup.confirm({
                                    message: self.$t('popup.audits.confirm_save'),
                                    textSave: self.$t('popup.audits.save'),
                                    callSave: function () {
                                        var validation = []
                                        var oData = {
                                            cType: self.checkType,
                                            check: self.addeditId,
                                            emp: self.$store.getters['loginUser/getLocalEmployee'],
                                            date: moment().unix(),
                                            values: [],
                                            finalScores: self.template.config.eval
                                        }
                                        let audit = self.$store.getters['audit/getById'](self.addeditId)
                                        // know if the audit has sections
                                        if (audit && Object.values(audit.sections).length > 0) {
                                            // validation to close an audit with all mandatory fields completed
                                            let incompleteItemFound = false
                                            self.openSection = true

                                            Object.values(audit.sections).forEach((items) => {
                                                Object.values(items.items).forEach(function (item) {
                                                    if (item.mandatory && item.is_visible && ((!item.value && item.value !== 0) || item.value == null) && !self.itemsExcluded.includes(parseInt(item.type))) {
                                                        log('>> ERROR', item)
                                                        validation.push(false)

                                                        self.showErrors = true
                                                        if (!item.complete_date && !incompleteItemFound) {
                                                            setTimeout(() => {
                                                                let element = document.getElementById(item.item_id)

                                                                if (element) {
                                                                    element.scrollIntoView({ behavior: 'smooth', block: 'start' })
                                                                } else {
                                                                    console.error('Element not found:', item.item_id)
                                                                }
                                                            }, 0)

                                                            incompleteItemFound = true
                                                        }

                                                        return self.$snackbar.error({
                                                            message: self.$t('audit.save_error_uncomplete')
                                                        })
                                                    }
                                                })
                                            })
                                        }

                                        if (validation.indexOf(false) == -1) {
                                            log('>> SAVING', oData)
                                            oData.values = JSON.stringify(oData.values)
                                            self.$overlay.loading()

                                            self.$store.dispatch('audit/complete', oData).then(function (response) {
                                                log('>> SAVED complete', response)
                                                self.$store.dispatch('audit/loadTemplates', {}).then(function () {
                                                    self.$overlay.hide()
                                                    if ((self.template.config && self.template.config.show_score == undefined) || (self.template.config && self.template.config.show_score == 1)) {
                                                        self.$router.push({
                                                            name: 'AuditDetail',
                                                            params: { id: self.detailId }
                                                        })
                                                    } else {
                                                        self.$popup.alert({
                                                            message: self.$t('audit.points_total', { name: self.user.name }),
                                                            textSave: self.$t('login.pw_forgot.action'),
                                                            customClass: 'auditPoints',
                                                            data: self.check.score,
                                                            callSave: function () {
                                                                // self.$router.push("/config/printer");
                                                                self.$router.push({
                                                                    name: 'AuditDetail',
                                                                    params: { id: self.detailId }
                                                                })
                                                            }
                                                        })
                                                    }
                                                })
                                            })
                                        } else {
                                            log('CANNOT SAVE')
                                            log('-> oData')
                                            log(oData)
                                            log('-> validation')
                                            log(validation)
                                        }
                                    }
                                })
                            }
                        })
                    }
                } else {
                    log('No puede editar')
                    self.$popup.alert({
                        closable: false,
                        blocking: false,
                        message: self.$t('popup.audits.not_level_permission'),
                        textSave: self.$t('popup.audits.exit'),
                        callSave: function (response) {
                            self.$router.push({
                                name: 'AuditDetail',
                                params: { id: self.detailId }
                            })
                        }
                    })
                }
            } else {
                var audit_tmpl = this.$store.getters['audit/getTemplateById'](self.detailId)
                var level = audit_tmpl.config != null && typeof audit_tmpl.config.level !== 'undefined' ? audit_tmpl.config.level : 3
                var list = false
                if (id != undefined) {
                    var empl = self.$store.getters['audit/getById'](id).employee_ref.id
                    if (empl && empl != null) {
                        empl = self.$store.getters['employee/getEmployee'](empl)
                    }
                    list = empl ? [empl] : self.$store.getters['employee/getAccessUsers'](level)
                } else {
                    list = self.$store.getters['employee/getAccessUsers'](level)
                }

                self.$popup.access({
                    list: list,
                    callAfterAccess: function (response) {
                        if (response) {
                            if (response.status) {
                                self.$popup.close()
                                self.$bar.reset()

                                self.$store.dispatch('audit/changeSupervisor', { emp_id: response.emp_id })

                                if (id && typeof id !== 'undefined' && id != 'add') {
                                    self.addeditId = id

                                    self.$router.push({
                                        name: 'AuditEdit',
                                        params: { id: self.detailId, edit: self.addeditId }
                                    })
                                } else {
                                    self.$store
                                        .dispatch('audit/add', {
                                            tpl_id: self.detailId,
                                            cType: self.checkType,
                                            emp_ref: response.emp_id
                                        })
                                        .then(function (response) {
                                            self.addeditId = response.id

                                            self.$router.push({
                                                name: 'AuditEdit',
                                                params: { id: self.detailId, edit: self.addeditId }
                                            })
                                        })
                                }
                            } else {
                                log('Access error')
                            }
                        }
                    }
                })
            }
        },

        listAudits() {
            var self = this

            var empl_curr = self.$store.getters['loginUser/getLocalEmployee']
            empl_curr = self.$store.getters['employee/getEmployee'](empl_curr)

            this.$popup.listAudits({
                list: this.templates,
                level: parseInt(self.$store.getters['employee/getRol'](empl_curr.rol).level),
                callback: function (self2, store) {
                    self2.$popup.close()
                    self.createAudit(self2.selected)
                }
            })
        }
    },
    created() {
        this.load()
    },
    watch: {
        $route: 'load',
        audit(newVal, oldVal) {
            if (this.auditsWithTempId.length > 0 && this.$route.params.edit) {
                const routerEdit = this.$route.params.edit
                this.auditsWithTempId.forEach((audit) => {
                    if (audit.temporal_id == routerEdit) {
                        this.addeditId = audit.id
                        log('$$ audit', audit.id, audit.temporal_id, routerEdit, this.addeditId)
                        this.$router.push({
                            name: 'AuditEdit',
                            params: { id: this.detailId, edit: this.addeditId, noRefresh: true }
                        })
                    }
                })
            }
        }
    },
    beforeRouteLeave(to, from, next) {
        next()
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
#content.audit {
    $column-width: 0%;
    $column-max-width: 0px;
    $content-width: 100%;

    > .content {
        $content-margin: 0px;
        padding: 8px !important;

        @include display-flex();
        @include flex-direction(row);
        @include flex-wrap(wrap);
        @include align-content(flex-start);
        @include border-radius($default-border-radius);
        padding-top: 25px;
        background-color: $default-bg-color;
        margin: $content-margin 0 0 0;
        width: calc(#{$content-width}) !important;
        min-width: calc(100% - #{$column-max-width}) !important;
        height: calc(100% - #{$content-margin}) !important;
        border: none;

        &.empty {
            @include display-flex();
            @include align-items();
            @include justify-content(flex-end);
            padding-top: 75px;

            .message {
                @include display-inline-flex();
                @include align-items();
                @include background($image: img('alexpose_04.svg'), $size: contain, $position: right center);
                width: 100%;
                padding-right: 150px;
                height: 250px;
                max-height: 100%;
            }
        }
    }
    &.portrait {
        padding-bottom: 0px;
        > .content {
            height: 100% !important;
        }
    }
}
</style>
