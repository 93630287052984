<template>
    <div class="notes" :class="$mq">
        <div class="note" v-for="note in notes">
            <div class="note-icon">
                <img :src="employeeData(note.employee_id).avatar" alt="employee avatar" class="note-avatar">
            </div>
            <div class="note-content">
                <div class="note-header">
                    <span class="note-name">{{ employeeData(note.employee_id).name + ' ' + employeeData(note.employee_id).surname }}</span>
                    {{ $t('audit.headers.wrote') }}
                </div>
                <div class="note-body">
                    <span class="note-message">{{ note.message }}</span>
                    <span class="note-date"> {{ formattedDate(note.updated_date) }}</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        notes: { type: Array }
    },
    computed: {
        
    },
    methods: {
        employeeData(employeeId) {
            return this.$store.getters['employee/getEmployee'](employeeId)   
        },
        formattedDate(date) {
            return moment(date).format('D MMM YYYY HH:mm');
        },
    }
}
</script>
<style lang="scss" scoped>
.notes {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    margin: 0;
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: $color-neutral-50;
    border-radius: 4px;
    // border: solid 1px $color-neutral-300;

    .note {
        display: flex;
        flex-direction: row;
        width: 100%;
        background-color: $color-neutral-50;
        margin: 0;
        border-radius: 4px;

        .note-icon {
            margin: 16px;
            .note-avatar {
                width: 44px;
                height: 44px;
            }
        }

        .note-content {
            display: flex;
            flex-direction: column;

            .note-header {
                font-family: $text;
                font-size: 18px;
                margin-top: 16px;
                margin-bottom: 4px;

                .note-name {
                    font-family: $text-bold;
                    font-size: 18px;
                }
            }

            .note-body {
                display: flex;
                flex-direction: column;
                margin-bottom: 16px;
                .note-message {
                    font-family: $text;
                    font-size: 16px;
                    margin-bottom: 4px;

                }
                .note-date {
                    font-family: $text;
                    font-size: 14px;
                    color: $color-neutral-600;
                    margin-bottom: 4px;

                }
            }
        }
    }
}
.notes.portrait {
    margin: 0px 0px;
    .note {
        min-height: 122px;
        overflow: visible;
        .note-icon {
            width: auto;
            margin: 15px;
        }
        .note-content {
            width: 80%;
            padding-right: 4px;
            flex-grow: 1;
            .note-header {
                margin-top: 15px;
            }
            .note-body {
                margin-bottom: 15px;
            }
        }
    }
}
</style>